import {Component, Inject, OnInit} from "@angular/core";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatInputModule} from "@angular/material/input";
import {MAT_DATE_LOCALE, MatNativeDateModule, MatOption, provideNativeDateAdapter} from "@angular/material/core";
import {MatCard, MatCardContent, MatCardTitle} from "@angular/material/card";
import {DatePipe, JsonPipe, NgForOf, NgIf} from "@angular/common";
import {MatButton} from "@angular/material/button";
import {MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogRef} from "@angular/material/dialog";
import {MatSelect, MatSelectChange} from "@angular/material/select";
import {TranslateModule} from "@ngx-translate/core";
import {v4 as uuidv4} from "uuid";
import {PatientRecordsTemplate} from "../../pages/patient-details/patient-details.component";
import {Area, Findings, SubArea} from "../../models/patient-records.model";

/**
 * Component for adding a patient with modal functionality.
 */
@Component({
    selector: "app-add-patient-file",
    templateUrl: "./add-patient-file.component.html",
    styleUrls: ["./add-patient-file.component.scss"],
    standalone: true,
    providers: [provideNativeDateAdapter(), {provide: MAT_DATE_LOCALE, useValue: "de-DE"}],
    imports: [FormsModule, MatDatepickerModule, MatInputModule, MatNativeDateModule, MatCard, MatCardContent, NgIf, MatButton, MatSelect, MatOption, DatePipe, TranslateModule, MatCardTitle, MatDialogActions, ReactiveFormsModule, MatDialogContent, NgForOf, JsonPipe]
})
export class AddPatientFileComponent implements OnInit {
    newFindings: Findings = {
        id: uuidv4(),
        createdBy: "",
        title: "",
        order: 1,
        area: {} as Area,
        subArea: {} as SubArea,
        examinationDateTime: "",
        dateTimeOfRecord: "",
        validFrom: "",
        validUntil: "",
        formioRendererI18n: {},
        formioRendererData: [],
        formioRendererForm: undefined,
        documentType: undefined,
        documentPath: "",  // URL zu dem Dokument
        pdfBlob: undefined,
    };
    selectedArea: Area | undefined;
    patientRecordTemplate: PatientRecordsTemplate = {} as PatientRecordsTemplate;
    patientRecordForm: FormGroup;
    areas: Area[] = [];
    templateFiles: PatientRecordsTemplate[] = [
        {
            templateLabel: "Weißes Dokument",
            templateId: "white_page",
            templatePath: "assets/img/demo_images/blank_pdf.pdf"
        },
        {
            templateLabel: "Stammdatenformular",
            templateId: "master_data_form",
            templatePath: "assets/img/demo_images/demo_stammdaten.pdf"
        },
        {
            templateLabel: "Anamnesebogen",
            templateId: "medical_history",
            templatePath: "assets/img/demo_images/demo_corona_einwilligung.pdf"
        },
        {
            templateLabel: "COVID-19 Einwilligung zur Schutzimpfung",
            templateId: "corona_form",
            templatePath: "assets/img/demo_images/demo_anamnesebogen.pdf"
        }
    ]

    /**
     * Constructor of AddPatModalComponent.
     * @param dialogRef - Reference to the dialog.
     * @param fb
     * @param data
     */
    constructor(
        public dialogRef: MatDialogRef<AddPatientFileComponent>,
        private fb: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: Area[]
    ) {
        //TODO CHANGE NAMING OF FORM CONTROLS
        this.patientRecordForm = this.fb.group({
            dateTimeOfRecord: [this.newFindings.dateTimeOfRecord],
            examinationDateTime: [this.newFindings.examinationDateTime],
            createdBy: [this.newFindings.createdBy, Validators.required],
            title: [this.newFindings.title, Validators.required],
            area: [this.newFindings.area, Validators.required],
            subArea: [this.newFindings.subArea, Validators.required],
            patientRecordTemplate: [this.patientRecordTemplate, Validators.required]
        });

        this.areas = this.data;
    }

    onTypeChange(ev: MatSelectChange) {
        this.selectedArea = this.areas.find(area => area.id === ev.value.id);
        this.patientRecordForm.get('subArea')?.enable();
        this.patientRecordForm.get('subArea')?.reset();
    }

    async ngOnInit() {
        this.patientRecordForm.get("subArea")?.disable();
    }

    /**
     * Closes the modal dialog and emits the saved data.
     */
    handleNewPatientRecord(): void {
        if (this.patientRecordForm.valid) {
            const {
                area,
                subArea,
                patientRecordTemplate,
                ...formValues
            } = this.patientRecordForm.value;

            this.newFindings = {
                ...this.newFindings,
                ...formValues,
                id: uuidv4(),
                area: area,
                subArea: subArea,
                documentPath: patientRecordTemplate.templatePath,
                documentType: this.getDocumentType(patientRecordTemplate.templatePath),
            };

            this.dialogRef.close({
                role: 'save',
                newPatientRecord: this.newFindings,
            });
        }
    }

    closeModal(): void {
        this.dialogRef.close({
            role: 'cancel',
        })
    }

    /**
     * Sets the documentType based on the file extension of the given path.
     * @param path - The file path to determine the document type from.
     * @returns The determined document type.
     */
    private getDocumentType(path: string): 'form' | 'pdf' | 'image' | 'dicom' | undefined {
        const extension = path.split('.').pop()?.toLowerCase();

        switch (extension) {
            case 'pdf':
                return 'pdf';
            case 'png':
            case 'jpg':
            case 'jpeg':
                return 'image';
            case 'dcm':
                return 'dicom';
            default:
                return undefined;
        }
    }
}
