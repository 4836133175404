import { CommonModule } from '@angular/common';
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    ViewChild,
} from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
    MatCheckboxChange,
    MatCheckboxModule,
} from '@angular/material/checkbox';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatRadioChange, MatRadioModule } from '@angular/material/radio';
import {
    MatDatepickerInputEvent,
    MatDatepickerModule,
} from '@angular/material/datepicker';
import {
    MAT_DATE_LOCALE,
    provideNativeDateAdapter,
} from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { inOutExpandY } from '../../../shared/animations';
import { LuicModule } from '@lohmann-birkner/luic';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { CdkTextareaAutosize, TextFieldModule } from '@angular/cdk/text-field';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatGridListModule } from '@angular/material/grid-list';
import { Subscription } from 'rxjs';
import { IonItem } from '@ionic/angular/standalone';

dayjs.extend(utc);

export interface FormioRendererForm {
    components: any[];

    [key: string]: any;
}

export interface FormioRendererData {
    key: string;
    value: string | number | boolean | undefined;
}

/**
 * An object for the translations of the form.
 * Example:
 * ```json
 * {
 *      "de": {
 *          "Birth date": "Geburtsdatum"
 *      },
 *      "en": {
 *          "Birth date": "Birth date"
 *      }
 * }
 * ```
 */
export interface FormioRendererI18n {
    [key: string]: { [key: string]: string };
}

@Component({
    selector: 'app-formio-renderer',
    standalone: true,
    templateUrl: './formio-renderer.component.html',
    styleUrls: ['./formio-renderer.component.scss'],
    providers: [
        provideNativeDateAdapter(),
        { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
    ],
    imports: [
        IonItem,
        CommonModule,
        FormsModule,
        LuicModule,
        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatGridListModule,
        MatInputModule,
        MatRadioModule,
        MatSelectModule,
        ReactiveFormsModule,
        TextFieldModule,
    ],
    animations: [inOutExpandY],
})
export class FormioRendererComponent implements OnChanges, OnDestroy {
    @ViewChild('autosize') autosize: CdkTextareaAutosize | undefined;

    @Input() form: FormioRendererForm | undefined;
    /** Data to populate the form's fields */
    @Input() data: FormioRendererData[] = [];
    @Output() dataChange = new EventEmitter<FormioRendererData[]>();

    @Input() i18n: FormioRendererI18n | undefined;
    @Input() readonly = false;
    @Input() maxRows = 0;

    /** Emmits the value of the "key" property of the button */
    @Output() formButtonClick = new EventEmitter<string>();
    @Output() formSubmitted = new EventEmitter<SubmitEvent>();

    displayNameMap = new Map([
        [Breakpoints.HandsetPortrait, 'handsetPortrait'],
        [Breakpoints.HandsetLandscape, 'handsetLandscape'],
        [Breakpoints.Web, 'web'],
        [Breakpoints.Tablet, 'tablet'],
    ]);
    currentBreakpoint: string = '';
    public components: any[] = [];
    public labels: { key: string; value: string }[] = [];

    // Changes Jose 21.08.2024
    public formGroup: FormGroup = this.fb.group({});
    // End changes Jose 21.08.2024

    private allSubs: Subscription[] = [];

    public constructor(
        private fb: FormBuilder,
        private translate: TranslateService,
        private breakpointObserver: BreakpointObserver
    ) {}

    public ngOnInit() {
        this.allSubs.push(
            this.breakpointObserver
                .observe([
                    Breakpoints.HandsetPortrait,
                    Breakpoints.HandsetLandscape,
                    Breakpoints.Web,
                    Breakpoints.Tablet,
                ])
                .subscribe((result) => {
                    for (const query of Object.keys(result.breakpoints)) {
                        if (result.breakpoints[query]) {
                            this.currentBreakpoint =
                                this.displayNameMap.get(query) ?? '';
                        }
                    }
                })
        );
    }

    public ngOnChanges(): void {
        this.refresh();

        // Emit the data on every change in the form
        this.allSubs.push(
            this.formGroup.valueChanges.subscribe((fg) => {
                const keys = Object.keys(fg);
                const d: FormioRendererData[] = keys.map((k) => ({
                    key: k,
                    value: fg[k],
                }));
                this.dataChange.emit(d);
            })
        );
    }

    public ngOnDestroy(): void {
        this.allSubs.forEach((s) => s.unsubscribe());
    }

    //#region Listeners
    public onClickOnButton(key: string) {
        this.formButtonClick.emit(key);
    }

    public onSubmit(e?: SubmitEvent) {
        this.formSubmitted.emit(e);
    }

    //#endregion

    public isComponentDisabled(component: any): boolean {
        // Check if the component's label is 'admissionDate'
        return component.label === 'Admission Date';
    }

    public getComponentLabel(key: string) {
        return this.labels.find((e) => e.key === key)?.value;
    }

    private refresh() {
        this.components = this.form?.components ?? [];
        if (!this.data || !Array.isArray(this.data)) this.data = [];
        for (const c of this.components) {
            // Set up the component x-show property (to be displayed or not)
            const d = this.data.find((e) => e.key === c.key);
            if (!d) {
                this.data.push({ key: c.key, value: undefined });
                c['x-data'] = undefined;
            } else {
                c['x-data'] = d.value;
            }
            c['x-show'] = this.showComponent(c);
            // Setup i18n on components label
            const currentLanguage = this.translate.currentLang;
            if (this.i18n && this.i18n[currentLanguage]) {
                const value = this.i18n[currentLanguage][c.label] ?? c.label;
                this.labels.push({
                    key: c.key,
                    value: this.i18n[currentLanguage][c.label],
                });
            }
            // Translate the values of selectboxes and select component
            if (c.type === 'selectboxes' && c.values) {
                for (const v of c.values) {
                    let value = // Safely find the right text
                        this.i18n && // If this.i18n exists
                        this.i18n[currentLanguage] && // And the current language
                        this.i18n[currentLanguage][v.label] // And the label for the language
                            ? this.i18n[currentLanguage][v.label] // Then assign it
                            : v.label; // Otherwise use the original label (without i18n)

                    this.labels.push({ key: v.value, value });
                }
            }
            if (c.type === 'select' && c.data?.values) {
                for (const v of c.data.values) {
                    let value =
                        this.i18n &&
                        this.i18n[currentLanguage] &&
                        this.i18n[currentLanguage][v.label]
                            ? this.i18n[currentLanguage][v.label]
                            : v.label;

                    this.labels.push({ key: v.value, value });
                }
            }
        }

        this.initForm();
    }

    private initForm() {
        const formControls: any = {};
        const supportedComponentTypes = [
            'textfield',
            'number',
            'password',
            'textarea',
            'checkbox',
            'select',
            'radio',
            'datetime',
            'dateandtime',
        ];
        for (const c of this.components) {
            if (supportedComponentTypes.includes(c.type)) {
                const fc = new FormControl(c['x-data'] ?? '');
                if (c.disabled || this.readonly) fc.disable({ onlySelf: true });
                formControls[c.key] = fc;
            }
        }
        this.formGroup = this.fb.group(formControls);
    }

    private showComponent(component: any): boolean {
        if (component.hidden) return false;

        const cc = component.conditional;
        if (cc) {
            if (cc.conditions?.length) {
                // Conditions of the new form.io version
                for (const condition of cc.conditions) {
                    const conditionData = this.data.find(
                        (e) => e.key === condition.component
                    )?.value;
                    switch (condition.operator) {
                        case 'isNotEmpty':
                            return !!conditionData ? cc.show : !cc.show;
                        case 'isEmpty':
                            return !conditionData ? cc.show : !cc.show;
                        case 'isEqual':
                            return conditionData === condition.value
                                ? cc.show
                                : !cc.show;
                        case 'isNotEqual':
                            return conditionData !== condition.value
                                ? cc.show
                                : !cc.show;
                    }
                }

                return false;
            }
        } else if (cc?.when) {
            // Old form.io version support
            const conditionField = this.data.find((e) => e.key === cc.when);
            const conditionData = conditionField?.value;

            return conditionData === cc.eq ? cc.show : !cc.show;
        }

        return true;
    }

    // private applyValue(key: string, value: any) {
    //     const d = this.data.find((e) => e.key === key);
    //     if (!d) {
    //         this.data.push({ key, value });
    //     } else {
    //         d.value = value;
    //     }
    // }
}
