import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { LabResult } from '../../../models/view-content.models/view-content-clinic-domain.model';
import { CommonModule } from '@angular/common';
import dayjs from 'dayjs';
import { MatFormField, MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';

interface TableData {
    groups: TableGroup[];
}

interface TableGroup {
    label: string;
    text_short: string;
    icon_name: string;
    sort_order: number;
    rows: TableRow[];
}

interface TableRow {
    label: string;
    code: string;
    long_text: string;
    limits: string;
    isImportant: boolean;
    unit: string;
    cells: TableCell[];
}

interface TableCell {
    observation_date_time: string;
    value?: string;
    limits: {
        maxValue?: number;
        minValue?: number;
    };
}

@Component({
    selector: 'app-labor-viewer',
    templateUrl: './labor-viewer.component.html',
    styleUrls: ['./labor-viewer.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        MatToolbarModule,
    ],
})
export class LaborViewerComponent implements OnInit, AfterViewInit {
    @Input() labResults: LabResult[] = [];

    @Output() clickOnFullScreen = new EventEmitter<void>();

    public tableData: TableData = { groups: [] };
    public datesArray: string[] = [];
    public filterOption: 'all' | 'important' = 'all';

    public constructor() {}

    public ngOnInit() {
        this.refresh();
    }

    public ngAfterViewInit(): void {}

    public getClassesForCell(cell: TableCell): string | undefined {
        if (!cell.value) return;

        const cleanValue = cell.value?.trim().replaceAll(',', '.');
        const parsedValue = Number.parseFloat(cell.value);

        // Most common scenario: the value is a parseable float
        if (!isNaN(parsedValue)) {
            if (
                cell.limits.minValue != null &&
                parsedValue < cell.limits.minValue
            )
                return 'color-below-limit';
            if (
                cell.limits.maxValue != null &&
                parsedValue > cell.limits.maxValue
            )
                return 'color-above-limit';
        }

        // Other scenario: the value is an estimation like "<0.005" or ">10.0"
        if (cleanValue.charAt(0) === '<') {
            const n = Number.parseFloat(cleanValue.substring(1));
            if (cell.limits.minValue != null && n < cell.limits.minValue)
                return 'color-below-limit';
        }

        if (cleanValue.charAt(0) === '>') {
            const n = Number.parseFloat(cleanValue.substring(1));
            if (cell.limits.minValue != null && n > cell.limits.minValue)
                return 'color-above-limit';
        }

        return undefined;
    }

    private refresh(): void {
        // Build table data
        this.datesArray = this.labResults
            .map((e) => dayjs(e.observation_date_time).toISOString())
            .sort((d1, d2) => d1.localeCompare(d2));

        this.tableData = { groups: [] };

        // Build the table without values
        for (const result of this.labResults) {
            for (const group of result.groups) {
                const existingGroup = this.tableData.groups.find(
                    (e) => e.label === group.text_long
                );

                const rows: TableRow[] = group.values.map((v) => ({
                    label: v.method.short_text,
                    code: v.method.code,
                    long_text: v.method.long_text,
                    limits: v.limits,
                    isImportant: true, // TODO: implement this
                    unit: v.unit.code,
                    // For each row build an array
                    cells: this.datesArray.map((date) => ({
                        observation_date_time: date,
                        limits: this.parseLimits(v.limits),
                    })),
                }));

                if (existingGroup) {
                    const missingRows = rows.filter(
                        (e) =>
                            !existingGroup.rows.some((r) => r.code === e.code)
                    );
                    existingGroup.rows = existingGroup.rows.concat(
                        ...missingRows
                    );
                } else {
                    const tableGroup: TableGroup = {
                        label: group.text_long,
                        text_short: group.text_short,
                        icon_name: group.icon_name,
                        sort_order: group.sort_order,
                        rows,
                    };
                    this.tableData.groups.push(tableGroup);
                }
            }
        }

        // Fill the values in the table
        for (const g of this.tableData.groups) {
            for (const r of g.rows) {
                for (const c of r.cells) {
                    c.value = this.getValueForCell(g, r, c);
                }
            }
        }
    }

    private getValueForCell(
        g: TableGroup,
        r: TableRow,
        c: TableCell
    ): string | undefined {
        const result = this.labResults.find(
            (e) =>
                dayjs(e.observation_date_time).toISOString() ===
                c.observation_date_time
        );
        const group = result?.groups.find((e) => e.text_long === g.label);
        return group?.values.find((e) => e.method.code === r.code)?.value;
    }

    private parseLimits(limits: string): {
        maxValue?: number;
        minValue?: number;
    } {
        const fixedLimits = limits.trim().replaceAll(',', '.');
        let max: number = NaN;
        let min: number = NaN;

        const oneLimit = /([<>])\s*(-?\d+(\.\d+)?)/.exec(fixedLimits);
        if (oneLimit) {
            // oneLimit[1] is the "<" or ">", oneLimit[2] is the number
            const operator = oneLimit[1];
            const value = parseFloat(oneLimit[2]);

            if (operator === '<') {
                max = value; // It's an upper limit
            } else if (operator === '>') {
                min = value; // It's a lower limit
            }
        } else {
            const l = fixedLimits.split(' - ');
            min = Number.parseFloat(l[0]);
            max = Number.parseFloat(l[1]);
        }

        return {
            minValue: isNaN(min) ? undefined : min,
            maxValue: isNaN(max) ? undefined : max,
        };
    }
}
