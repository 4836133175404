import {createSelector} from '@ngrx/store';
import {AppState} from '../appstate.model';

export const selectLocatorFeature = (state: AppState) => state.locators;

export const selectLocators = createSelector(
    selectLocatorFeature,
    (locatorsState) => locatorsState.locators
);

