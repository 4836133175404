export type ChartData = ChartConfiguration<'line', ChartItemPoint[]>['data'];
import { ChartConfiguration } from 'chart.js';
import { VC_CurveGroup } from '../../../models/view-content.models/view-content.model';
import bloodPointStyle from '../../curve/plugin/bloodPointStyle';
import { ChartItemPoint, VitalType } from '../../curve/curve.component';
import { CurveItemData, CurveRow } from '../../../models/curve.model';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';

const labelMappings: { [key: string]: string } = {
    Herzfrequenz: 'HF',
    Blutdruck: 'RR',
    Temperatur: 'T',
    Atemfrequenz: 'AF',
};

const CHART_COLORS = {
    HF: 'rgba(248, 60, 58, 1)',
    RR: 'rgba(63, 153, 76, 1)',
    T: 'rgba(14, 122, 254, 1)',
    AF: 'rgba(255, 149, 0, 1)',
};

const POINT_RADIUS = { HF: 5, RR: undefined, T: 5, AF: 10 };
const POINT_HOVER_RADIUS = { HF: 10, RR: undefined, T: 10, AF: 15 };
const SHOW_LINE = { HF: undefined, RR: false, T: undefined, AF: undefined };
const POINT_STYLE = {
    HF: 'rect',
    RR: bloodPointStyle,
    T: 'circle',
    AF: 'cross',
};
const POINT_ROTATION = { HF: undefined, RR: undefined, T: undefined, AF: 45 };
const POINT_BORDER_WIDTH = {
    HF: undefined,
    RR: undefined,
    T: undefined,
    AF: 2,
};

const r = labelMappings['heartRate'];

const _labelMapping = {
    [VitalType.heartRate.toString()]: 'Herzfrequenz',
    [VitalType.respiratoryRate.toString()]: 'Atemfrequenz',
    [VitalType.bloodPressure.toString()]: 'Blutdruck',
    [VitalType.temperatur.toString()]: 'Temperatur',
};

export const mapVsGroup2ChartDataDatasets = (
    vsGroups: VC_CurveGroup
): ChartData['datasets'] => {
    return vsGroups.data.rows.map((e, index) => {
        const label = labelMappings[e.label] as 'HF' | 'RR' | 'T' | 'AF';
        const color = CHART_COLORS[label];
        const pointRadius = POINT_RADIUS[label];
        const pointHoverRadius = POINT_HOVER_RADIUS[label];
        const showLine = SHOW_LINE[label];
        const pointStyle = POINT_STYLE[label];
        const pointRotation = POINT_ROTATION[label];
        const pointBorderWidth = POINT_BORDER_WIDTH[label];
        return {
            backgroundColor: color,
            borderColor: color,
            data: e.data.map((d) => {
                // Try to create a blood pressure value, detected because it includes a "/" between systolic and diastolic
                const v = d.value.split('/');
                if (v.length > 1) {
                    return {
                        x: d.date,
                        y: Number.parseInt(v[0]),
                        systolic: Number.parseInt(v[0]),
                        diastolic: Number.parseInt(v[1]),
                    };
                }

                return {
                    x: d.date,
                    y: Number.parseInt(d.value),
                };
            }),
            fill: false,
            label,
            pointBorderWidth,
            pointHoverRadius,
            pointRadius,
            pointRotation,
            pointStyle,
            showLine,
            yAxisID: 'y' + (index ? index + 1 : ''),
        };
    });
};

export const mergeVitalSignsData = (
    dataSets: ChartData['datasets']
): {
    [type: string]: { [date: string]: ChartItemPoint[] };
} => {
    const mergedData: {
        [type: string]: { [date: string]: ChartItemPoint[] };
    } = {};

    for (const dataItem of dataSets) {
        const label = dataItem.label ?? '';
        mergedData[label] = {};

        for (const item of dataItem.data) {
            const date = dayjs(item.x)
                .startOf('day')
                .add(4, 'hour')
                .toISOString();

            if (!mergedData[label][date]) {
                mergedData[label][date] = [];
            }

            mergedData[label][date].push(item);
        }
    }

    return mergedData;
};

export const buildVitalSignsRow = (mergedData: {
    [type: string]: { [date: string]: ChartItemPoint[] };
}): CurveRow[] => {
    const rows: CurveRow[] = [];

    for (const mergedDataKey of Object.keys(mergedData)) {
        const mergedDataItem = mergedData[mergedDataKey];

        const dataEntries: CurveItemData[] = [];

        // Iterate over each dateKey in mergedDataItem
        for (const dateKey of Object.keys(mergedDataItem)) {
            const value = mergedDataItem[dateKey];

            // Push each individual point with `date` and `y`
            value.forEach((point) => {
                dataEntries.push({
                    id: uuidv4(),
                    date: point.x, // The specific time point
                    value:
                        point.diastolic == null
                            ? point.y.toString()
                            : `${point.systolic}/${point.diastolic}`, // The actual value
                });
            });
        }

        rows.push({
            id: uuidv4(),
            data: dataEntries,
            label: mergedDataKey
                ? _labelMapping[mergedDataKey]
                : 'Unbekannt',
            sortOrder: 0,
            status: 'normal',
        });
    }

    return rows;
};
