import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserToken} from '../../models/auth.model';
import {PatientListGroup, VcPatientListItem} from '../../models/view-content.models/view-content.model';
import {Cp2ApiService} from '../../services/cp2-api.service';
import {filter, firstValueFrom, Subscription} from 'rxjs';
import {AccessFacadeService} from '../../services/facades/access-facade.service';
import {DatePipe, JsonPipe, NgClass, NgForOf, NgIf, NgSwitch, NgSwitchCase} from '@angular/common';
import {MatIcon} from '@angular/material/icon';
import {MatInput} from '@angular/material/input';
import {MAT_SELECT_CONFIG, MatOption, MatSelect} from '@angular/material/select';
import {
    MatCell,
    MatCellDef,
    MatColumnDef,
    MatHeaderCell,
    MatHeaderCellDef,
    MatHeaderRow,
    MatHeaderRowDef,
    MatRow,
    MatRowDef,
    MatTable,
    MatTableDataSource
} from '@angular/material/table';
import {
    HomePatientBasicInfoComponent
} from '../../components/home-page/home-patient-basic-info/home-patient-basic-info.component';
import {ToolboxService} from '../../services/toolbox.service';
import {RouterLink} from '@angular/router';
import {HomePatientTasksComponent} from '../../components/home-page/home-patient-tasks/home-patient-tasks.component';
import {v4 as uuidv4} from 'uuid';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {MatTooltip} from '@angular/material/tooltip';
import {MatTabNav} from '@angular/material/tabs';
import {MatBadge} from '@angular/material/badge';
import {switchMap} from 'rxjs/operators';

// #region Interfaces
interface FilterOptions {
    key: string;
    value: string;
    selected: boolean;
}

interface SortOptions {
    key: string;
    value: string;
    selected: boolean;
    order: 'DESC' | 'ASC';
}

interface ExtendedPatientListGroup extends PatientListGroup {
    listIdentifier: {
        id: string;
        code: string;
        name: string;
        listType: string;
        selected: boolean;
    };

    patients: VcPatientListItem[];
}

// #endregion

@Component({
    selector: 'app-workflow-page',
    templateUrl: './workflow-page.component.html',
    styleUrls: ['./workflow-page.component.scss'],
    imports: [
        NgForOf,
        MatIcon,
        MatInput,
        MatSelect,
        MatOption,
        NgIf,
        NgClass,
        MatTable,
        MatColumnDef,
        MatHeaderCell,
        MatCell,
        MatHeaderCellDef,
        MatCellDef,
        MatHeaderRow,
        MatRow,
        MatHeaderRowDef,
        MatRowDef,
        JsonPipe,
        NgSwitch,
        NgSwitchCase,
        DatePipe,
        HomePatientBasicInfoComponent,
        RouterLink,
        HomePatientTasksComponent,
        TranslateModule,
        MatTooltip,
        MatTabNav,
        MatBadge
    ],
    standalone: true,
    providers: [
        {
            provide: MAT_SELECT_CONFIG,
            useValue: {overlayPanelClass: 'shadow'}
        }
    ]
})
export class WorkflowPageComponent implements OnInit, OnDestroy {
    // #region Variables
    public patients: VcPatientListItem[] = [];
    public caseLists: ExtendedPatientListGroup[] = [];
    public allSubs: Subscription[] = [];

    public dataSource: MatTableDataSource<VcPatientListItem>;
    public displayedColumns: string[] = [];

    public filterOptions: FilterOptions[] = [];

    public sortOptions: SortOptions[] = [];
    public appMenuClientHeight = 0;

    public translatedColumns: { [key: string]: string } = {};

    private accessToken: UserToken | undefined;

    // #endregion

    constructor(
        private api: Cp2ApiService,
        private accessFacade: AccessFacadeService,
        private toolbox: ToolboxService,
        private translate: TranslateService
    ) {
        this.dataSource = new MatTableDataSource<VcPatientListItem>([]);
    }

    async ngOnInit() {
        this.appMenuClientHeight = document.getElementsByClassName(
            'app-container-menubar'
        )[0].clientHeight;

        this.allSubs.push(
            this.accessFacade.userTokens$
                .pipe(
                    filter((e) => !!e.token),
                    switchMap(async (t) => {
                        await this.toolbox.executeInLoading(async () => {
                            this.accessToken = t.token ?? undefined;
                            if (this.accessToken) {
                                await this.updateData();
                            }

                            await this.initializeFiltersAndSort();
                            await this.handleSelectedFilter(
                                this.filterOptions[0]
                            );
                            await this.initializePatients();
                        }, 'Loading Data...');
                    })
                )
                .subscribe()
        );
    }

    ngOnDestroy() {
        this.allSubs.forEach((sub) => sub.unsubscribe());
    }

    // #region Filters
    public async handleSelectedStation(list: ExtendedPatientListGroup) {
        this.caseLists.forEach((l) => {
            l.listIdentifier.selected =
                list.listIdentifier.name === l.listIdentifier.name;
        });

        const selectedList = this.getSelectedList();
        if (selectedList) {
            const activeSort = this.sortOptions.find((s) => s.selected);
            if (activeSort) {
                if (activeSort.key === 'name') {
                    this.patients = this.sortByFullName(
                        selectedList.patients,
                        activeSort.order
                    );
                } else if (activeSort.key === 'admissionDate') {
                    this.patients = this.sortByAdmissionDate(
                        selectedList.patients,
                        activeSort.order
                    );
                }
            }
            this.refresh(selectedList.patients);
        } else {
            console.error('Keine Liste ausgewählt!');
        }
    }

    public async handleSelectedFilter(filter: FilterOptions) {
        const selectedList = this.getSelectedList();

        if (!selectedList) {
            console.error('Keine Liste ausgewählt!');
            return;
        }

        this.filterOptions.forEach((f) => (f.selected = false));
        filter.selected = true;

        const translationKeys = this.getTranslationKeys(filter.key);
        const translations = await this.translate
            .get(translationKeys)
            .toPromise();

        this.displayedColumns = translationKeys.map((key) => translations[key]);
        this.translatedColumns = translations;

        this.updateCaseLists(selectedList);
        this.sortPatients(selectedList);
        this.refresh(selectedList.patients);
    }

    public searchValue(ev: EventTarget | null) {
        if (ev) {
            const searchTerm = (ev as HTMLInputElement).value.toLowerCase();
            const selectedList = this.getSelectedList();

            if (selectedList) {
                const patients = selectedList.patients.filter((patient) =>
                    this.matchesPatient(patient, searchTerm)
                );
                this.refresh(patients);
            }
        }
    }

    // #region Sorting
    public async handleSorting(sort: SortOptions) {
        const selectedList = this.getSelectedList();
        if (!selectedList) {
            console.error('Keine Liste ausgewählt!');
            return;
        }
        this.sortOptions.forEach((s) => (s.selected = false));
        sort.order = sort.order === 'ASC' ? 'DESC' : 'ASC';
        sort.selected = true;

        let patients: VcPatientListItem[] = [];

        if (sort.key === 'name') {
            patients = this.sortByFullName(selectedList.patients, sort.order);
        } else if (sort.key === 'admissionDate') {
            patients = this.sortByAdmissionDate(
                selectedList.patients,
                sort.order
            );
        }

        this.refresh(patients);
    }

    public sortByAdmissionDate(patients: VcPatientListItem[], sortOrder: 'ASC' | 'DESC'): VcPatientListItem[] {
        if (patients) {
            return patients.sort((a, b) => {
                const dateA = new Date(a.patient_details.case.data.admission_date).getTime();
                const dateB = new Date(b.patient_details.case.data.admission_date).getTime();
                return sortOrder === 'ASC' ? dateA - dateB : dateB - dateA;
            });
        }
        return [];
    }

    public sortByFullName(patients: VcPatientListItem[], sortOrder: 'ASC' | 'DESC'): VcPatientListItem[] {
        if (patients) {
            return patients.sort((a, b) => {
                const nameA = a.patient_details.case.data.patient.surname.toLowerCase() + ' ' + a.patient_details.case.data.patient.name.toLowerCase();
                const nameB = b.patient_details.case.data.patient.surname.toLowerCase() + ' ' + b.patient_details.case.data.patient.name.toLowerCase();
                return sortOrder === 'ASC' ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
            });
        }
        return [];
    }

    private async initializeFiltersAndSort(): Promise<void> {
        const translations = await this.getTranslations([
            'COMPONENT.PAGE_WORKFLOW.filter_tasks',
            'COMPONENT.PAGE_WORKFLOW.filter_instruction',
            'COMPONENT.PAGE_WORKFLOW.filter_comments',
            'COMPONENT.PAGE_WORKFLOW.filter_findings',
            'COMPONENT.PAGE_WORKFLOW.admissionDate'
        ]);

        this.filterOptions = [
            {
                key: 'tasks',
                value: translations['COMPONENT.PAGE_WORKFLOW.filter_tasks'],
                selected: false
            },
            {
                key: 'instruction',
                value: translations[
                    'COMPONENT.PAGE_WORKFLOW.filter_instruction'
                    ],
                selected: false
            },
            {
                key: 'comments',
                value: translations['COMPONENT.PAGE_WORKFLOW.filter_comments'],
                selected: false
            },
            {
                key: 'findings',
                value: translations['COMPONENT.PAGE_WORKFLOW.filter_findings'],
                selected: false
            }
        ];

        this.sortOptions = [
            {key: 'name', value: 'Name', selected: true, order: 'ASC'},
            {
                key: 'admissionDate',
                value: translations['COMPONENT.PAGE_WORKFLOW.admissionDate'],
                selected: false,
                order: 'ASC'
            }
        ];
    }

    private async initializePatients(): Promise<void> {
        const selectedList = this.getSelectedList();
        if (selectedList) {
            this.patients = this.sortByFullName(selectedList.patients, 'ASC');
            this.refresh(selectedList.patients);
        }
    }

    private getTranslations(
        keys: string[]
    ): Promise<{ [key: string]: string }> {
        return firstValueFrom(this.translate.get(keys));
    }

    private getTranslationKeys(filterKey: string): string[] {
        const baseKey = 'COMPONENT.PAGE_WORKFLOW.column_';
        switch (filterKey) {
            case 'tasks':
                return [
                    'general_information',
                    'task',
                    'edited_at',
                    'edited_by',
                    'status'
                ].map((k) => baseKey + k);
            case 'instruction':
                return [
                    'general_information',
                    'description',
                    'submitted_to',
                    'creation_date',
                    'status'
                ].map((k) => baseKey + k);
            case 'comments':
                return [
                    'general_information',
                    'submitted_to',
                    'description'
                ].map((k) => baseKey + k);
            case 'findings':
                return [
                    'general_information',
                    'finding_category',
                    'examination_date',
                    'finding_date',
                    'findings'
                ].map((k) => baseKey + k);
            default:
                return [];
        }
    }

    private updateCaseLists(selectedList: any): void {
        this.caseLists.forEach((l) => {
            l.listIdentifier.selected =
                l.listIdentifier.name === selectedList.listIdentifier.name;
        });
    }

    private sortPatients(selectedList: any): void {
        const activeSort = this.sortOptions.find((s) => s.selected);
        if (activeSort) {
            if (activeSort.key === 'name') {
                this.patients = this.sortByFullName(
                    selectedList.patients,
                    activeSort.order
                );
            } else if (activeSort.key === 'admissionDate') {
                this.patients = this.sortByAdmissionDate(
                    selectedList.patients,
                    activeSort.order
                );
            }
        }
    }

    // #endregion

    private matchesPatient(patient: VcPatientListItem, query: string): boolean {
        // Convert the query to lowercase for case-insensitive comparison
        const lowerCaseQuery = query.toLowerCase();

        // Check simple string properties
        const matchesSimpleFields = [
            patient?.patient_details.patient.data.name,
            patient?.patient_details.patient.data.surname,
            patient?.patient_details.current_place.data?.room?.long_text ?? '',
            patient?.patient_details.case.data.admission_date
        ].some((field) => field?.toLowerCase().includes(lowerCaseQuery));

        // Check array of strings (diagnosis)
        const matchesDiagnosis =
            patient?.patient_details.last_diagnosis?.data.diagnose
                .toLowerCase()
                .includes(lowerCaseQuery);

        // Check nested Tasks array
        const matchesTasks = patient?.tasks.some(
            (task: any) =>
                task.data.task_name.toLowerCase().includes(lowerCaseQuery) ||
                task.data.details.toLowerCase().includes(lowerCaseQuery) ||
                task.data.priority.toLowerCase().includes(lowerCaseQuery) ||
                task.data.editor.surname
                    .toLowerCase()
                    .includes(lowerCaseQuery) ||
                task.data.editor.name.toLowerCase().includes(lowerCaseQuery) ||
                task.data.goalDateOn.toLowerCase().includes(lowerCaseQuery) ||
                task.data.contractor.surname
                    .toLowerCase()
                    .includes(lowerCaseQuery) ||
                task.data.contractor.name.toLowerCase().includes(lowerCaseQuery)
        );

        // Check nested Visits array
        const matchesVisits = patient?.visit_record.history?.some(
            (visitRecord: any) =>
                visitRecord.data.note.toLowerCase().includes(lowerCaseQuery)
        );

        // Check Discharge information (if applicable)
        const matchesDischarge =
            patient?.patient_details.case.data.discharge_date
                ?.toLowerCase()
                .includes(lowerCaseQuery) ||
            patient?.patient_details.case.data.planned_discharge_date
                ?.toLowerCase()
                .includes(lowerCaseQuery) ||
            false;

        // Return true if any of the above conditions match
        return (
            matchesSimpleFields ||
            matchesDiagnosis ||
            matchesTasks ||
            matchesVisits ||
            matchesDischarge
        );
    }

    // #region Refresher
    private getSelectedList() {
        return this.caseLists.find((l) => l.listIdentifier.selected);
    }

    private refresh(patients: VcPatientListItem[]): void {
        this.patients = patients || [];
        this.dataSource.data = this.patients;
    }

    private async updateData(): Promise<void> {
        if (!this.accessToken) return;

        try {
            const fetchedLists = (await this.api.getCaseListForUser(
                this.accessToken.related_user_id,
                this.accessToken.access_token
            )) as ExtendedPatientListGroup[];

            const res = this.translate.instant(
                'COMPONENT.PAGE_WORKFLOW.all_patients'
            );
            this.caseLists = [
                {
                    listIdentifier: {
                        id: uuidv4(),
                        code: 'all-patients',
                        name: res,
                        listType: 'station',
                        selected: true
                    },
                    patients: fetchedLists.flatMap((group) => group.patients)
                },
                ...fetchedLists
            ];
        } catch (error) {
            console.error('Error while refreshing data', error);
            this.refresh([]);
        }
    }

    // #endregion
}
