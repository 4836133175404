import {FormioModule} from '@formio/angular';
import {AfterContentInit, Component, Inject, OnInit} from '@angular/core';
import {MatCardModule} from '@angular/material/card';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {CommonModule, JsonPipe} from '@angular/common';
import {
    FormioRendererComponent,
    FormioRendererData,
    FormioRendererI18n
} from '../../components/data-interaction/formio-renderer/formio-renderer.component';
import {PatientNameAndDob} from '../../pages/patient-details/patient-details.component';
import {CalAgePipe} from '../../pipes/cal-age.pipe';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormsModule} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {ChangingHistoryComponent} from '../../components/data-interaction/changing-history/changing-history.component';
import {MatListModule} from '@angular/material/list';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {MatBadgeModule} from '@angular/material/badge';
import {Consultation} from '../../models/view-content.models/view-content-clinic-domain.model';

export interface FormModalResult {
    role: 'cancel' | 'save';
    data: any;
}

export interface ExtendedConsulation extends Consultation {
    goal_date: 'yesterday' | 'today' | 'tomorrow' | 'on';
}

@Component({
    selector: 'app-form-modal',
    standalone: true,
    templateUrl: './form-modal.component.html',
    styleUrls: ['./form-modal.component.scss'],
    imports: [
        FormioModule,
        MatCardModule,
        JsonPipe,
        FormioRendererComponent,
        CommonModule,
        CalAgePipe,
        FormsModule,
        MatFormFieldModule,
        TranslateModule,
        MatIconModule,
        MatButtonModule,
        MatDialogModule,
        MatListModule,
        ChangingHistoryComponent,
        ScrollingModule,
        MatBadgeModule
    ]
})
export class FormModalComponent<T> implements OnInit, AfterContentInit {
    private static readonly TAG = 'FormModalComponent';

    /**the data for form self, the json files*/
    public form: any;

    /**the data in the form */
    public formData: any;

    public renderOptions: any = {};
    /**the name of the form */
    public form_file_name: string = '';
    /**the title of the dialog */
    public formTitle: string = '';
    /**translation data for the formular */
    public i18n: FormioRendererI18n | undefined;

    /**translation for the key of the incoming data
     * this variable also work as a flag to control when to show the whole container of changing history
     */
    public viewContentI18n: FormioRendererI18n;
    public formioRendererData: FormioRendererData[] = [];
    public patientInfo: PatientNameAndDob = {
        firstName: '',
        lastName: '',
        dob: '',
        gender: '',
        room: '',
        ward: '',
        bed: ''
    };
    public showHistory: boolean = false;
    public numberOfHistories: number = 0;
    public histories: any[] = [];

    public constructor(
        public dialogRef: MatDialogRef<FormModalResult>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            form_file_name: string;
            form_data?: any; //current data in the form
            patient_info: PatientNameAndDob;
            viewContentI18n: FormioRendererI18n;
            history: any[];
            hideHistoryFields: string[];
        },
        private translate: TranslateService,
        public dialog: MatDialog
    ) {
        this.viewContentI18n = data.viewContentI18n;
        this.formData = data.form_data;
        this.patientInfo = data.patient_info;
        this.form_file_name = data.form_file_name;
        this.formTitle = this.getFormName(data.form_file_name);
        this.histories = data.history;
        if (data.history) {
            this.processData(this.histories);
            this.numberOfHistories = data.history?.length;
        }
        this.loadJSON();
    }

    public async ngOnInit(): Promise<void> {
        await this.loadJSON();
        if (this.data.form_data) {
            this.buildFormioRendererData();
        }
    }

    public ngAfterContentInit(): void {
        this.setLanguage();
    }

    //#region Listeners

    public onClickOnCancel(): void {
        this.dialogRef.close(); // Optionally pass a result here if needed
    }

    public onClickOnSave(): void {
        this.dialogRef.close({role: 'save', data: this.formioRendererData});
    }

    onDataChange(updatedData: FormioRendererData[]) {
        this.formioRendererData = updatedData;
    }

    //#endregion

    private buildFormioRendererData(): void {
        if (!this.data.form_data) return;

        this.formioRendererData = [];
        const keys = Object.keys(this.data.form_data.data);
        for (const key of keys) {
            const value = this.data.form_data.data[key];
            this.formioRendererData.push({key, value});
        }
    }

    private processData(dataArray: any[]) {
        dataArray.sort(
            (a, b) =>
                new Date(b.modifiedAt).getTime() -
                new Date(a.modifiedAt).getTime()
        );
    }

    private async loadJSON(): Promise<void> {
        const content = await (
            await fetch('assets/forms/' + this.data.form_file_name)
        ).json();
        this.form = content.form;
        this.renderOptions.i18n = content.i18n;
        this.i18n = content.i18n;
    }

    private setLanguage(lang?: string) {
        if (!lang) {
            lang = this.translate.currentLang;
        }
        this.renderOptions.language = lang;
    }

    private getFormName(formFilename: string) {
        let parts = formFilename.split('_');
        let desiredPart = parts[1];
        return desiredPart.split('.')[0];
    }
}
