<mat-toolbar
    *ngIf="
        currentBreakpoint === 'handsetPortrait' ||
        currentBreakpoint === 'handsetLandscape'
    "
    class="z-10"
>
    <button
        mat-icon-button
        class="example-icon"
        aria-label="sidebar-button"
        (click)="onClickShowSidebar()"
    >
        <mat-icon>menu</mat-icon>
    </button>
    <span>CP2</span>
    <span class="example-spacer"></span>
</mat-toolbar>

<app-app-navigation
    *ngIf="currentUrl !== '/login'"
    (navigate)="handleNavigation($event)"
    [currentHandyOrientation]="currentBreakpoint"
    [ngClass]="{
        'app-container-menubar-handy': currentBreakpoint === 'handsetPortrait',
        'app-container-menubar-handy-landscape':
            currentBreakpoint === 'handsetLandscape',
        'app-container-menubar':
            currentBreakpoint !== 'handsetPortrait' &&
            currentBreakpoint !== 'handsetLandscape'
    }"
></app-app-navigation>

<!-- container for sidebar and the page right sidebar  -->
<div [ngClass]="{ 'app-container': currentUrl !== '/login' }">
    <app-sidebar
        class="app-container-sidebar lbShadowSmall"
        *ngIf="currentUrl !== '/login' && showSidebar"
        (clickSidebarButton)="handleSidebarButton($event)"
        [userData]="{
            name: username,
            photoLink: '../assets/img/placeholder.jpg'
        }"
        [currentBreakpoint]="currentBreakpoint"
    ></app-sidebar>

        <router-outlet></router-outlet>
</div>
