import {createReducer, on} from '@ngrx/store';
import * as ViewContentActions from '../actions/viewcontent.action';
import { ViewContent } from '../../models/view-content.models/view-content.model';

export interface ViewContentState {
    viewContent: ViewContent<any>[];
    loading: boolean;
    error: any;
}

export const initialState: ViewContentState = {
    viewContent: [],
    loading: false,
    error: null,
};

export const viewContentReducer = createReducer(
    initialState,
    on(ViewContentActions.setViewContent, (state, {viewContent}) => ({
        ...state,
        viewContent,
        loading: true,
    })),
    on(ViewContentActions.setViewContentSuccess, (state, {viewContent}) => ({
        ...state,
        viewContent,
        loading: false,
        error: null,
    })),
    on(ViewContentActions.setViewContentFail, (state, {error}) => ({
        ...state,
        loading: false,
        error,
    })),
    on(ViewContentActions.loadViewContentSuccess, (state, {viewContent}) => ({
        ...state,
        viewContent,  // Typen sollten nun übereinstimmen
        loading: false,
        error: null,
    })),
    on(ViewContentActions.loadViewContentFail, (state, {error}) => ({
        ...state,
        loading: false,
        error,
    }))
);
