<div>
    <!-- Dates header -->
    <div class="curve-part-container sticky z-[9999] top-0">
        <table>
            <tr>
                <td
                    class="curve-left-most-cell"
                    style="
                        border-color: transparent;
                        border-right-color: lightgray;
                        border-bottom-color: lightgray;
                    "
                >
                    <button
                        mat-icon-button
                        aria-label="Eine Woche zurück"
                        matTooltip="Eine Woche zurück"
                        (click)="onClickOnMoveDays(-7)"
                    >
                        <mat-icon>keyboard_double_arrow_left</mat-icon>
                    </button>

                    <button
                        mat-icon-button
                        aria-label="Ein Tag zurück"
                        matTooltip="Ein Tag zurück"
                        (click)="onClickOnMoveDays(-1)"
                    >
                        <mat-icon>keyboard_arrow_left</mat-icon>
                    </button>

                    <button
                        mat-icon-button
                        aria-label="Heute"
                        matTooltip="Heute"
                        (click)="onClickOnToday()"
                    >
                        <mat-icon>today</mat-icon>
                    </button>

                    <button
                        mat-icon-button
                        aria-label="Ein Tag vorwärts"
                        matTooltip="Ein Tag vorwärts"
                        (click)="onClickOnMoveDays(1)"
                    >
                        <mat-icon>keyboard_arrow_right</mat-icon>
                    </button>

                    <button
                        mat-icon-button
                        aria-label="Eine Woche vorwärts"
                        matTooltip="Eine Woche vorwärts"
                        (click)="onClickOnMoveDays(7)"
                    >
                        <mat-icon>keyboard_double_arrow_right</mat-icon>
                    </button>
                </td>

                <td
                    *ngFor="let d of datesArray"
                    class="curve-info-cell text-center"
                    [ngClass]="{
                        'curve-today': isToday(d),
                        'curve-weekend': isWeekend(d)
                    }"
                >
                    {{ d | date : "EE dd.MM.yyyy" }}
                </td>
            </tr>
        </table>
    </div>

    <!-- Upper part -->
    <div class="curve-part-container">
        <div *ngFor="let group of upperDataToShow">
            <table
                *ngIf="group.status !== 'deleted' && group.status !== 'hidden'"
            >
                <tr>
                    <th
                        class="curve-group-header curve-left-most-cell text-left"
                    >
                        {{ group.label }}

                        <button
                            mat-icon-button
                            aria-label="Gruppenname anpassen"
                            matTooltip="Gruppenname anpassen"
                            (click)="onClickOnEditGroup(group)"
                        >
                            <mat-icon>edit</mat-icon>
                        </button>
                    </th>
                    <th class="curve-group-header w-full text-left" colspan="7">
                        <button
                            mat-icon-button
                            aria-label="Gruppe hinzufügen"
                            matTooltip="Gruppe hinzufügen"
                            (click)="
                                onClickOnAddGroupUnderneath(group, 'upper')
                            "
                        >
                            <mat-icon>add</mat-icon>
                        </button>

                        <button
                            mat-icon-button
                            aria-label="Zeile hinzufügen"
                            matTooltip="Zeile hinzufügen"
                            (click)="onClickOnAddRow(group)"
                        >
                            <mat-icon>playlist_add</mat-icon>
                        </button>
                    </th>
                </tr>
                <ng-container *ngFor="let row of group.rows">
                    <tr
                        *ngIf="
                            row.status !== 'deleted' && row.status !== 'hidden'
                        "
                    >
                        <td class="curve-left-most-cell">
                            {{ row.label }}

                            <button
                                mat-icon-button
                                class="line-header-button"
                                aria-label="Zeilenname bearbeiten"
                                matTooltip="Zeilenname bearbeiten"
                                (click)="onClickOnEditRow(group, row)"
                            >
                                <mat-icon>edit</mat-icon>
                            </button>
                        </td>

                        <td
                            *ngFor="let d of row.data"
                            class="curve-info-cell curve-info-cell-hover"
                            [ngClass]="{ 'curve-today': isToday(d.date) }"
                            (click)="onClickOnCell(group, row, d)"
                        >
                            {{ d.value }}
                        </td>
                    </tr>
                </ng-container>
            </table>
        </div>
    </div>

    <!-- Middle part (chart and table) -->
    <app-curve
        class="block curve-part-container"
        [datesArray]="datesArray"
        [datasets]="curveDataToShow"
        (datasetsChange)="onVitalSignChange($event)"
    />
    <!-- Vital signs table -->
    <div class="curve-part-container">
        <table *ngIf="curveDataForTable">
            <tr>
                <th class="curve-group-header curve-left-most-cell text-left">
                    {{ curveDataForTable.label }}

                    <!-- <button
                            mat-icon-button
                            aria-label="Gruppenname anpassen"
                            matTooltip="Gruppenname anpassen"
                            (click)="onClickOnEditGroup(group)"
                        >
                            <mat-icon>edit</mat-icon>
                        </button> -->
                </th>
                <th class="curve-group-header w-full text-left" colspan="7">
                    <!-- <button
                            mat-icon-button
                            aria-label="Gruppe hinzufügen"
                            matTooltip="Gruppe hinzufügen"
                            (click)="
                                onClickOnAddGroupUnderneath(group, 'upper')
                            "
                        >
                            <mat-icon>add</mat-icon>
                        </button> -->

                    <!-- <button
                            mat-icon-button
                            aria-label="Zeile hinzufügen"
                            matTooltip="Zeile hinzufügen"
                            (click)="onClickOnAddLine(group)"
                        >
                            <mat-icon>playlist_add</mat-icon>
                        </button> -->
                </th>
            </tr>
            <ng-container *ngFor="let row of curveDataForTable.rows">
                <tr *ngIf="row.status !== 'deleted' && row.status !== 'hidden'">
                    <td
                        class="curve-left-most-cell"
                        style="display: table-cell"
                    >
                        {{ row.label }}
                    </td>

                    <td
                        *ngFor="let d of row.data"
                        class="curve-info-cell"
                        [ngClass]="{ 'curve-today': isToday(d.date) }"
                    >
                        <span
                            *ngFor="let item of d.value; last as last"
                            (click)="onClickOnVitalSignTableValue(item, row)"
                        >
                            <span class="vital-sign-table-value">{{
                                item.value
                            }}</span>
                            {{ last ? "" : ", " }}
                        </span>
                    </td>
                </tr>
            </ng-container>
        </table>
    </div>

    <!-- Downer part -->
    <div class="curve-part-container">
        <div *ngFor="let group of downerDataToShow">
            <table
                *ngIf="group.status !== 'deleted' && group.status !== 'hidden'"
            >
                <tr>
                    <th
                        class="curve-group-header curve-left-most-cell text-left"
                    >
                        {{ group.label }}

                        <button
                            mat-icon-button
                            aria-label="Gruppenname anpassen"
                            matTooltip="Gruppenname anpassen"
                            (click)="onClickOnEditGroup(group)"
                        >
                            <mat-icon>edit</mat-icon>
                        </button>
                    </th>
                    <th class="curve-group-header w-full text-left" colspan="7">
                        <button
                            mat-icon-button
                            aria-label="Gruppe hinzufügen"
                            matTooltip="Gruppe hinzufügen"
                            (click)="
                                onClickOnAddGroupUnderneath(group, 'lower')
                            "
                        >
                            <mat-icon>add</mat-icon>
                        </button>

                        <button
                            mat-icon-button
                            aria-label="Zeile hinzufügen"
                            matTooltip="Zeile hinzufügen"
                            (click)="onClickOnAddRow(group)"
                        >
                            <mat-icon>playlist_add</mat-icon>
                        </button>
                    </th>
                </tr>
                <ng-container *ngFor="let row of group.rows">
                    <tr
                        *ngIf="
                            row.status !== 'deleted' && row.status !== 'hidden'
                        "
                    >
                        <td class="curve-left-most-cell">
                            {{ row.label }}

                            <button
                                mat-icon-button
                                class="line-header-button"
                                aria-label="Zeilenname bearbeiten"
                                matTooltip="Zeilenname bearbeiten"
                                (click)="onClickOnEditRow(group, row)"
                            >
                                <mat-icon>edit</mat-icon>
                            </button>
                        </td>

                        <td
                            *ngFor="let d of row.data"
                            class="curve-info-cell curve-info-cell-hover"
                            [ngClass]="{ 'curve-today': isToday(d.date) }"
                            (click)="onClickOnCell(group, row, d)"
                        >
                            {{ d.value }}
                        </td>
                    </tr>
                </ng-container>
            </table>
        </div>
    </div>
</div>
