import { Chart, ChartArea, Scale } from 'chart.js';
import {
    formatNumber,
    isNullOrUndef,
    sign,
    almostEquals,
    niceNum,
    almostWhole,
    _decimalPlaces,
    _setMinAndMaxByKey,
    toRadians,
    _alignPixel,
} from 'chart.js/helpers';

function generateTicks(
    generationOptions: {
        maxTicks?: any;
        bounds?: any;
        min?: any;
        max?: any;
        precision?: any;
        step?: any;
        count?: any;
        maxDigits?: any;
        horizontal?: any;
        minRotation?: any;
        includeBounds?: any;
    },
    // @ts-ignore
    dataRange: this
) {
    const ticks = [];
    // To get a "nice" value for the tick spacing, we will use the appropriately named
    // "nice number" algorithm. See https://stackoverflow.com/questions/8506881/nice-label-algorithm-for-charts-with-minimum-ticks
    // for details.

    const MIN_SPACING = 1e-14;
    const {
        bounds,
        step,
        min,
        max,
        precision,
        count,
        maxTicks,
        maxDigits,
        includeBounds,
    } = generationOptions;
    const unit = step || 1;
    const maxSpaces = maxTicks - 1;
    const { min: rmin, max: rmax } = dataRange;
    const minDefined = !isNullOrUndef(min);
    const maxDefined = !isNullOrUndef(max);
    const countDefined = !isNullOrUndef(count);
    const minSpacing = (rmax - rmin) / (maxDigits + 1);
    let spacing = niceNum((rmax - rmin) / maxSpaces / unit) * unit;
    let factor, niceMin, niceMax, numSpaces;

    // Beyond MIN_SPACING floating point numbers being to lose precision
    // such that we can't do the math necessary to generate ticks
    if (spacing < MIN_SPACING && !minDefined && !maxDefined) {
        return [{ value: rmin }, { value: rmax }];
    }

    numSpaces = Math.ceil(rmax / spacing) - Math.floor(rmin / spacing);
    if (numSpaces > maxSpaces) {
        // If the calculated num of spaces exceeds maxNumSpaces, recalculate it
        spacing = niceNum((numSpaces * spacing) / maxSpaces / unit) * unit;
    }

    if (!isNullOrUndef(precision)) {
        // If the user specified a precision, round to that number of decimal places
        factor = Math.pow(10, precision);
        spacing = Math.ceil(spacing * factor) / factor;
    }

    if (bounds === 'ticks') {
        niceMin = Math.floor(rmin / spacing) * spacing;
        niceMax = Math.ceil(rmax / spacing) * spacing;
    } else {
        niceMin = rmin;
        niceMax = rmax;
    }

    if (
        minDefined &&
        maxDefined &&
        step &&
        almostWhole((max - min) / step, spacing / 1000)
    ) {
        // Case 1: If min, max and stepSize are set and they make an evenly spaced scale use it.
        // spacing = step;
        // numSpaces = (max - min) / spacing;
        // Note that we round here to handle the case where almostWhole translated an FP error
        numSpaces = Math.round(Math.min((max - min) / spacing, maxTicks));
        spacing = (max - min) / numSpaces;
        niceMin = min;
        niceMax = max;
    } else if (countDefined) {
        // Cases 2 & 3, we have a count specified. Handle optional user defined edges to the range.
        // Sometimes these are no-ops, but it makes the code a lot clearer
        // and when a user defined range is specified, we want the correct ticks
        niceMin = minDefined ? min : niceMin;
        niceMax = maxDefined ? max : niceMax;
        numSpaces = count - 1;
        spacing = (niceMax - niceMin) / numSpaces;
    } else {
        // Case 4
        numSpaces = (niceMax - niceMin) / spacing;

        // If very close to our rounded value, use it.
        if (almostEquals(numSpaces, Math.round(numSpaces), spacing / 1000)) {
            numSpaces = Math.round(numSpaces);
        } else {
            numSpaces = Math.ceil(numSpaces);
        }
    }

    // The spacing will have changed in cases 1, 2, and 3 so the factor cannot be computed
    // until this point
    const decimalPlaces = Math.max(
        _decimalPlaces(spacing),
        _decimalPlaces(niceMin)
    );
    factor = Math.pow(10, isNullOrUndef(precision) ? decimalPlaces : precision);
    niceMin = Math.round(niceMin * factor) / factor;
    niceMax = Math.round(niceMax * factor) / factor;

    let j = 0;
    if (minDefined) {
        if (includeBounds && niceMin !== min) {
            ticks.push({ value: min });

            if (niceMin < min) {
                j++; // Skip niceMin
            }
            // If the next nice tick is close to min, skip it
            if (
                almostEquals(
                    Math.round((niceMin + j * spacing) * factor) / factor,
                    min,
                    relativeLabelSize(min, minSpacing, generationOptions)
                )
            ) {
                j++;
            }
        } else if (niceMin < min) {
            j++;
        }
    }

    for (; j < numSpaces; ++j) {
        const tickValue = Math.round((niceMin + j * spacing) * factor) / factor;
        if (maxDefined && tickValue > max) {
            break;
        }
        ticks.push({ value: tickValue });
    }

    if (maxDefined && includeBounds && niceMax !== max) {
        // If the previous tick is too close to max, replace it with max, else add max
        if (
            ticks.length &&
            almostEquals(
                ticks[ticks.length - 1].value,
                max,
                relativeLabelSize(max, minSpacing, generationOptions)
            )
        ) {
            ticks[ticks.length - 1].value = max;
        } else {
            ticks.push({ value: max });
        }
    } else if (!maxDefined || niceMax === max) {
        ticks.push({ value: niceMax });
    }

    return ticks;
}

function relativeLabelSize(
    value: string,
    minSpacing: number,
    { horizontal, minRotation }: any
) {
    const rad = toRadians(minRotation);
    const ratio = (horizontal ? Math.sin(rad) : Math.cos(rad)) || 0.001;
    const length = 0.75 * minSpacing * ('' + value).length;
    return Math.min(minSpacing / ratio, length);
}

export default class CustomScale extends Scale {
    static id: string = 'customScale';

    /**
     * @type {any}
     */
    static defaults = {
        datasetElementType: false,
        dataElementType: 'point',
        showLine: false,
        fill: false,
    };

    start: undefined;
    end: undefined;
    _startValue: undefined;
    _endValue: undefined;
    _valueRange: number;
    _range: this | undefined;

    constructor(cfg: {
        id: string;
        type: string;
        ctx: CanvasRenderingContext2D;
        chart: Chart;
    }) {
        super(cfg);

        console.log('constructor customscales');

        /** @type {number} */
        this.start = undefined;
        /** @type {number} */
        this.end = undefined;
        /** @type {number} */
        this._startValue = undefined;
        /** @type {number} */
        this._endValue = undefined;
        this._valueRange = 0;
    }

    override determineDataLimits() {
        const { min, max } = this.getMinMax(true);
        this.min = isFinite(min) ? min : 0;
        this.max = isFinite(max) ? max : 1;

        // Common base implementation to handle min, max, beginAtZero
        this.handleTickRangeOptions();
    }

    /**
     * Returns the maximum number of ticks based on the scale dimension
     * @protected
     */
    computeTickLimit() {
        const horizontal = this.isHorizontal();
        const length = horizontal ? this.width : this.height;
        // @ts-ignore
        const minRotation = toRadians(this.options.ticks.minRotation);
        const ratio =
            (horizontal ? Math.sin(minRotation) : Math.cos(minRotation)) ||
            0.001;
        // @ts-ignore
        const tickFont = this._resolveTickFontOptions(0);
        return Math.ceil(length / Math.min(40, tickFont.lineHeight / ratio));
    }

    // Utils
    override getPixelForValue(value: number | null) {
        return value === null
            ? NaN
            : this.getPixelForDecimal(
                  // @ts-ignore
                  (value - this._startValue) / this._valueRange
              );
    }

    override getValueForPixel(pixel: number) {
        return (
            // @ts-ignore
            this._startValue + this.getDecimalForPixel(pixel) * this._valueRange
        );
    }

    override parse(raw: string | number, index: any) {
        // eslint-disable-line no-unused-vars
        if (isNullOrUndef(raw)) {
            return null;
        }
        if (
            // @ts-ignore
            (typeof raw === 'number' || raw instanceof Number) &&
            !isFinite(+raw)
        ) {
            return null;
        }

        return +raw;
    }

    handleTickRangeOptions() {
        // @ts-ignore
        const { beginAtZero } = this.options;
        const { minDefined, maxDefined } = this.getUserBounds();
        let { min, max } = this;

        const setMin = (v: number) => (min = minDefined ? min : v);
        const setMax = (v: number) => (max = maxDefined ? max : v);

        if (beginAtZero) {
            const minSign = sign(min);
            const maxSign = sign(max);

            if (minSign < 0 && maxSign < 0) {
                setMax(0);
            } else if (minSign > 0 && maxSign > 0) {
                setMin(0);
            }
        }

        if (min === max) {
            let offset = max === 0 ? 1 : Math.abs(max * 0.05);

            setMax(max + offset);

            if (!beginAtZero) {
                setMin(min - offset);
            }
        }
        this.min = min;
        this.max = max;
    }

    getTickLimit() {
        // @ts-ignore
        const tickOpts = this.options.ticks;
        // eslint-disable-next-line prefer-const
        let { maxTicksLimit, stepSize } = tickOpts;
        let maxTicks;

        if (stepSize) {
            maxTicks =
                Math.ceil(this.max / stepSize) -
                Math.floor(this.min / stepSize) +
                1;
            if (maxTicks > 1000) {
                console.warn(
                    `scales.${this.id}.ticks.stepSize: ${stepSize} would result generating up to ${maxTicks} ticks. Limiting to 1000.`
                );
                maxTicks = 1000;
            }
        } else {
            maxTicks = this.computeTickLimit();
            maxTicksLimit = maxTicksLimit || 11;
        }

        if (maxTicksLimit) {
            maxTicks = Math.min(maxTicksLimit, maxTicks);
        }

        return maxTicks;
    }

    // /**
    //  * @protected
    //  */
    // computeTickLimit() {
    //     return Number.POSITIVE_INFINITY;
    // }

    override buildTicks() {
        const opts = this.options;
        // @ts-ignore
        const tickOpts = opts.ticks;

        // Figure out what the max number of ticks we can support it is based on the size of
        // the axis area. For now, we say that the minimum tick spacing in pixels must be 40
        // We also limit the maximum number of ticks to 11 which gives a nice 10 squares on
        // the graph. Make sure we always have at least 2 ticks
        let maxTicks = this.getTickLimit();
        maxTicks = Math.max(2, maxTicks);

        const numericGeneratorOptions = {
            maxTicks,
            // @ts-ignore
            bounds: opts.bounds,
            // @ts-ignore
            min: opts.min,
            // @ts-ignore
            max: opts.max,
            precision: tickOpts.precision,
            step: tickOpts.stepSize,
            count: tickOpts.count,
            // @ts-ignore
            maxDigits: this._maxDigits(),
            horizontal: this.isHorizontal(),
            minRotation: tickOpts.minRotation || 0,
            includeBounds: tickOpts.includeBounds !== false,
        };
        const dataRange = this._range || this;
        const ticks = generateTicks(numericGeneratorOptions, dataRange);

        // At this point, we need to update our max and min given the tick values,
        // since we probably have expanded the range of the scale
        // @ts-ignore
        if (opts.bounds === 'ticks') {
            _setMinAndMaxByKey(ticks, this, 'value');
        }

        if (opts.reverse) {
            ticks.reverse();

            // @ts-ignore
            this.start = this.max;
            // @ts-ignore
            this.end = this.min;
        } else {
            // @ts-ignore
            this.start = this.min;
            // @ts-ignore
            this.end = this.max;
        }

        return ticks;
    }

    /**
     * @protected
     */
    override configure() {
        console.log('configure');
        const ticks = this.ticks;
        let start = this.min;
        let end = this.max;

        super.configure();

        // @ts-ignore
        if (this.options.offset && ticks.length) {
            const offset = (end - start) / Math.max(ticks.length - 1, 1) / 2;
            start -= offset;
            end += offset;
        }
        // @ts-ignore
        this._startValue = start;
        // @ts-ignore
        this._endValue = end;
        this._valueRange = end - start;
    }

    override getLabelForValue(value: number) {
        return formatNumber(
            value,
            // @ts-ignore
            this.chart.options.locale,
            // @ts-ignore
            this.options.ticks.format
        );
    }

    override drawGrid(chartArea: ChartArea) {
        // @ts-ignore
        const grid = this.options.grid;
        const ctx = this.ctx;
        const items =
            // @ts-ignore
            this._gridLineItems ||
            // @ts-ignore
            (this._gridLineItems = this._computeGridLineItems(chartArea));
        let i, ilen;

        // @ts-ignore
        const drawLine = (p1, p2, style) => {
            if (!style.width || !style.color) {
                return;
            }
            ctx.save();
            ctx.lineWidth = style.width;
            ctx.strokeStyle = style.color;
            ctx.setLineDash(style.borderDash || []);
            ctx.lineDashOffset = style.borderDashOffset;

            ctx.beginPath();
            ctx.moveTo(p1.x, p1.y);
            ctx.lineTo(p2.x, p2.y);
            ctx.stroke();
            ctx.restore();
        };

        if (grid.display) {
            for (i = 0, ilen = items.length; i < ilen; ++i) {
                const item = items[i];

                if (grid.drawOnChartArea) {
                    drawLine(
                        0,
                        0,
                        item
                    );
                }

                if (grid.drawTicks) {
                    drawLine(
                        0,
                        0,
                        {
                            color: item.tickColor,
                            width: item.tickWidth,
                            borderDash: item.tickBorderDash,
                            borderDashOffset: item.tickBorderDashOffset,
                        }
                    );
                }
            }
        }
    }

    drawBorder() {
        const {
            chart,
            ctx,
            // @ts-ignore
            options: { border, grid },
        } = this;
        // @ts-ignore
        const borderOpts = border.setContext(this.getContext());
        const axisWidth = border.display ? borderOpts.width : 0;
        if (!axisWidth) {
            return;
        }
        // @ts-ignore
        const lastLineWidth = grid.setContext(this.getContext(0)).lineWidth;
        // @ts-ignore
        const borderValue = this._borderValue;
        let x1, x2, y1, y2;

        if (this.isHorizontal()) {
            x1 = _alignPixel(chart, this.left, axisWidth) - axisWidth / 2;
            x2 =
                _alignPixel(chart, this.right, lastLineWidth) +
                lastLineWidth / 2;
            y1 = y2 = borderValue;
        } else {
            y1 = _alignPixel(chart, this.top, axisWidth) - axisWidth / 2;
            y2 =
                _alignPixel(chart, this.bottom, lastLineWidth) +
                lastLineWidth / 2;
            x1 = x2 = borderValue;
        }
        ctx.save();
        ctx.lineWidth = borderOpts.width;
        ctx.strokeStyle = borderOpts.color;

        ctx.beginPath();
        ctx.moveTo(x1, y1);
        ctx.lineTo(x2, y2);
        ctx.stroke();

        ctx.restore();
    }
}

CustomScale.id = 'customScale';

// export default CustomScale;
