import { FormioRendererI18n } from './../../components/data-interaction/formio-renderer/formio-renderer.component';
import {
    Case_Overview,
    getNewViewContent,
    VC_CurveGroup,
} from './../../models/view-content.models/view-content.model';
import { Component, OnDestroy, OnInit, signal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PaintingToolBridgeComponent } from '../../components/utility/painting-tool-bridge/painting-tool-bridge.component';
import { RecordsFacadeService } from '../../services/facades/records-facade.service';
import { AreaFacadeService } from '../../services/facades/areas-facade.service';
import { FullscreenDialogComponent } from '../../modals/fullscreen-dialog/fullscreen-dialog.component';
import {
    LS_PATIENT_RECORDS,
    LS_PATIENT_RECORDS_AREAS,
    LS_PATIENT_RECORDS_SUBAREAS,
    LS_PATIENT_SELECTED_RECORD,
} from '../../shared/constants';
import { Preferences } from '@capacitor/preferences';
import {
    Area,
    Findings,
    PaintingToolItem,
    Record,
    SubArea,
} from '../../models/patient-records.model';
import { Patient_Details } from '../../models/view-content.models/view-content.model';
import { PatientCurveComponent } from '../../components/patient-info/patient-curve-group/patient-curve-group.component';
import { CurveComponent } from '../../components/curve/curve.component';
import { DataRepositoryService } from '../../services/datarepository.service';
import {
    CurveGroup,
    getNewCurveGroup,
    getNewCurveRow,
} from '../../models/curve.model';
import { v4 as uuidv4 } from 'uuid';
import { PatientListItem } from '../../models/patient.model';
import { firstValueFrom, Subscription } from 'rxjs';
import { CommonModule, Location } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatCardModule } from '@angular/material/card';
import { PatientOverviewComponent } from '../../components/patient-info/patient-overview/patient-overview.component';
import { PatientFilesComponent } from '../../components/patient-info/patient-files/patient-files.component';
import { CalAgePipe, DayAgoPipe } from '@lohmann-birkner/luic';
import { MatIconModule } from '@angular/material/icon';
import { WorkflowTabsComponent } from '../../components/tabs/workflow-tabs/workflow-tabs.component';
import { UpcommingPageComponent } from '../base/upcomming-page/upcomming-page.component';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatTabChangeEvent, MatTabsModule } from '@angular/material/tabs';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Cp2ApiService } from '../../services/cp2-api.service';
import { SettingsFacadeService } from '../../services/facades/settings-facade.service';
import dayjs from 'dayjs';
import { AccessFacadeService } from '../../services/facades/access-facade.service';
import { CP2_User } from '../../models/view-content.models/view-content-personal-domain.model';
import * as jose from 'jose';
import { dummyVC_LabReport } from '../../shared/view_content_dummy_data';

export interface PatientNameAndDob {
    firstName: string;
    lastName: string;
    dob: string;
    gender: string;
    ward: string;
    room: string;
    bed: string;
}

export interface PatientRecordsTemplate {
    templateLabel: string;
    templateId: string;
    templatePath: string;
}

@Component({
    selector: 'app-patient-details',
    templateUrl: './patient-details.component.html',
    styleUrls: ['./patient-details.component.scss'],
    standalone: true,
    imports: [
        CalAgePipe,
        CommonModule,
        CurveComponent,
        DayAgoPipe,
        MatCardModule,
        MatDialogModule,
        MatIconModule,
        MatTabsModule,
        PatientCurveComponent,
        PatientFilesComponent,
        PatientOverviewComponent,
        TranslateModule,
        UpcommingPageComponent,
        WorkflowTabsComponent,
    ],
    providers: [
        // Provide DialogRef token
        { provide: DialogRef, useValue: {} },
        { provide: DIALOG_DATA, useValue: {} },
    ],
})
export class PatientDetailsComponent implements OnInit, OnDestroy {
    public vcPatientDetails: Patient_Details | undefined;
    public vcCaseOverview: Case_Overview | undefined;

    public vc_CurveGroups: VC_CurveGroup[] = [];
    public currentTab: number | undefined;
    public selectedChip: string | undefined | null;

    public selectedRecord = {} as Record;
    public records: Record[] = [];
    public areas: Area[] = [];
    // TODO: SORGE DAFÜR DAS ES IM STATE MANAGER GESPEICHERT WIRD UND FRAGE PETER NACH WIE ES MIR RECORD AUSSIEHT
    public subAreas: SubArea[] = [];
    public findings: Findings[] = [];
    public isFullscreenForImageEnabled = signal({
        data: {},
        fullScreen: false,
    });
    public isMetaDataViewOpened = signal(false);
    public isPaintingToolOpened = signal({
        data: {},
        isPaintingToolDialogOpened: false,
    });

    //TODO: need to be deleted
    public patient: PatientListItem | undefined;
    // public patientDetails: PatientData | undefined;
    public viewContentI18n: FormioRendererI18n | undefined;
    public patientNameAndDob: PatientNameAndDob = {
        firstName: '',
        lastName: '',
        dob: '',
        gender: '',
        ward: '',
        room: '',
        bed: '',
    };
    public patientWorkflow: PatientListItem[] | undefined;
    public filterValue: string = '';
    private allSubs: Subscription[] = [];
    // JOSE 9.08.2024
    private caseId: string | undefined;
    private user: CP2_User | undefined;

    public constructor(
        private api: Cp2ApiService,
        private accessFacade: AccessFacadeService,
        private areasFacade: AreaFacadeService,
        private dialog: MatDialog,
        private location: Location,
        private recordsFacade: RecordsFacadeService,
        private repo: DataRepositoryService,
        private route: ActivatedRoute,
        private settings: SettingsFacadeService
    ) {
        this.settings.loadSettings();
    }

    /**
     * Initializes the component, fetches patient details, and calculates age.
     * @private
     */
    public async ngOnInit(): Promise<void> {
        this.caseId = this.route.snapshot.paramMap.get('id') ?? undefined;
        await this.refresh();

        // Listen for changes in the route parameters
        const s = this.route.paramMap.subscribe((params) => {
            const tabIndex = params.get('tabIndex');
            const selectedChip = params.get('selectedChip');
            if (tabIndex !== null) {
                this.currentTab = Number.parseInt(tabIndex, 10);
                this.selectedChip = selectedChip;
            } else {
                this.changeTab(0);
            }
        });
        this.allSubs.push(s);

        try {
            await this.initializePatientRecords();
        } catch (e) {
            console.error(e);
        }

        // Load this.user
        this.allSubs.push(
            this.accessFacade.userTokens$.subscribe(async (t) => {
                if (t.token) {
                    const dec = jose.decodeJwt(t.token.access_token);
                    this.user = {
                        userId: dec['sub'] ?? '',
                        surname: dec['family_name'] as string,
                        name: dec['given_name'] as string,
                    };
                    await this.loadCurveViewContent();
                }
            })
        );
    }

    /**
     * Cleans up resources on component destruction.
     */
    public ngOnDestroy(): void {
        this.allSubs.forEach((s) => s.unsubscribe());
    }

    //#region Listeners
    public async onCurveGroupsChange($event: VC_CurveGroup): Promise<void> {
        console.log($event)
        if (
            !this.caseId ||
            !this.vcCaseOverview?.case.related_patient_id ||
            !this.user
        )
            throw Error(
                'Error saving curve group: no case_id or patient_id or user available'
            );

        $event.related_case_id = this.caseId;
        $event.related_patient_id = this.vcCaseOverview.case.related_patient_id;
        await this.repo.createOrUpdateViewContent($event, this.user);
    }
    //#endregion

    /**
     * Handles tab change event.
     * @param $event - The tab change event.
     * @private
     */
    public changeTab($event: MatTabChangeEvent | number): void {
        const newTabIndex =
            $event instanceof MatTabChangeEvent ? $event.index : $event;
        const currentPath = this.location.path().replace(/\/\d+$/, '');
        const newPath = `${currentPath}/${newTabIndex}`;

        // Aktualisiere die Browser-URL ohne eine Navigation auszulösen.
        this.location.replaceState(newPath);
        this.currentTab = newTabIndex;
    }

    public async onClickOnFullScreen(ev: {
        data: any;
        fullScreen: boolean;
    }) {
        this.isFullscreenForImageEnabled.set(ev);

        const dialogRef = this.dialog.open(FullscreenDialogComponent, {
            data: ev,
            width: 'calc(100% - 2rem)',
            height: 'calc(100% - 2rem)',
        });

        const data = await firstValueFrom(dialogRef.afterClosed());
        this.isFullscreenForImageEnabled.set({ data: {}, fullScreen: false });
    }

    public async onPaintingToolOpenedChanged(ev: {
        data: any;
        isPaintingToolDialogOpened: boolean;
    }) {
        this.isPaintingToolOpened.set(ev);
        let img: PaintingToolItem;
        if (ev.data?.documentType === 'image') {
            img = {
                image: await this.convertToBase64(ev.data?.image),
                json_field: null,
            };
        } else {
            img = {
                blob: ev.data?.pdfBlob,
                json_field: null,
            };
        }
        if (img) {
            // Opens a dialog for adding a new patient file and prevents it from auto-refocusing
            const dialogRef = this.dialog.open(PaintingToolBridgeComponent, {
                restoreFocus: false,
                height: '856px',
                maxHeight: '100vh',
                maxWidth: '100%',
                width: '1464px',
                data: { img: img, patient: this.patient }, //TODO:patient need to be replaced
            });
            this.allSubs.push(
                dialogRef.afterClosed().subscribe((result) => {
                    this.isPaintingToolOpened.set({
                        data: {},
                        isPaintingToolDialogOpened: false,
                    });
                })
            );
        }
    }

    public onMetaDataViewOpenedChanged(ev: boolean) {
        this.isMetaDataViewOpened.set(ev);
    }

    private async initializePatientRecords() {
        // Check and initialize areas
        const storedAreas = JSON.parse(
            <string>(
                (await Preferences.get({ key: LS_PATIENT_RECORDS_AREAS })).value
            )
        );
        if (!storedAreas) {
            const areas = this.getDefaultAreas();
            await Preferences.set({
                key: LS_PATIENT_RECORDS_AREAS,
                value: JSON.stringify(areas),
            });
            // this.areas = areas;
        }

        // Check and initialize subAreas
        const storedSubAreas = JSON.parse(
            <string>(
                (await Preferences.get({ key: LS_PATIENT_RECORDS_SUBAREAS }))
                    .value
            )
        );
        if (!storedSubAreas) {
            const subAreas = this.getDefaultSubAreas();
            await Preferences.set({
                key: LS_PATIENT_RECORDS_SUBAREAS,
                value: JSON.stringify(subAreas),
            });
            // this.subAreas = subAreas;
        }

        // Check and initialize records
        const storedRecords = JSON.parse(
            <string>(await Preferences.get({ key: LS_PATIENT_RECORDS })).value
        );
        if (!storedRecords) {
            const records = this.getDefaultRecords();
            await Preferences.set({
                key: LS_PATIENT_RECORDS,
                value: JSON.stringify(records),
            });
            // this.records = records;
        }

        // Check and initialize selectedRecord
        const storedSelectedRecord = JSON.parse(
            <string>(
                (await Preferences.get({ key: LS_PATIENT_SELECTED_RECORD }))
                    .value
            )
        );
        if (!storedSelectedRecord) {
            const record = this.getDefaultRecords()[0];
            await Preferences.set({
                key: LS_PATIENT_SELECTED_RECORD,
                value: JSON.stringify(record),
            });
            // this.selectedRecord = record;
        }

        this.findings = this.api.getPatientFindings();
        const vcLabReport = dummyVC_LabReport;

        this.areasFacade.loadAreas();
        this.areasFacade.loadSubAreas();
        this.recordsFacade.loadSelectedRecord();
        this.recordsFacade.loadRecords();

        // Subscribe to updates from facades
        this.subscribeToFacades();
    }

    private blobToBase64(blob: Blob): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result as string);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    }

    private getDefaultAreas(): Area[] {
        return [
            {
                id: '95c9665d-ad11-4bf6-b25e-4b64c02196e7',
                recordId: 'c5725899-b20b-4aff-b349-8fe9f510d47d',
                version: '1.0',
                name: 'Labor',
                validFrom: '2023-01-01T00:00:00Z',
                validUntil: '2024-01-01T00:00:00Z',
                order: 1,
                subAreas: [
                    {
                        id: 'a2ccfc0a-233e-4758-9da8-d1a7bc636a2c',
                        areaId: '95c9665d-ad11-4bf6-b25e-4b64c02196e7',
                        version: '1.0',
                        name: 'Blutuntersuchung',
                        validFrom: '2023-01-01T00:00:00Z',
                        validUntil: '2024-01-01T00:00:00Z',
                        order: 1,
                    },
                ],
            },
            {
                id: '83482f1f-0c43-4d22-8e81-3ed6c19650c6',
                recordId: 'c5725899-b20b-4aff-b349-8fe9f510d47d',
                version: '1.0',
                name: 'Radiologie',
                validFrom: '2023-02-01T00:00:00Z',
                validUntil: '2024-02-01T00:00:00Z',
                order: 1,
                subAreas: [
                    {
                        id: '8ba4feae-f002-4f31-b06f-72f19dece33d',
                        areaId: '83482f1f-0c43-4d22-8e81-3ed6c19650c6',
                        version: '1.0',
                        name: 'MRT',
                        validFrom: '2023-02-01T00:00:00Z',
                        validUntil: '2024-02-01T00:00:00Z',
                        order: 1,
                    },
                    {
                        id: 'ef481ba4-9234-4fa8-b3bc-dc3ec3d7ba6c',
                        areaId: '83482f1f-0c43-4d22-8e81-3ed6c19650c6',
                        version: '1.0',
                        name: 'CT',
                        validFrom: '2023-02-01T00:00:00Z',
                        validUntil: '2024-02-01T00:00:00Z',
                        order: 1,
                    },
                ],
            },
            {
                id: '4201d743-e2a8-4aa5-8bb2-88a37c9da669',
                recordId: 'af27a2a9-81f4-44a7-b72f-58528949fc8d',
                version: '1.0',
                name: 'Kardiologie',
                validFrom: '2023-02-01T00:00:00Z',
                validUntil: '2024-02-01T00:00:00Z',
                order: 1,
                subAreas: [
                    {
                        id: '7bb37e0f-2ab1-4ddb-9422-e15e813326d3',
                        areaId: '4201d743-e2a8-4aa5-8bb2-88a37c9da669',
                        version: '1.0',
                        name: 'EKG',
                        validFrom: '2023-02-01T00:00:00Z',
                        validUntil: '2024-02-01T00:00:00Z',
                        order: 1,
                    },
                ],
            },
            {
                id: '97bb7da0-4729-40ed-8d6e-96fa330ba4f7',
                recordId: 'af27a2a9-81f4-44a7-b72f-58528949fc8d',
                version: '1.0',
                name: 'Allgemeinmedizin',
                validFrom: '2023-02-01T00:00:00Z',
                validUntil: '2024-02-01T00:00:00Z',
                order: 1,
                subAreas: [
                    {
                        id: 'ca76399e-42dd-401b-8405-9d9d4b5e20db',
                        areaId: '97bb7da0-4729-40ed-8d6e-96fa330ba4f7',
                        version: '1.0',
                        name: 'Vitalparameter',
                        validFrom: '2023-02-01T00:00:00Z',
                        validUntil: '2024-02-01T00:00:00Z',
                        order: 1,
                    },
                    {
                        id: 'fff73543-04d5-4921-aab6-5feae2a596ea',
                        areaId: '97bb7da0-4729-40ed-8d6e-96fa330ba4f7',
                        version: '1.0',
                        name: 'Anamnese',
                        validFrom: '2023-02-01T00:00:00Z',
                        validUntil: '2024-02-01T00:00:00Z',
                        order: 1,
                    },
                ],
            },
            {
                id: 'a19c3890-678f-43a3-813e-b1059536fa5d',
                recordId: 'c5725899-b20b-4aff-b349-8fe9f510d47d',
                version: '1.0',
                name: 'Test',
                validFrom: '2024-07-04T13:58:14.041Z',
                validUntil: '2024-07-04T13:58:14.041Z',
                order: 1,
                subAreas: [
                    {
                        id: '2742634c-fa4d-47ec-bdb6-37dc1034b92d',
                        areaId: 'a19c3890-678f-43a3-813e-b1059536fa5d',
                        version: '1.0',
                        name: 'Test1',
                        validFrom: '2024-07-04T14:01:21.044Z',
                        validUntil: '2024-07-04T14:01:21.044Z',
                        order: 1,
                    },
                ],
            },
        ];
    }

    private getDefaultSubAreas(): SubArea[] {
        return [
            {
                id: 'a2ccfc0a-233e-4758-9da8-d1a7bc636a2c',
                areaId: '95c9665d-ad11-4bf6-b25e-4b64c02196e7',
                version: '1.0',
                name: 'Blutuntersuchung',
                validFrom: '2023-01-01T00:00:00Z',
                validUntil: '2024-01-01T00:00:00Z',
                order: 1,
            },
            {
                id: '8ba4feae-f002-4f31-b06f-72f19dece33d',
                areaId: '83482f1f-0c43-4d22-8e81-3ed6c19650c6',
                version: '1.0',
                name: 'MRT',
                validFrom: '2023-02-01T00:00:00Z',
                validUntil: '2024-02-01T00:00:00Z',
                order: 1,
            },
            {
                id: 'ef481ba4-9234-4fa8-b3bc-dc3ec3d7ba6c',
                areaId: '83482f1f-0c43-4d22-8e81-3ed6c19650c6',
                version: '1.0',
                name: 'CT',
                validFrom: '2023-02-01T00:00:00Z',
                validUntil: '2024-02-01T00:00:00Z',
                order: 1,
            },
            {
                id: '7bb37e0f-2ab1-4ddb-9422-e15e813326d3',
                areaId: '4201d743-e2a8-4aa5-8bb2-88a37c9da669',
                version: '1.0',
                name: 'EKG',
                validFrom: '2023-02-01T00:00:00Z',
                validUntil: '2024-02-01T00:00:00Z',
                order: 1,
            },
            {
                id: 'ca76399e-42dd-401b-8405-9d9d4b5e20db',
                areaId: '97bb7da0-4729-40ed-8d6e-96fa330ba4f7',
                version: '1.0',
                name: 'Vitalparameter',
                validFrom: '2023-02-01T00:00:00Z',
                validUntil: '2024-02-01T00:00:00Z',
                order: 1,
            },
            {
                id: 'fff73543-04d5-4921-aab6-5feae2a596ea',
                areaId: '97bb7da0-4729-40ed-8d6e-96fa330ba4f7',
                version: '1.0',
                name: 'Anamnese',
                validFrom: '2023-02-01T00:00:00Z',
                validUntil: '2024-02-01T00:00:00Z',
                order: 1,
            },
            {
                id: '71fcd825-e2ab-4c7a-b3a1-615330a8ccf1',
                areaId: '1e690973-8328-45ac-991a-336ecad1d57a',
                version: '1.0',
                name: 'Sonstiges',
                validFrom: '2023-02-01T00:00:00Z',
                validUntil: '2024-02-01T00:00:00Z',
                order: 1,
            },
        ];
    }

    private getDefaultRecords(): Record[] {
        return [
            {
                id: 'c5725899-b20b-4aff-b349-8fe9f510d47d',
                clinicId: 'clinic-1234',
                name: 'Ambulant',
                version: '1.0',
                validFrom: new Date().toISOString(),
                validUntil: new Date().toISOString(),
            },
            {
                id: 'af27a2a9-81f4-44a7-b72f-58528949fc8d',
                clinicId: 'clinic-5678',
                name: 'Stationär',
                version: '1.0',
                validFrom: new Date().toISOString(),
                validUntil: new Date().toISOString(),
            },
        ];
    }

    private subscribeToFacades() {
        this.allSubs.push(
            this.areasFacade.areas$.subscribe((areas: Area[]) => {
                if (areas.length > 0) {
                    this.areas = areas;
                }
            })
        );

        this.allSubs.push(
            this.areasFacade.subAreas$.subscribe((subAreas: SubArea[]) => {
                if (subAreas.length > 0) {
                    this.subAreas = subAreas;
                }
            })
        );

        this.allSubs.push(
            this.recordsFacade.selectedRecord$.subscribe(
                (record: Record | null) => {
                    if (record) {
                        this.selectedRecord = record;
                    }
                }
            )
        );

        this.allSubs.push(
            this.recordsFacade.records$.subscribe((records: Record[]) => {
                if (records.length > 0) {
                    this.records = records;
                }
            })
        );
    }

    private async convertToBase64(filePath: string): Promise<any> {
        try {
            const response = await fetch(filePath);
            const blob = await response.blob();
            return await this.blobToBase64(blob);
        } catch (error) {
            console.error('Error:', error);
        }
    }

    private async refresh(): Promise<void> {
        if (!this.caseId)
            throw Error('Error refreshing view: no patientId found');

        this.vcPatientDetails = await this.api.getVcPatient(this.caseId);
        this.vcCaseOverview = await this.api.getVcCaseOverview(this.caseId);

        this.patientNameAndDob = {
            firstName: this.vcPatientDetails.patient.data.name,
            lastName: this.vcPatientDetails.patient.data.surname,
            dob: this.vcPatientDetails.patient.data.birth_date,
            gender: this.vcPatientDetails.patient.data.gender,
            ward: this.vcPatientDetails.current_place.data.room.serviceUnit
                .name,
            bed: this.vcPatientDetails.current_place.data.name,
            room: this.vcPatientDetails.current_place.data.room.name,
        };

        await this.loadCurveViewContent();
    }

    private async loadCurveViewContent(): Promise<void> {
        if (!this.caseId)
            throw Error(
                'Error loading curve viewContents: this.vcCaseOverview.case unavailable'
            );

        // Load all ViewContents for current case from DB
        const allVc = await this.repo.getAllEmptyViewContentForCase(
            this.caseId
        );

        // Complete the Curve groups ViewContents
        this.vc_CurveGroups = await Promise.all(
            allVc
                .filter((e) => e.locator.startsWith('case.curve.group'))
                .map((e) => this.repo.getFullViewContentForId<CurveGroup>(e.id))
        );
        // If there is n o data, create and save it
        if (!this.vc_CurveGroups?.length) {
            this.vc_CurveGroups = this.getEmptyVcCurve();
            for (const vc of this.vc_CurveGroups) {
                if (this.user) await this.repo.createViewContent(vc, this.user);
            }
        } else {
            for (const vc of this.vc_CurveGroups) {
                vc.history = await this.repo.getVcHistory<CurveGroup>(vc.id);
            }
        }
    }

    private getEmptyVcCurve(): VC_CurveGroup[] {
        const cid = this.caseId;
        const pid = this.vcCaseOverview?.case.related_patient_id;
        if (!cid || !pid) throw Error('Error creating VC_CurveGroup');

        // Upper part
        const notesRow = getNewCurveRow(uuidv4(), 'Notizen');
        const appointmentsRow = getNewCurveRow(uuidv4(), 'Termine');
        const diagnosticRow = getNewCurveRow(uuidv4(), 'Diagnostik');
        const therapyRow = getNewCurveRow(uuidv4(), 'Therapie');
        const basisGroup = getNewCurveGroup(
            uuidv4(),
            'Basisinformationen',
            'upper',
            [notesRow, appointmentsRow, diagnosticRow, therapyRow]
        );

        // Middle part
        const heartRate = getNewCurveRow(uuidv4(), 'Herzfrequenz');
        const bloodPressure = getNewCurveRow(uuidv4(), 'Blutdruck');
        const temperature = getNewCurveRow(uuidv4(), 'Temperatur');
        const respiratoryRate = getNewCurveRow(uuidv4(), 'Atemfrequenz');
        const vitalSigns = getNewCurveGroup(
            'vital_signs-' + uuidv4(),
            'Vitalwerte',
            'middle',
            [heartRate, bloodPressure, temperature, respiratoryRate]
        );

        // Lower part
        const accessGroup = getNewCurveGroup(
            uuidv4(),
            'Zugänge/Dränage/Bilanz',
            'lower'
        );
        const medicationGroup = getNewCurveGroup(
            uuidv4(),
            'Medikation',
            'lower'
        );

        const i18n: FormioRendererI18n = {
            de: {
                Eingabe: 'Eingabe',
                Speichern: 'Speichern',
                Abbrechen: 'Abbrechen',
                'Field Set': 'Field Set',
            },
            en: {
                Eingabe: 'Input',
                Speichern: 'Save',
                Abbrechen: 'Cancel',
                'Field Set': 'Field Set',
            },
        };

        const basisVc = getNewViewContent(
            'case.curve.group.' + basisGroup.id,
            pid,
            cid,
            basisGroup,
            uuidv4(),
            [],
            [],
            'other',
            dayjs().toISOString(),
            'not_final',
            i18n
        );
        const vitalSignsVc = getNewViewContent(
            'case.curve.group.' + this.caseId,
            pid,
            cid,
            vitalSigns,
            uuidv4(),
            [],
            [],
            'other',
            dayjs().toISOString(),
            'not_final',
            i18n
        );
        const medicationVc = getNewViewContent(
            'case.curve.group.' + medicationGroup.id,
            pid,
            cid,
            medicationGroup,
            uuidv4(),
            [],
            [],
            'other',
            dayjs().toISOString(),
            'not_final',
            i18n
        );
        const accessVc = getNewViewContent(
            'case.curve.group.' + medicationGroup.id,
            pid,
            cid,
            accessGroup,
            uuidv4(),
            [],
            [],
            'other',
            dayjs().toISOString(),
            'not_final',
            i18n
        );

        return [basisVc, vitalSignsVc, accessVc, medicationVc];
    }
}
