<div class="patient-overview-container">
    <!-- First Column: Anamnesis to Therapy -->
    <div class="patient-details-container-col">
        <!-- Medical History (Anamnese) -->
        <mat-list>
            <div mat-subheader class="patient-overview-container-title">
                {{
                    "COMPONENT.DIALOG_PATIENT_OVERVIEW.patientDetails.anamnesis.title"
                        | translate
                }}
                @if ( caseOverview?.medical_history?.history?.length===0){
                <button
                    mat-icon-button
                    (click)="
                        openFormDialog('form_anamnesis.json', patientNameAndDob)
                    "
                >
                    <mat-icon>add</mat-icon>
                </button>
                }@else {
                <button
                    mat-icon-button
                    (click)="
                        onClickOnElement(
                            caseOverview?.medical_history?.data,
                            'form_anamnesis.json',
                            patientNameAndDob,
                            patientDetailsI18n,
                            caseOverview?.medical_history?.history
                        )
                    "
                >
                    <mat-icon>edit</mat-icon></button
                >}
            </div>
            <app-dynamic-list
                [list]="[caseOverview?.medical_history]"
                [clipToWhenSmall]="1"
                [showButton]="false"
            >
                <ng-template #itemTemplate let-item="item">
                    <div
                        class="patient-details-container-content"
                        (click)="
                            onClickOnElement(
                                item.data,
                                'form_anamnesis.json',
                                patientNameAndDob,
                                patientDetailsI18n,
                                caseOverview?.medical_history?.history
                            )
                        "
                    >
                        <div class="patient-overview-item-title">
                            {{ item.data.medical_history }}
                        </div>

                        <div class="patient-overview-item-line">
                            {{
                                item.data.date
                                    | date
                                        : ("GENERAL.date_time_format"
                                              | translate)
                            }}
                        </div>
                    </div>
                </ng-template>
            </app-dynamic-list>
        </mat-list>

        <!-- Diagnosis -->
        <mat-list class="patient-overview-diagnoses">
            <div mat-subheader class="patient-overview-container-title">
                {{
                    "COMPONENT.DIALOG_PATIENT_OVERVIEW.patientDetails.diagnoses.title"
                        | translate
                }}
                @if ( caseOverview?.diagnose?.history?.length===0){
                <button
                    mat-icon-button
                    (click)="
                        openFormDialog('form_diagnoses.json', patientNameAndDob)
                    "
                >
                    <mat-icon>add</mat-icon></button
                >}@else {
                <button
                    mat-icon-button
                    (click)="
                        onClickOnElement(
                            caseOverview?.diagnose?.data,
                            'form_diagnoses.json',
                            patientNameAndDob,
                            patientDetailsI18n,
                            caseOverview?.diagnose?.history
                        )
                    "
                >
                    <mat-icon>edit</mat-icon>
                </button>
                }
            </div>
            <app-dynamic-list
                [list]="[caseOverview?.diagnose]"
                [clipToWhenSmall]="1"
                [showButton]="false"
            >
                <ng-template #itemTemplate let-item="item">
                    <div
                        class="patient-details-container-content"
                        (click)="
                            onClickOnElement(
                                item.data,
                                'form_diagnoses.json',
                                patientNameAndDob,
                                patientDetailsI18n,
                                caseOverview?.diagnose?.history
                            )
                        "
                    >
                        <div class="patient-overview-item-title">
                            {{ item.data.diagnose }}
                        </div>
                        <div class="patient-overview-item-line">
                            {{
                                item.data.created_at
                                    | date
                                        : ("GENERAL.date_time_format"
                                              | translate)
                            }}
                        </div>
                    </div>
                </ng-template>
            </app-dynamic-list>
        </mat-list>

        <!-- Interaction module -->
        <!-- <mat-list>
            <div mat-subheader class="patient-overview-container-title">
                {{
                    "COMPONENT.DIALOG_PATIENT_OVERVIEW.patientDetails.interactionModule.title"
                        | translate
                }}
                <button
                    mat-icon-button
                    (click)="
                        openFormDialog(
                            'form_interactionModule.json',
                            patientNameAndDob
                        )
                    "
                >
                    <mat-icon>add</mat-icon>
                </button>
            </div>
            <div
                *ngFor="let interaction of patientDetails.interactionModule"
                class="patient-details-container-content"
            >
                <div class="patient-overview-item-title">
                    {{ interaction | json }}
                </div>
            </div>
        </mat-list> -->

        <!-- Therapy -->
        <mat-list *ngIf="caseOverview?.therapy?.length !== 0">
            <div mat-subheader class="patient-overview-container-title">
                {{
                    "COMPONENT.DIALOG_PATIENT_OVERVIEW.patientDetails.therapy.title"
                        | translate
                }}
                <button
                    mat-icon-button
                    (click)="
                        openFormDialog('form_therapy.json', patientNameAndDob)
                    "
                >
                    <mat-icon>add</mat-icon>
                </button>
            </div>
            <app-dynamic-list
                [list]="caseOverview?.therapy!"
                [clipToWhenSmall]="1"
            >
                <ng-template #itemTemplate let-item="item">
                    <div
                        class="patient-details-container-content"
                        (click)="
                            onClickOnElement(
                                item.data,
                                'form_therapy.json',
                                patientNameAndDob,
                                patientDetailsI18n,
                                item.history
                            )
                        "
                    >
                        <div class="patient-overview-item-title">
                            {{ item.data.title }}
                        </div>

                        <div class="patient-overview-item-line">
                            {{
                                item.data.goalDateOn
                                    | date
                                        : ("GENERAL.date_time_format"
                                              | translate)
                            }}<span>{{
                                item.data.conducted
                                    ? "(D)"
                                    : item.data.registered
                                    ? "(A)"
                                    : null
                            }}</span>
                        </div>
                    </div>
                </ng-template>
            </app-dynamic-list>
        </mat-list>
    </div>

    <!-- Second Column: Vistite, Diagnostic and konsile-->
    <div class="patient-details-container-col">
        <!-- visite -->
        <mat-list *ngIf="caseOverview?.visit_record?.data">
            <div mat-subheader class="patient-overview-container-title">
                {{
                    "COMPONENT.DIALOG_PATIENT_OVERVIEW.patientDetails.visit.title"
                        | translate
                }}

                @if ( caseOverview?.visit_record?.history?.length===0){
                <button
                    mat-icon-button
                    (click)="
                        openFormDialog(
                            'form_visit.json.json',
                            patientNameAndDob
                        )
                    "
                >
                    <mat-icon>add</mat-icon></button
                >}@else {
                <button
                    mat-icon-button
                    (click)="
                        onClickOnElement(
                            caseOverview?.visit_record?.data,
                            'form_visit.json',
                            patientNameAndDob,
                            patientDetailsI18n,
                            caseOverview?.visit_record?.history
                        )
                    "
                >
                    <mat-icon>edit</mat-icon>
                </button>
                }
            </div>
            <app-dynamic-list
                [list]="[caseOverview?.visit_record]"
                [showButton]="true"
                [clipToWhenSmall]="2"
            >
                <ng-template #itemTemplate let-item="item">
                    <div
                        class="patient-details-container-content"
                        (click)="
                            onClickOnElement(
                                item.data,
                                'form_visit.json',
                                patientNameAndDob,
                                patientDetailsI18n,
                                caseOverview?.visit_record?.history
                            )
                        "
                    >
                        <div class="patient-overview-item-title">
                            {{ item.data.note }}
                        </div>

                        <div class="patient-overview-item-line">
                            {{
                                item.data.date_and_time
                                    | date
                                        : ("GENERAL.date_time_format"
                                              | translate)
                            }}
                        </div>
                    </div>
                </ng-template>
            </app-dynamic-list>
        </mat-list>

        <!-- Diagnostic -->
        <mat-list *ngIf="caseOverview?.diagnostic?.length !== 0">
            <div mat-subheader class="patient-overview-container-title">
                {{
                    "COMPONENT.DIALOG_PATIENT_OVERVIEW.patientDetails.diagnostics.title"
                        | translate
                }}
                <button
                    mat-icon-button
                    (click)="
                        openFormDialog(
                            'form_diagnostics.json',
                            patientNameAndDob
                        )
                    "
                >
                    <mat-icon>add</mat-icon>
                </button>
            </div>

            <app-dynamic-list [list]="caseOverview?.diagnostic!">
                <ng-template #itemTemplate let-item="item">
                    <div
                        class="patient-details-container-content"
                        (click)="
                            onClickOnElement(
                                item.data,
                                'form_diagnostics.json',
                                patientNameAndDob,
                                patientDetailsI18n,
                                item.history
                            )
                        "
                    >
                        <div class="patient-overview-item-title">
                            {{ item.data.title }}
                        </div>
                        <div class="patient-overview-item-line">
                            {{
                                item.data.goalDateOn
                                    | date
                                        : ("GENERAL.date_time_format"
                                              | translate)
                            }}
                        </div>
                    </div></ng-template
                >
            </app-dynamic-list>
        </mat-list>

        <!-- Consultations (Konsile) -->
        <mat-list *ngIf="caseOverview?.consultation">
            <div mat-subheader class="patient-overview-container-title">
                {{
                    "COMPONENT.DIALOG_PATIENT_OVERVIEW.patientDetails.consultations.title"
                        | translate
                            : { count: caseOverview?.consultation?.length }
                }}
                <button
                    mat-icon-button
                    (click)="
                        openFormDialog(
                            'form_consultations.json',
                            patientNameAndDob
                        )
                    "
                >
                    <mat-icon>add</mat-icon>
                </button>
            </div>

            <app-dynamic-list [list]="caseOverview?.consultation!">
                <ng-template #itemTemplate let-item="item">
                    <div
                        class="patient-details-container-content"
                        (click)="
                            onClickOnElement(
                                item.data,
                                'form_consultations.json',
                                patientNameAndDob,
                                patientDetailsI18n,
                                item.history
                            )
                        "
                    >
                        <div class="patient-overview-item-title">
                            {{ item.data.title }}
                        </div>

                        <div class="patient-overview-item-line">
                            {{
                                item.data.goalDateOn
                                    | date : ("GENERAL.date_format" | translate)
                            }}
                        </div>

                        <div class="patient-overview-item-line">
                            {{ item.data.details }}
                        </div>
                    </div>
                </ng-template>
            </app-dynamic-list>
        </mat-list>

        <!-- Pathogen -->
        <mat-list *ngIf="caseOverview?.pathogen">
            <div mat-subheader class="patient-overview-container-title">
                {{
                    "COMPONENT.DIALOG_PATIENT_OVERVIEW.patientDetails.pathogen.title"
                        | translate
                }}
                <button
                    mat-icon-button
                    (click)="
                        openFormDialog('form_pathogen.json', patientNameAndDob)
                    "
                >
                    <mat-icon>add</mat-icon>
                </button>
            </div>
            <app-dynamic-list [list]="caseOverview?.pathogen!">
                <ng-template #itemTemplate let-item="item">
                    <div
                        (click)="
                            onClickOnElement(
                                item.data,
                                'form_pathogen.json',
                                patientNameAndDob,
                                patientDetailsI18n,
                                item.history
                            )
                        "
                        class="patient-details-container-content"
                    >
                        <div class="patient-overview-item-title">
                            {{ item.data.name }}
                        </div>
                        <div class="patient-overview-item-line">
                            {{
                                "COMPONENT.DIALOG_PATIENT_OVERVIEW.patientDetails.pathogen.label_type"
                                    | translate : { type: item.data.type }
                            }}
                        </div>
                        <div class="patient-overview-item-line">
                            {{
                                item.data.date
                                    | date
                                        : ("GENERAL.date_time_format"
                                              | translate)
                            }}
                        </div>
                    </div>
                </ng-template>
            </app-dynamic-list>
        </mat-list>

        <!-- Antibiotics -->
        <!-- TODO -->

        <!-- Infectiology -->
        <!-- TODO -->

        <!-- Anticoagulation -->
        <!-- TODO -->
    </div>

    <!-- Third Column: Discharge to Tasks -->
    <div class="patient-details-container-col">
        <!-- Tasks -->
        <mat-list *ngIf="caseOverview?.task" class="third-column-task">
            <div mat-subheader class="patient-overview-container-title">
                {{
                    "COMPONENT.DIALOG_PATIENT_OVERVIEW.patientDetails.tasks.title"
                        | translate : { count: caseOverview?.task?.length }
                }}
                <button
                    mat-icon-button
                    (click)="
                        openFormDialog('form_tasks.json', patientNameAndDob)
                    "
                >
                    <mat-icon>add</mat-icon>
                </button>
            </div>

            <app-dynamic-list [list]="caseOverview?.task!">
                <ng-template #itemTemplate let-item="item">
                    <div
                        class="patient-details-container-content"
                        (click)="
                            onClickOnElement(
                                item.data,
                                'form_tasks.json',
                                patientNameAndDob,
                                patientDetailsI18n,
                                item.history
                            )
                        "
                    >
                        <div class="flex row gap-2">
                            <mat-icon
                                matListItemIcon
                                *ngIf="
                                    item.data.priority === 'high' ||
                                    item.data.priority === 'veryHigh'
                                "
                                class="task-open-icon"
                                >priority_high</mat-icon
                            >

                            <div class="patient-overview-item-title">
                                {{ item.data.task_name }}
                            </div>
                        </div>
                        <span
                            [ngClass]="{
                                'color-danger': item.priority === 'veryHigh'
                            }"
                            matListItemLine
                            >{{ item.data.contractor.surname }},
                            {{ item.data.contractor.name }}
                        </span>
                        <div class="patient-overview-item-line">
                            {{
                                item.data.goalDateOn
                                    | date
                                        : ("GENERAL.date_time_format"
                                              | translate)
                            }}
                        </div>
                    </div>
                </ng-template>
            </app-dynamic-list>
        </mat-list>

        <!-- Discharge -->
        <mat-list *ngIf="caseOverview?.discharge">
            <div mat-subheader class="patient-overview-container-title">
                {{
                    "COMPONENT.DIALOG_PATIENT_OVERVIEW.patientDetails.discharge.title"
                        | translate
                }}
                <button
                    mat-icon-button
                    (click)="
                        openFormDialog('form_discharge.json', patientNameAndDob)
                    "
                >
                    <mat-icon>add</mat-icon>
                </button>
            </div>

            <app-dynamic-list [list]="caseOverview?.discharge!">
                <ng-template #itemTemplate let-item="item">
                    <div
                        class="patient-details-container-content"
                        (click)="
                            onClickOnElement(
                                item.data,
                                'form_discharge.json',
                                patientNameAndDob,
                                patientDetailsI18n,
                                item.history
                            )
                        "
                    >
                        <div class="patient-overview-item-title">
                            {{ item.data.comment }}
                        </div>

                        <div class="patient-overview-item-line">
                            {{
                                item.data.dismissalExpectedOn
                                    | date : ("GENERAL.date_format" | translate)
                            }}
                        </div>
                    </div>
                </ng-template>
            </app-dynamic-list>
        </mat-list>

        <!-- Post treatment TODO-->
        <mat-list *ngIf="caseOverview?.post_treatment">
            <div mat-subheader class="patient-overview-container-title">
                {{
                    "COMPONENT.DIALOG_PATIENT_OVERVIEW.patientDetails.postTreatment.title"
                        | translate
                            : { count: caseOverview?.post_treatment?.length }
                }}
                <button
                    mat-icon-button
                    (click)="
                        openFormDialog(
                            'form_postTreatment.json',
                            patientNameAndDob
                        )
                    "
                >
                    <mat-icon>add</mat-icon>
                </button>
            </div>

            <app-dynamic-list [list]="caseOverview?.post_treatment!">
                <ng-template #itemTemplate let-item="item">
                    <div
                        class="patient-details-container-content"
                        (click)="
                            onClickOnElement(
                                item.data,
                                'form_postTreatment.json',
                                patientNameAndDob,
                                patientDetailsI18n,
                                item.history
                            )
                        "
                    >
                        <div class="patient-overview-item-title">
                            {{ item.data.postTreatment }}
                        </div>
                    </div>
                </ng-template>
            </app-dynamic-list>
        </mat-list>
    </div>

    <!-- Fourth Column: DRG and Discharge -->
    <div class="patient-details-container-col">
        <!-- Doctors -->
        <!-- TODO -->

        <!-- DRG -->
        <!-- <mat-list>
            <div mat-subheader class="patient-overview-container-title">
                DRG
                <button mat-icon-button (click)="openDialog()">
                    <mat-icon>add</mat-icon>
                </button>
            </div>
            <div
                class="patient-details-container-content"
                *ngFor="let drg of patientDetails.DRGs"
                (click)="onClickOnElement(drg, 'form_drg.json')"
            >
                <div class="patient-overview-item-title">
                    Target Discharge: {{ drg.targetDischarge }}
                </div>
                <div class="patient-overview-item-line">
                    Actual Discharge: {{ drg.actualDischarge }}
                </div>
                <div class="patient-overview-item-line">
                    Plan vs. Ziel: {{ differenceInDays }}
                </div>
            </div>
        </mat-list> -->
    </div>
</div>
