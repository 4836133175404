import {Injectable, NgZone, OnDestroy} from '@angular/core';
import {fromEvent, merge, of, Subject, Subscription} from 'rxjs';
import {NgEventBus} from 'ng-event-bus';
import {environment} from '../../../environments/environment';
import {decodeJwt, JWTPayload} from 'jose';
import {EventSourcePolyfill, EventSourcePolyfillInit} from 'event-source-polyfill';

export interface EventBusPayload {
    operation: string;
    topic: string;
}

const INITIAL_RETRY_DELAY = 2000;

@Injectable({
    providedIn: 'root',
})
export class ServerSentEventService implements OnDestroy {
    private subscriptions: Subscription[] = [];
    private retryCounts = new Map<string, number>(); // Speichert den Retry Count pro Topic
    private retryTimeouts = new Map<string, ReturnType<typeof setTimeout>>(); // Speichert die Retry Timeout ID pro Topic
    private topicSubscriptions = new Map<string, Subject<unknown>>();
    private offline = false;
    private eventSources = new Map<string, EventSource>();

    constructor(
        private zone: NgZone,
        private eventBus: NgEventBus
    ) {
        this.monitorNetworkStatus();
    }

    public getServerSentEvents(topic: string): Subject<unknown> {
        let subject = this.topicSubscriptions.get(topic);
        if (!subject) {
            subject = new Subject<unknown>();
            this.topicSubscriptions.set(topic, subject);
            this.initializeEventSource(topic, subject);
        }
        return subject;
    }

    public removeSubscription(topic: string): void {
        const subject = this.topicSubscriptions.get(topic);
        if (subject) {
            subject.unsubscribe();
            this.topicSubscriptions.delete(topic);
        }

        const eventSource = this.eventSources.get(topic);
        if (eventSource) {
            eventSource.close();
            this.eventSources.delete(topic);
        }

        const retryTimeout = this.retryTimeouts.get(topic);
        if (retryTimeout) {
            clearTimeout(retryTimeout);
            this.retryTimeouts.delete(topic);
        }
    }

    ngOnDestroy(): void {
        this.clearAllSubscriptions();
        this.eventSources.forEach((eventSource) => eventSource.close());
        this.eventSources.clear();
        this.retryTimeouts.forEach((timeout) => clearTimeout(timeout));
        this.retryTimeouts.clear();
    }

    private emitEventBus(eventType: string, operation: string, topic: string): void {
        const payload: EventBusPayload = {operation, topic};
        this.eventBus.cast(eventType, payload);
    }

    private initializeEventSource(topic: string, subject: Subject<unknown>): void {
        const url = new URL(this.getMercureBaseUrl());
        url.searchParams.append('topic', topic);

        const eventSourceOptions: EventSourcePolyfillInit = {
            headers: {
                'Connection': 'keep-alive',
                'Cache-Control': 'no-cache',
                'Authorization': `Bearer ${this.getMercureToken()}`,
                // 'X-Authorization': `Bearer ${this.getMercureToken()}`
            },
            heartbeatTimeout: 30000,
            // lastEventIdQueryParameterName: 'lastEventId'
        };

        this.setupEventSource(topic, subject, url.toString(), eventSourceOptions);
    }

    private setupEventSource(topic: string, subject: Subject<unknown>, url: string, eventSourceOptions: any): void {
        const eventSource = new EventSourcePolyfill(url, eventSourceOptions);

        eventSource.onopen = () => {
            this.zone.run(() => {
                this.emitEventBus('SSE_EVENT', 'OPENED', topic);
                console.info(`Connection opened for topic: ${topic}`);
                this.resetRetryCount(topic);
            });
        };

        eventSource.onmessage = (event) => {
            this.zone.run(() => {
                try {
                    const parsedData = event.data ? JSON.parse(event.data) : {};
                    subject.next(parsedData);
                } catch (err) {
                    console.error(`Error parsing message in EventSource for topic: ${topic}`, err);
                }
            });
        };

        eventSource.onerror = (err) => {
            this.zone.run(() => {
                // console.error(`Error in EventSource for topic: ${topic}`, err);
                this.emitEventBus('SSE_EVENT', 'CLOSED', topic);
                eventSource.close();
                this.retryConnection(topic);
            });
        };

        this.eventSources.set(topic, eventSource);
    }

    private monitorNetworkStatus(): void {
        this.subscriptions.push(
            merge(
                of(null),
                fromEvent(window, 'online'),
                fromEvent(window, 'offline')
            ).subscribe(() => {
                const isOnline = navigator.onLine;
                if (isOnline && this.offline) {
                    console.warn('ONLINE');
                    this.offline = false;
                    this.topicSubscriptions.forEach((_, topic) => {
                        console.warn(topic);
                        this.retryConnection(topic);
                    });
                } else if (!isOnline) {
                    console.warn('OFFLINE');
                    this.offline = true;
                    this.topicSubscriptions.forEach((_, topic) => this.emitEventBus('SSE_EVENT', 'CLOSED', topic));
                }
            })
        );
    }

    private retryConnection(topic: string): void {
        const retryCount = this.retryCounts.get(topic) || 0;

        const retryTimeout = this.retryTimeouts.get(topic);
        if (retryTimeout) {
            clearTimeout(retryTimeout);
        }

        const nextRetryTimeout = setTimeout(() => {
            this.retryCounts.set(topic, retryCount + 1);
            console.info(`Reconnecting to topic: ${topic}, attempt ${retryCount + 1}`);
            this.removeSubscription(topic);
            this.getServerSentEvents(topic);
        }, this.calculateRetryDelay(retryCount));

        this.retryTimeouts.set(topic, nextRetryTimeout);
    }

    private calculateRetryDelay(retryCount: number): number {
        const jitter = Math.random() * 1000; // Add a random jitter up to 1 second
        return INITIAL_RETRY_DELAY * Math.pow(2, retryCount) + jitter;
    }

    private resetRetryCount(topic: string): void {
        this.retryCounts.set(topic, 0);
    }

    private clearAllSubscriptions(): void {
        this.topicSubscriptions.forEach((subject) => subject.complete());
        this.topicSubscriptions.clear();
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    private getMercureBaseUrl(): string {
        return environment.mercureBaseUrl;
    }

    private getMercureToken(): string {
        const token = environment.mercureSubscriberToken;
        if (!this.validateToken(token)) {
            throw new Error('Invalid Mercure Subscriber Token');
        }
        return token;
    }

    private validateToken(token: string): boolean {
        if (!token) return false;

        const parts = token.split('.');
        if (parts.length !== 3) return false;

        try {
            const decodedToken = decodeJwt(token) as JWTPayload;
            if (decodedToken.exp && Date.now() >= decodedToken.exp * 1000) {
                return false;
            }
        } catch {
            return false;
        }

        return true;
    }
}
