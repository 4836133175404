import {createAction, props} from '@ngrx/store';
import {Locator} from "../../models/view-content.model";

export const loadLocatorState = createAction('[Locators] Load Locators');

export const setLocators = createAction(
    '[Locators] Set Locators',
    props<{ locators: Locator[] }>()
);

export const setLocatorsSuccess = createAction(
    '[Locators] Set Locators Successful',
    props<{ locators: Locator[] }>()
);

export const setLocatorsFail = createAction(
    '[Locators] Set Locators Fail',
    props<{ error: any }>()
);

export const loadLocatorsSuccess = createAction(
    '[Locators] Load Locators Successful',
    props<{ locators: Locator[] }>()
);

export const loadLocatorsFail = createAction(
    '[Locators] Load Locators Fail',
    props<{ error: any }>()
);
