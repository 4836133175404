import { Chart, Plugin } from 'chart.js';

const customTitle: Plugin = {
    id: 'customTitle',
    beforeLayout: (chart, args, opts) => {
        // if (!opts['x'].display) {
        //     return;
        // }

        const { ctx } = chart;
        ctx.font =
            opts['x'].font ||
            '12px "Helvetica Neue", Helvetica, Arial, sans-serif';

        const { width } = ctx.measureText(opts['x'].text);
        if (chart.options.layout?.padding) {

            const clientWidth = document.querySelector('.curve-left-most-cell')?.clientWidth ?? 0;
            const calculatedPadding = clientWidth - 210;
            // @ts-ignore
            chart.options.layout.padding.left = calculatedPadding;
            // @ts-ignore
            chart.options.layout.padding.right = 0;
        }
    },
    afterDraw: (chart, args, opts) => {
        const {
            ctx,
            chartArea: { top },
        } = chart;

        for(const opsKey of Object.keys(opts)) {
            if (opsKey === 'x') {
                continue;
            }

            if (opts[opsKey].display) {
                ctx.fillStyle = opts[opsKey].color || Chart.defaults.color;
                ctx.font =
                    opts[opsKey].font ||
                    '12px "Helvetica Neue", Helvetica, Arial, sans-serif';

                const offsetX = chart.scales[opsKey].left + 25;

                ctx.fillText(
                    opts[opsKey].text,
                    offsetX,
                    top + (10 * -1),
                );
            }
        }
    },
};

export default customTitle;
