@if (lineChartData() && lineChartOptions()) {
<div class="chart-wrapper">
    <canvas class="curve" baseChart [data]="lineChartData()" [options]="lineChartOptions()" [type]="lineChartType"
        [plugins]="plugins || []" (chartClick)="chartClicked($event)">
    </canvas>
</div>
} @else {
<div class="alert alert-warning" role="alert">
    No data available
</div>
}
