import {createAction, props} from '@ngrx/store';
import {Record} from "../../models/patient-records.model";

export const loadRecords = createAction('[Records] Load Records');
export const loadRecordsSuccess = createAction('[Records] Load Records Success', props<{ records: Record[] }>());
export const loadRecordsFailure = createAction('[Records] Load Records Failure', props<{ error: any }>());

export const loadSelectedRecord = createAction('[Records] Load Selected Record');
export const loadSelectedRecordSuccess = createAction('[Records] Load Selected Record Success', props<{
    record: Record | null
}>());
export const loadSelectedRecordFailure = createAction('[Records] Load Selected Record Failure', props<{
    error: any
}>());

export const setSelectedRecord = createAction('[Records] Set Selected Record', props<{ record: Record }>());
export const removeSelectedRecord = createAction('[Records] Remove Selected Record');
export const setSelectedRecordSuccess = createAction('[Records] Set Selected Record Success', props<{
    record: Record
}>());
export const removeSelectedRecordSuccess = createAction('[Records] Remove Selected Record Success');
export const setSelectedRecordFailure = createAction('[Records] Set Selected Record Failure', props<{ error: any }>());
