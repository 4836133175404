import { MatIconModule } from '@angular/material/icon';
import { Component, Inject, OnInit } from '@angular/core';
import {
    MatDialog,
    MAT_DIALOG_DATA,
    MatDialogTitle,
    MatDialogContent,
    MatDialogClose,
    MatDialogRef,
} from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { CalAgePipe, DayAgoPipe } from '@lohmann-birkner/luic';
import { TranslateModule } from '@ngx-translate/core';

import { CommonModule } from '@angular/common';
import { PatientNameAndDob } from '../../pages/patient-details/patient-details.component';
import {
    FormModalComponent,
    FormModalResult,
} from '../form-modal/form-modal.component';
import { firstValueFrom } from 'rxjs';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { FormioRendererI18n } from '../../components/data-interaction/formio-renderer/formio-renderer.component';
import { ChangingHistoryComponent } from '../../components/data-interaction/changing-history/changing-history.component';
import { MatBadgeModule } from '@angular/material/badge';

@Component({
    selector: 'app-start-page-task-popup',
    templateUrl: './start-page-task-popup.component.html',
    styleUrls: ['./start-page-task-popup.component.scss'],
    imports: [
        MatDialogTitle,
        MatDialogContent,
        MatCardModule,
        MatExpansionModule,
        CalAgePipe,
        DayAgoPipe,
        TranslateModule,
        CommonModule,
        MatIconModule,
        MatButtonModule,
        MatListModule,
        MatDialogClose,
        MatBadgeModule,
        ChangingHistoryComponent,
    ],
    standalone: true,
})
export class StartPageTaskPopupComponent implements OnInit {
    showHistory: boolean = false;
    history: any[]; //history contains all the data
    viewContentI18n: FormioRendererI18n;
    numberOfHistories: number | undefined;
    currentBreakpoint: string = '';

    constructor(
        public dialogRef: MatDialogRef<FormModalResult>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            data: any;
            patient_info: PatientNameAndDob;
            viewContentI18n: FormioRendererI18n;
            currentBreakpoint: string;
        },
        public dialog: MatDialog
    ) {
        this.history = data.data;
        this.viewContentI18n = data.viewContentI18n;
        this.currentBreakpoint = data.currentBreakpoint;
    }

    ngOnInit() {
        this.history = this.showGoalDateOn(this.history);
    }

    toggleHistory() {
        this.showHistory = !this.showHistory;
    }
    public onClose(): void {
        this.dialogRef.close(); // Optionally pass a result here if needed
    }
    /** open a exsiting task formular */
    public async onClickOnElement(
        formFileName: string,
        data: any, //task data
        patientInfo?: PatientNameAndDob,
        history?: any
    ): Promise<void> {
        const dialogConfig: any = {
            data: {
                form_file_name: formFileName,
                form_data: { data }, //task data
                patient_info: patientInfo,
                history,
                viewContentI18n: this.viewContentI18n,
            },
            panelClass: 'home-task-dialog',
        };
        // Add logic for dialog position based on the currentBreakpoint
        if (
            this.currentBreakpoint === 'handsetPortrait' ||
            this.currentBreakpoint === 'handsetLandscape'
        ) {
            dialogConfig.position = { bottom: '0' };
            dialogConfig.height = '80%';
        }

        const dialogRef = this.dialog.open(FormModalComponent, dialogConfig);
        const res = await firstValueFrom(dialogRef.afterClosed());
    }

    /** to show exactly goal date in the form  */
    /** to check if there is a goal date already in the data, if true, then add one more
     * attribute goal_date, to show the radio('today'|'yesterday'|'tomorrow'|'on') in the form */
    private showGoalDateOn(data: any[]): any[] {
        return data.map((el) => {
            let updatedEl = { ...el };
            if (el.data.goalDateOn) {
                updatedEl.data.goal_date = 'on';
            }
            if (el.data.dayCountDeactivateOn) {
                updatedEl.data.dayCountDeactivate = 'on';
            }
            return updatedEl;
        });
    }
}
