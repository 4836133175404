import { AuthorizationToken, Device, UserToken } from '../models/auth.model';
import { Injectable } from '@angular/core';
import { DEFAULT_SETTINGS, Settings } from '../models/settings.model';
import {
    LS_ACCESS_TOKEN,
    LS_AUTHZ_TOKENS,
    LS_CODE_VERIFIER,
    LS_DEVICE,
    LS_LOCATORS,
    LS_PATIENT_RECORDS,
    LS_PATIENT_RECORDS_AREAS,
    LS_PATIENT_RECORDS_SUBAREAS,
    LS_PATIENT_SELECTED_RECORD,
    LS_SETTINGS,
    LS_VIEWCONTENT,
} from '../shared/constants';
import { Preferences } from '@capacitor/preferences';
import { DbService } from './database/db.service';
import { MercureStatus } from '../models/mercure.model';
import { Area, Record, SubArea } from '../models/patient-records.model';
import {
    VcHistoryElement,
    ViewContent,
} from '../models/view-content.models/view-content.model';
import { capSQLiteChanges } from '@capacitor-community/sqlite';
import { Locator } from '../models/view-content.model';
import { CP2_User } from '../models/view-content.models/view-content-personal-domain.model';

//
// TODO: BIG TODO!!! Check the use of Access and Authz with care
//

@Injectable({
    providedIn: 'root',
})
export class DataRepositoryService {
    private static readonly TAG = 'DataRepositoryService';

    public constructor(private db: DbService) {}

    //#region Authz
    /**
     * Safely gets all authorization tokens. Will return all saved authz tokens or an empty array in case of error or none
     */
    public async getAllAuthzTokens(): Promise<AuthorizationToken[]> {
        try {
            const v = (await Preferences.get({ key: LS_AUTHZ_TOKENS })).value;

            if (v) return JSON.parse(v);
        } catch (e) {
            console.warn(
                DataRepositoryService.TAG,
                'error retrieving authz tokens',
                e
            );
        }

        return [];
    }

    /**
     * Save the given array to the preferences' authorization tokens. Will clean up the expired tokens before save.
     * Will overwrite whatever was in preferences before.
     */
    public async saveAuthzTokens(tokens: AuthorizationToken[]): Promise<void> {
        // Remove tokens that are older than 300 seconds
        const currentTimeInSeconds = Math.floor(Date.now() / 1000);
        tokens = tokens.filter((token) => {
            const expiresIn = token.exp;
            return expiresIn >= 0 && expiresIn <= 300 + currentTimeInSeconds;
        });

        await Preferences.set({
            key: LS_AUTHZ_TOKENS,
            value: JSON.stringify(tokens),
        });
    }

    //#endregion

    //#region Access Token
    public async getAccessToken(): Promise<UserToken | null> {
        try {
            const value = (await Preferences.get({ key: LS_ACCESS_TOKEN }))
                .value;

            return value ? JSON.parse(value) : null;
        } catch (e) {
            console.warn(
                `${DataRepositoryService.TAG}, error retrieving access token`,
                e
            );
            return null;
        }
    }

    public async setAccessToken(token: UserToken | null): Promise<void> {
        if (!token) {
            await Preferences.remove({ key: LS_ACCESS_TOKEN });
            return;
        }

        await Preferences.set({
            key: LS_ACCESS_TOKEN,
            value: JSON.stringify(token),
        });
    }

    public async removeAccessToken(): Promise<void> {
        return await this.setAccessToken(null);
    }

    public async saveCodeVerifier(codeVerifier: string | null): Promise<void> {
        if (!codeVerifier) {
            await Preferences.remove({ key: LS_CODE_VERIFIER });
            return;
        }

        await Preferences.set({
            key: LS_CODE_VERIFIER,
            value: codeVerifier,
        });
    }

    public async getCodeVerifier(): Promise<string | null> {
        try {
            return (await Preferences.get({ key: LS_CODE_VERIFIER })).value;
        } catch (e) {
            console.warn(
                `${DataRepositoryService.TAG}, error retrieving code verifier`,
                e
            );
            return null;
        }
    }

    public async removeCodeVerifier(): Promise<void> {
        try {
            await Preferences.remove({ key: LS_CODE_VERIFIER });
        } catch (e) {
            console.error(
                `${DataRepositoryService.TAG}, error removing code verifier`,
                e
            );
        }
    }

    //#endregion

    //#region settings
    public async saveSettings(settings: Settings): Promise<void> {
        await Preferences.set({
            key: LS_SETTINGS,
            value: JSON.stringify(settings),
        });
    }

    public async getSettings(): Promise<Settings> {
        try {
            const value = (await Preferences.get({ key: LS_SETTINGS })).value;
            return value ? JSON.parse(value) : DEFAULT_SETTINGS;
        } catch (e) {
            console.warn(
                `${DataRepositoryService.TAG}, error retrieving settings`,
                e
            );
            return DEFAULT_SETTINGS;
        }
    }

    //#endregion

    //#region locators

    public async getLocators(): Promise<Locator[]> {
        try {
            const v = (await Preferences.get({ key: LS_LOCATORS })).value;

            if (v) return JSON.parse(v);
        } catch (e) {
            console.warn(
                DataRepositoryService.TAG,
                'error retrieving locators',
                e
            );
        }

        return [];
    }

    public async saveLocators(locator: Locator[]): Promise<void> {
        await Preferences.set({
            key: LS_LOCATORS,
            value: JSON.stringify(locator),
        });
    }

    //#endregion

    //#region settings
    public async saveMercure(mercure: MercureStatus): Promise<void> {
        /*  await Preferences.set({
         key: LS_SETTINGS,
         value: JSON.stringify(settings),
         });*/
    }

    public async getMercure(): Promise<any> {
        /* try {
         const value = (await Preferences.get({ key: LS_SETTINGS })).value;
         return value ? JSON.parse(value) : DEFAULT_SETTINGS;
         } catch (e) {
         console.warn(
         `${DataRepositoryService.TAG}, error retrieving settings`,
         e
         );
         return DEFAULT_SETTINGS;
         }*/
    }

    //#endregion

    //#region Device
    public async getDevice(): Promise<Device> {
        try {
            const v = (await Preferences.get({ key: LS_DEVICE })).value;

            if (v) return JSON.parse(v);
        } catch (e) {
            console.warn(
                DataRepositoryService.TAG,
                'error retrieving device id',
                e
            );
        }

        return { deviceId: '' };
    }

    public async saveDevice(device: Device): Promise<void> {
        await Preferences.set({
            key: LS_DEVICE,
            value: JSON.stringify(device),
        });
    }

    //#endregion

    //#region ViewContent
    async setViewContent(viewContent: ViewContent<any>[]): Promise<any> {
        await Preferences.set({
            key: LS_VIEWCONTENT,
            value: JSON.stringify(viewContent),
        });
    }

    async getViewContent(): Promise<ViewContent<any>[]> {
        try {
            const v = (await Preferences.get({ key: LS_VIEWCONTENT })).value;

            if (v) return JSON.parse(v);
        } catch (e) {
            console.warn(
                DataRepositoryService.TAG,
                'error retrieving viewcontent',
                e
            );
        }

        return [];
    }

    // async createViewContent(viewContent: ViewContent): Promise<any> {
    //     return this.dbService.createViewContent(
    //         viewContent.locator,
    //         viewContent.content,
    //         viewContent.scopes ?? ''
    //     );
    // }

    // async getViewContentById(id: number): Promise<ViewContent> {
    //     const result = await this.db.getViewContentById(id.toString());
    //     return result.values; // Convert to ViewContent if necessary
    // }

    // async getAllViewContent(): Promise<ViewContent[]> {
    //     const results = await this.db.getAllViewContent();
    //     return results.values;
    // }

    // async updateViewContent(viewContent: ViewContent): Promise<any> {
    //     if (!viewContent.id || viewContent.id < 1)
    //         throw Error(
    //             'view content must have a positive id property for update'
    //         );

    //     return this.db.updateViewContent(
    //         viewContent.id,
    //         viewContent.locator,
    //         viewContent.content,
    //         viewContent.scopes ?? ''
    //     );
    // }

    async deleteViewContent(id: number): Promise<any> {
        return this.db.deleteViewContent(id);
    }

    //#endregion

    //#region patientRecords

    async getRecords(): Promise<Record[]> {
        const { value } = await Preferences.get({ key: LS_PATIENT_RECORDS });
        return value ? JSON.parse(value) : [];
    }

    async setRecords(records: Record[]): Promise<void> {
        await Preferences.set({
            key: LS_PATIENT_RECORDS,
            value: JSON.stringify(records),
        });
    }

    async getSelectedRecord(): Promise<Record | null> {
        const { value } = await Preferences.get({
            key: LS_PATIENT_SELECTED_RECORD,
        });
        return value ? JSON.parse(value) : null;
    }

    async setSelectedRecord(record: Record): Promise<void> {
        await Preferences.set({
            key: LS_PATIENT_SELECTED_RECORD,
            value: JSON.stringify(record),
        });
    }

    async removeSelectedRecord(): Promise<void> {
        await Preferences.remove({ key: LS_PATIENT_SELECTED_RECORD });
    }

    async getAreas(): Promise<Area[]> {
        const { value } = await Preferences.get({
            key: LS_PATIENT_RECORDS_AREAS,
        });
        return value ? JSON.parse(value) : [];
    }

    async getSubAreas(): Promise<SubArea[]> {
        const { value } = await Preferences.get({
            key: LS_PATIENT_RECORDS_SUBAREAS,
        });
        return value ? JSON.parse(value) : [];
    }

    async addArea(area: Area): Promise<Area> {
        const areas = await this.getAreas();
        areas.push(area);
        await Preferences.set({
            key: LS_PATIENT_RECORDS_AREAS,
            value: JSON.stringify(areas),
        });
        return area;
    }

    async updateArea(updatedArea: Area): Promise<Area> {
        let areas = await this.getAreas();
        areas = areas.map((area) =>
            area.id === updatedArea.id ? updatedArea : area
        );
        await Preferences.set({
            key: LS_PATIENT_RECORDS_AREAS,
            value: JSON.stringify(areas),
        });
        return updatedArea;
    }

    async removeArea(areaId: string): Promise<void> {
        let areas = await this.getAreas();
        areas = areas.filter((area) => area.id !== areaId);
        await Preferences.set({
            key: LS_PATIENT_RECORDS_AREAS,
            value: JSON.stringify(areas),
        });
    }

    async addSubArea(subArea: SubArea): Promise<SubArea> {
        const subAreas = await this.getSubAreas();
        subAreas.push(subArea);
        await Preferences.set({
            key: LS_PATIENT_RECORDS_SUBAREAS,
            value: JSON.stringify(subAreas),
        });
        return subArea;
    }

    async updateSubArea(updatedSubArea: SubArea): Promise<SubArea> {
        let subAreas = await this.getSubAreas();
        subAreas = subAreas.map((subArea) =>
            subArea.id === updatedSubArea.id ? updatedSubArea : subArea
        );
        await Preferences.set({
            key: LS_PATIENT_RECORDS_SUBAREAS,
            value: JSON.stringify(subAreas),
        });
        return updatedSubArea;
    }

    async removeSubArea(subAreaId: string): Promise<void> {
        let subAreas = await this.getSubAreas();
        subAreas = subAreas.filter((subArea) => subArea.id !== subAreaId);
        await Preferences.set({
            key: LS_PATIENT_RECORDS_SUBAREAS,
            value: JSON.stringify(subAreas),
        });
    }

    //#endregion

    //#region ViewContent V2
    public async createViewContent<T>(
        vc: ViewContent<T>,
        user: CP2_User
    ): Promise<capSQLiteChanges> {
        const dataRes = await this.createVcData(vc.data);
        if (!dataRes?.changes || !dataRes.changes.lastId)
            throw Error('Error saving VC_Data');

        const vcRes = await this.db.createViewContent(
            vc.id,
            vc.locator,
            JSON.stringify(vc.owners),
            JSON.stringify(vc.owner_departments),
            vc.main_owner_job_type,
            vc.created_at,
            vc.status,
            vc.related_patient_id,
            vc.related_case_id,
            dataRes.changes.lastId,
            JSON.stringify(vc.form),
            JSON.stringify(vc.i18n)
        );
        if (!vcRes?.changes || !vcRes.changes.lastId)
            throw Error('Error saving ViewContent');

        // Create new user in if necessary
        const userRes = await this.createCP2User(user);
        // TODO: Check problems/errors

        //Store the current data in history with current time
        const histRes = await this.db.createViewContentHistory(
            vc.id,
            dataRes.changes.lastId,
            new Date().toISOString(),
            user.userId
        );
        if (!histRes?.changes || !histRes.changes.lastId)
            throw Error('Error saving History');

        return vcRes;
    }

    public async updateViewContent<T>(
        vc: ViewContent<T>,
        user: CP2_User
    ): Promise<any> {
        const dataRes = await this.createVcData(vc.data);
        if (!dataRes?.changes || !dataRes.changes.lastId)
            throw Error('Error saving VC_Data');

        const vcRes = await this.db.updateViewContent(
            vc.id,
            vc.locator,
            JSON.stringify(vc.owners),
            JSON.stringify(vc.owner_departments),
            vc.main_owner_job_type,
            vc.created_at,
            vc.status,
            vc.related_patient_id,
            vc.related_case_id,
            dataRes.changes.lastId,
            JSON.stringify(vc.form),
            JSON.stringify(vc.i18n)
        );
        if (!vcRes?.changes) throw Error('Error saving ViewContent');

        // Create new user in if necessary
        const userRes = await this.createCP2User(user);
        // TODO: Check problems/errors

        //Store the current data in history with current time
        const histRes = await this.db.createViewContentHistory(
            vc.id,
            dataRes.changes.lastId,
            new Date().toISOString(),
            user.userId
        );
        if (!histRes?.changes || !histRes.changes.lastId)
            throw Error('Error saving History');
    }

    /** @deprecated */
    public async updateViewContentOld<T>(
        vc: ViewContent<T>,
        userId: string
    ): Promise<any> {
        const dataRes = await this.createVcData(vc.data);
        if (!dataRes?.changes || !dataRes.changes.lastId)
            throw Error('Error saving VC_Data');

        const vcRes = await this.db.updateViewContent(
            vc.id,
            vc.locator,
            JSON.stringify(vc.owners),
            JSON.stringify(vc.owner_departments),
            vc.main_owner_job_type,
            vc.created_at,
            vc.status,
            vc.related_patient_id,
            vc.related_case_id,
            dataRes.changes.lastId,
            JSON.stringify(vc.form),
            JSON.stringify(vc.i18n)
        );
        if (!vcRes?.changes) throw Error('Error saving ViewContent');

        //Store the current data in history with current time
        const histRes = await this.db.createViewContentHistory(
            vc.id,
            dataRes.changes.lastId,
            new Date().toISOString(),
            userId
        );
        if (!histRes?.changes || !histRes.changes.lastId)
            throw Error('Error saving History');
    }

    public async createOrUpdateViewContent<T>(
        vc: ViewContent<T>,
        user: CP2_User
    ): Promise<any> {
        if (vc.id === '-1') return await this.createViewContent(vc, user);

        const e = await this.getEmptyViewContentForId(vc.id);

        if (e) return await this.updateViewContent(vc, user);

        return await this.createViewContent(vc, user);
    }

    public async getAllEmptyViewContentForCase(
        caseId: string
    ): Promise<ViewContent<null>[]> {
        const res = await this.db.getAllViewContentForCase(caseId);

        return res.values.map((c: any) => this.parseViewContent(c));
    }

    public async getAllEmptyViewContent(): Promise<ViewContent<null>[]> {
        const res = await this.db.getAllViewContent();

        return res.values.map((c: any) => this.parseViewContent(c));
    }

    public async getEmptyViewContentForId(
        id: string
    ): Promise<ViewContent<null> | undefined> {
        const res = (await this.db.getViewContentById(id)).values?.[0];

        return res ? this.parseViewContent(res) : undefined;
    }

    public async getFullViewContentForId<T>(
        id: string
    ): Promise<ViewContent<T>> {
        const vc = (await this.db.getViewContentById(id)).values?.[0];
        if (!vc) throw Error('Error fetching full view content for id=' + id);

        const data = await this.getVcData(vc.data_id);
        vc.data = data;
        const history = await this.getVcHistory(vc.data_id);
        vc.history = history;

        return this.parseViewContent(vc);
    }

    private parseViewContent<T>(vc: any): ViewContent<T> {
        try {
            vc.i18n = vc.i18n ? JSON.parse(vc.i18n) : undefined;
            vc.owner_departments = vc.owner_departments
                ? JSON.parse(vc.owner_departments)
                : undefined;
            vc.owner = vc.owner ? JSON.parse(vc.owner) : undefined;
        } catch (e) {
            console.warn('Error parsing View Content', e);
        }
        return vc;
    }
    //#endregion

    //#region VC Data & VC History
    private async createVcData(data: any): Promise<capSQLiteChanges> {
        return await this.db.createVcData(JSON.stringify(data));
    }

    private async getVcData(id: number): Promise<any> {
        const res = await this.db.getVcDataForId(id);
        if (!res.values?.[0]?.data) return null;

        return JSON.parse(res.values[0].data);
    }

    public async getVcHistory<T>(vcId: string): Promise<VcHistoryElement<T>[]> {
        const res = await this.db.getHistoryForViewContent(vcId);

        // Build an array with the full editors
        const v: any[] = res.values ?? [];
        const editorIds = Array.from(new Set(v.map((item) => item['editor'])));
        const editors: CP2_User[] = [];
        for (const id of editorIds) {
            const ed = await this.getCp2UserById(id);
            if (ed) editors.push(ed);
        }

        if (res.values?.length) {
            const h = res.values.map<VcHistoryElement<T>>((e) => ({
                modifiedAt: e.datetime,
                modifiedBy:
                    editors.find((ed) => ed.userId === e.editor) ??
                    ({} as CP2_User),
                data: JSON.parse(e.data),
            }));
            return h;
        }

        return [];
    }
    //#endregion

    //#region CP2_User
    private async createCP2User(u: CP2_User): Promise<capSQLiteChanges> {
        return await this.db.createCp2User(
            u.userId,
            u.surname,
            u.name,
            u.validSince ?? '',
            u.validUntil ?? ''
        );
    }

    private async updateCP2User(u: CP2_User): Promise<capSQLiteChanges> {
        return await this.db.updateCp2User(
            u.userId,
            u.surname,
            u.name,
            u.validSince ?? '',
            u.validUntil ?? ''
        );
    }

    private async getCp2UserById(id: string): Promise<CP2_User | null> {
        const res = await this.db.getCp2UserById(id);
        if (!res.values?.[0]) return null;

        return res.values[0];
    }
    //#endregion
}
