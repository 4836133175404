import { Chart, Plugin } from 'chart.js';
import dayjs from 'dayjs';

const backgroundColorPlugin: Plugin = {
    id: 'backgroundColorPlugin',
    beforeDraw: (chart: Chart) => {
        const ctx = chart.ctx;
        const xAxis = chart.scales['x'];
        const yAxis = chart.scales['y'];

        ctx.save();
        ctx.fillStyle = '#eee';

        // Find the index of the current day
        const dayIndex = xAxis.ticks.findIndex((tick) =>
            dayjs().isSame(tick.value, 'day')
        );

        if (dayIndex !== -1) {
            const x = xAxis.getPixelForTick(dayIndex);

            const calculatedDayIndex = dayIndex + 6;
            const tickIndex = calculatedDayIndex >= xAxis.ticks.length ? xAxis.ticks.length -1 : calculatedDayIndex;

            const width = xAxis.getPixelForTick(tickIndex) - x;

            ctx.fillRect(x, yAxis.top, width, yAxis.bottom - yAxis.top);
        }

        ctx.restore();
    },
};

export default backgroundColorPlugin;
