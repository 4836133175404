import {
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import {
    MatPaginator,
    MatPaginatorIntl,
    MatPaginatorModule,
    PageEvent,
} from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTable, MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule, DatePipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { FormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { UpcommingPageComponent } from '../../../pages/base/upcomming-page/upcomming-page.component';
import { CalAgePipe, DayAgoPipe } from '@lohmann-birkner/luic';
import { OrgaTabsMedicalOrderComponent } from '../orga-tabs-medical-order/orga-tabs-medical-order.component';
import { OrgaTabsTasksComponent } from '../orga-tabs-tasks/orga-tabs-tasks.component';
import { OrgaTabsCommentsComponent } from '../orga-tabs-comments/orga-tabs-comments.component';
import { OrgaTabsDiagFindingsComponent } from '../orga-tabs-diag-findings/orga-tabs-diag-findings.component';
import { Cp2MatPaginatorIntl } from '../../../shared/Cp2MatPaginatorIntl';
import { PatientListItem } from '../../../models/patient.model';
import { MatMenuModule } from '@angular/material/menu';
import { HomePatientTasksComponent } from '../../home-page/home-patient-tasks/home-patient-tasks.component';
import { HomePatientVisitComponent } from '../../home-page/home-patient-visit/home-patient-visit.component';
import { HomePatientBasicInfoComponent } from '../../home-page/home-patient-basic-info/home-patient-basic-info.component';
import { FormioRendererI18n } from '../../data-interaction/formio-renderer/formio-renderer.component';
import { VcPatientListItem } from '../../../models/view-content.models/view-content.model';

@Component({
    selector: 'app-orga-tabs',
    templateUrl: './orga-tabs.component.html',
    styleUrls: ['./orga-tabs.component.scss'],
    standalone: true,
    imports: [
        MatFormFieldModule,
        MatInputModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatIconModule,
        TranslateModule,
        DatePipe,
        MatButtonModule,
        MatButtonToggleModule,
        FormsModule,
        UpcommingPageComponent,
        CommonModule,
        MatChipsModule,
        CalAgePipe,
        OrgaTabsMedicalOrderComponent,
        OrgaTabsTasksComponent,
        OrgaTabsCommentsComponent,
        DayAgoPipe,
        OrgaTabsDiagFindingsComponent,
        MatMenuModule,
        HomePatientBasicInfoComponent,
        HomePatientVisitComponent,
        HomePatientTasksComponent,
    ],
    providers: [{ provide: MatPaginatorIntl, useClass: Cp2MatPaginatorIntl }],
})
export class OrgaTabsComponent implements OnChanges, OnInit {
    @Input() orgaPatients: VcPatientListItem[] = [];
    @Input() viewContentI18n: FormioRendererI18n | undefined;
    // Mat table variables
    public displayedColumns = ['basicInfo', 'visit', 'tasks'];

    // Paginator variables
    public page = 0;
    public pageSize = 10;

    public patientListToShow: VcPatientListItem[] = [];

    public constructor() {}
    ngOnInit(): void {
        console.log(this.orgaPatients)
    }

    public ngOnChanges(): void {}

    //#region Listeners
    public onPagerChange($event: PageEvent) {
        this.page = $event.pageIndex;
        this.pageSize = $event.pageSize;

        this.paginate();
    }
    //#endregion

    private paginate(): void {
        // JG: Pagination deactivated on 3.06.2024
        // this.patientListToShow = this.orgaPatients.slice(
        //     this.pageSize * this.page,
        //     this.pageSize * (this.page + 1)
        // );

        this.patientListToShow = this.orgaPatients;
    }
}
