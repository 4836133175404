import {
    capSQLiteChanges,
    DBSQLiteValues,
    SQLiteDBConnection,
} from '@capacitor-community/sqlite';
import { Injectable } from '@angular/core';
import { SQLiteService } from './sqlite.service';
import { DbnameVersionService } from './dbname-version.service';
import { MigrationUpgradeStatements } from './migrations.statement';

/*
 * Add your migrations in the `upgradeMigrations` array of `MigrationUpgradeStatements` (migrations.statements.ts)
 */
@Injectable()
export class DbService {
    private static readonly TAG = 'DbService';

    private databaseName: string = '';
    private migrationsStatements = new MigrationUpgradeStatements();
    private upgradeMigrations;
    private loadToVersion;
    private db!: SQLiteDBConnection;

    public constructor(
        private sqliteService: SQLiteService,
        private dbVerService: DbnameVersionService
    ) {
        this.upgradeMigrations = this.migrationsStatements.upgradeMigrations;
        this.loadToVersion =
            this.upgradeMigrations[this.upgradeMigrations.length - 1].toVersion;
    }

    public async initializeDatabase(dbName: string) {
        this.databaseName = dbName;
        // create upgrade statements
        await this.sqliteService.addUpgradeStatement({
            database: this.databaseName,
            upgrade: this.upgradeMigrations,
        });
        // create and/or open the database
        this.db = await this.sqliteService.openDatabase(
            this.databaseName,
            false,
            'no-encryption',
            this.loadToVersion,
            false
        );
        this.dbVerService.set(this.databaseName, this.loadToVersion);
    }

    //#region ViewContent CRUD functions
    public async createViewContent(
        id: string,
        locator: string,
        owners: string,
        owner_departments: string,
        main_owner_job_type: string,
        created_at: string,
        status: string,
        related_patient_id: string,
        related_case_id: string,
        data_id: number,
        form: string,
        i18n: string
    ): Promise<capSQLiteChanges> {
        const sql = `
            INSERT INTO view_content (
                id,
                locator,
                owners,
                owner_departments,
                main_owner_job_type,
                created_at,
                status,
                related_patient_id,
                related_case_id,
                data_id,
                form,
                i18n
            ) VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?);
        `;

        const values = [
            id,
            locator,
            owners,
            owner_departments,
            main_owner_job_type,
            created_at,
            status,
            related_patient_id,
            related_case_id,
            data_id,
            form,
            i18n,
        ];

        return await this.db.run(sql, values);
    }

    public async getViewContentById(id: string): Promise<DBSQLiteValues> {
        const sql = `SELECT * FROM view_content WHERE id = ?;`;
        return await this.db.query(sql, [id]);
    }

    public async getViewContentByLocator(id: string): Promise<DBSQLiteValues> {
        const sql = `SELECT * FROM view_content WHERE locator = ?;`;
        return await this.db.query(sql, [id]);
    }

    public async getAllViewContentForCase(caseId: string): Promise<any> {
        const sql = `SELECT * FROM view_content WHERE related_case_id = ?;`;
        const res = await this.db.query(sql, [caseId]);
        return res;
    }

    public async updateViewContent(
        id: string,
        locator: string,
        owners: string,
        owner_departments: string,
        main_owner_job_type: string,
        created_at: string,
        status: string,
        related_patient_id: string,
        related_case_id: string,
        data_id: number,
        form: string,
        i18n: string
    ): Promise<capSQLiteChanges> {
        const sql = `
            UPDATE view_content
            SET
                locator = ?,
                owners = ?,
                owner_departments = ?,
                main_owner_job_type = ?,
                created_at = ?,
                status = ?,
                related_patient_id = ?,
                related_case_id = ?,
                data_id = ?,
                form = ?,
                i18n = ?
            WHERE id = ?;
        `;

        const values = [
            locator,
            owners,
            owner_departments,
            main_owner_job_type,
            created_at,
            status,
            related_patient_id,
            related_case_id,
            data_id,
            form,
            i18n,
            id,
        ];

        return await this.db.run(sql, values);
    }

    public async deleteViewContent(id: number): Promise<any> {
        const sql = `DELETE FROM view_content WHERE id = ?;`;
        await this.db.run(sql, [id]);
    }

    public async getAllViewContent(): Promise<any> {
        const sql = `SELECT * FROM view_content;`;
        return await this.db.query(sql, []);
    }

    public async createVcData(data: string): Promise<capSQLiteChanges> {
        const sql = `INSERT INTO view_content_data (data) VALUES (?);`;
        return await this.db.run(sql, [data]);
    }

    public async getVcDataForId(id: number): Promise<DBSQLiteValues> {
        const sql = `SELECT * FROM view_content_data WHERE id = ?;`;
        const values = [id];
        return await this.db.query(sql, values);
    }

    public async updateVcData(
        id: number,
        data: string
    ): Promise<capSQLiteChanges> {
        const sql = `UPDATE view_content_data (data) VALUES (?) WHERE id = ?;`;
        const values = [data, id];
        return await this.db.run(sql, values);
    }

    public async createViewContentHistory(
        view_content_id: string,
        view_content_data_id: number,
        datetime: string,
        editor: string
    ): Promise<capSQLiteChanges> {
        const sql = `INSERT INTO view_content_history (view_content_id, view_content_data_id, datetime, editor ) VALUES (?, ?, ?, ?);`;
        const values = [
            view_content_id,
            view_content_data_id,
            datetime,
            editor,
        ];
        return await this.db.run(sql, values);
    }

    public async getHistoryForViewContent(
        vcId: string
    ): Promise<DBSQLiteValues> {
        const sql = `
            SELECT view_content_history.*, view_content_data.data
            FROM view_content_history
            INNER JOIN view_content_data ON view_content_history.view_content_data_id = view_content_data.id
            WHERE view_content_history.view_content_id = ?;
        `;
        const values = [vcId];
        return await this.db.query(sql, values);
    }
    //#endregion

    //#region ViewContentData CRUD functions
    //#endregion

    //#region CP2_user
    /** Inserts one registry in the cp2_user table.
     * @param {boolean} safe (default = true) If true the clause "OR IGNORE" will be added to the SQL sentence, preventing an Error in case of collision
     */
    public async createCp2User(
        userId: string,
        surname: string,
        name: string,
        validSince: string,
        validUntil: string,
        safe = true
    ): Promise<capSQLiteChanges> {
        const sql = `
            INSERT ${safe ? 'OR IGNORE' : ''} INTO cp2_user (
                userId,
                surname,
                name,
                validSince,
                validUntil
            ) VALUES (?, ?, ?, ?, ?);
        `;
        const values = [userId, surname, name, validSince, validUntil];

        return await this.db.run(sql, values);
    }

    public async getCp2UserById(userId: string): Promise<DBSQLiteValues> {
        const sql = `SELECT * FROM cp2_user WHERE userId = ?;`;
        return await this.db.query(sql, [userId]);
    }

    public async getAllCp2Users(): Promise<DBSQLiteValues> {
        const sql = `SELECT * FROM cp2_user;`;
        return await this.db.query(sql, []);
    }

    public async updateCp2User(
        userId: string,
        surname: string,
        name: string,
        validSince: string,
        validUntil: string
    ): Promise<capSQLiteChanges> {
        const sql = `
            UPDATE cp2_user
            SET
                surname = ?,
                name = ?,
                validSince = ?,
                validUntil = ?
            WHERE userId = ?;
        `;

        const values = [surname, name, validSince, validUntil, userId];

        return await this.db.run(sql, values);
    }

    public async deleteCp2User(userId: string): Promise<capSQLiteChanges> {
        const sql = `DELETE FROM cp2_user WHERE userId = ?;`;
        return await this.db.run(sql, [userId]);
    }

    //#endregion
}
