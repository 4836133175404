import {Injectable, OnDestroy} from '@angular/core';
import {NgEventBus} from 'ng-event-bus';
import {Subscription} from 'rxjs';
import {DeviceFacadeService} from '../facades/device-facade.service';
import {Device} from '../../models/auth.model';
import {ServerSentEventService} from './server-sent-event.service';
import {LocatorsFacadeService} from '../facades/locators-facade.service';
import {Locator} from '../../models/view-content.model';
import {DataRepositoryService} from '../datarepository.service';
import {Cp2ApiService} from '../cp2-api.service';
import {ViewContentFacadeService} from '../facades/viewcontent-facade.service';
import {decodeJwt, JWTPayload} from 'jose';
import {v4 as uuidv4} from 'uuid';

@Injectable({
    providedIn: 'root',
})
export class ServerSentEventHandlerService implements OnDestroy {
    private currentAccessToken: string | null = null;
    private subscriptions: Subscription[] = [];
    private currentDevice: Device | null = null;

    constructor(
        private readonly apiService: Cp2ApiService,
        private readonly eventBus: NgEventBus,
        private readonly deviceFacade: DeviceFacadeService,
        private readonly sseService: ServerSentEventService,
        private readonly locatorsFacade: LocatorsFacadeService,
        private readonly viewContentFacade: ViewContentFacadeService,
        private readonly dataRepository: DataRepositoryService
    ) {
    }

    // this.eventBus.cast() is method to "open" a "channel" to received messages from SSE
    public initializeMercureTopicSubscriptions(accessToken: string): void {
        if (!this.isValidToken(accessToken)) {
            console.error('Invalid access token provided.');
            return;
        }

        this.currentAccessToken = accessToken;

        const deviceSubscription = this.deviceFacade.device$.subscribe(
            (deviceState) => {
                const deviceId = deviceState.device?.deviceId;
                if (deviceId) {
                    this.currentDevice = {...this.currentDevice, deviceId};

                    this.subscribeToServerSentEvents([`device/${deviceId}`], (eventData: any) => {
                        this.eventBus.cast('sse:' + deviceId, eventData);
                    });
                }
            },
            (error) => this.handleSubscriptionError(error, 'deviceSubscription')
        );

        this.subscriptions.push(deviceSubscription);

        const viewContentSubscription = this.deviceFacade.getDeviceId().subscribe(
            async (deviceId: string) => {
                if (deviceId) {
                    const eventBusSubscription = this.eventBus.on('sse:' + deviceId).subscribe(async (event) => {
                        if (this.currentAccessToken) {
                            await this.processViewContentEvent(event);
                        }
                    });

                    this.subscriptions.push(eventBusSubscription);
                }
            },
            (error) => this.handleSubscriptionError(error, 'viewContentSubscription')
        );

        this.subscriptions.push(viewContentSubscription);

        this.subscribeToServerSentEvents(['periodicPing'], (eventData: any) => {
            this.eventBus.cast('sse:periodicPing', eventData);
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
        this.clearSensitiveData();
    }

    private async processViewContentEvent(event: any): Promise<void> {
        try {
            const locatorString: string = event.data as string;
            const locator: Locator = {
                id: event.id ?? uuidv4(),
                datetime: new Date(event.timestamp).toISOString(),
                locator: locatorString,
            };

            const [allLocators, allViewContents] = await Promise.all([
                this.dataRepository.getLocators(),
                this.dataRepository.getViewContent(),
            ]);

            const updatedLocators = [...allLocators, locator];
            this.locatorsFacade.saveLocators(updatedLocators);

            const oldestLocator = this.findOldestLocator(updatedLocators);

            const viewContent = await this.apiService.getViewContentByLocator(
                oldestLocator.locator,
                this.currentAccessToken!
            );

            const updatedViewContents = [...allViewContents, viewContent];
            this.viewContentFacade.saveViewContent(updatedViewContents);

            const remainingLocators = updatedLocators.filter((loc) => loc.id !== oldestLocator.id);
            this.locatorsFacade.saveLocators(remainingLocators);
        } catch (error) {
            this.handleProcessingError(error, 'processViewContentEvent');
        }
    }

    private findOldestLocator(locators: Locator[]): Locator {
        return locators.reduce((oldest, current) =>
            new Date(current.datetime) < new Date(oldest.datetime) ? current : oldest
        );
    }

    private subscribeToServerSentEvents(topics: string[], onNext: (eventData: any) => void): void {
        topics.forEach((topic) => {
            const eventStream = this.sseService.getServerSentEvents(topic);
            const subscription = eventStream.subscribe({
                next: onNext,
                error: (error) => {
                    console.error(`Error receiving ${topic} event:`, error);
                },
            });
            this.subscriptions.push(subscription);
        });
    }

    private handleSubscriptionError(error: any, context: string): void {
        console.error(`Error in ${context}:`, error);
        // Log error or notify user
    }

    private handleProcessingError(error: any, context: string): void {
        console.error(`Error during processing in ${context}:`, error);
        // Retry or notify user
    }

    private clearSensitiveData(): void {
        this.currentAccessToken = null;
        this.currentDevice = null;
    }

    private isValidToken(token: string): boolean {
        if (!token) {
            return false;
        }

        const parts = token.split('.');
        if (parts.length !== 3) {
            return false;
        }

        try {
            const decoded = decodeJwt(token) as JWTPayload;

            if (decoded.exp && Date.now() >= decoded.exp * 1000) {
                return false;
            }
        } catch (error) {
            return false;
        }

        return true;
    }
}
