import {Routes} from "@angular/router";
import {ErrorComponent} from "./pages/base/error/error.component";
import {SettingsComponent} from "./pages/settings/settings.component";
import {OrganizationComponent} from "./pages/organization/organization.component";
import {WorkflowComponent} from "./pages/workflow/workflow.component";
import {TestSandboxComponent} from "./pages/base/test-sandbox/test-sandbox.component";
import {PatientDetailsComponent} from "./pages/patient-details/patient-details.component";
import {authGuard} from "./guards/auth.guard";
import {LoginCallbackComponent} from "./components/utility/login-callback/login-callback.component";
import {LoginPageComponent} from "./pages/base/login-page/login-page.component";
import {HomeComponent} from "./pages/home/home.component";

export const routes: Routes = [
    {
        path: "login",
        children: [
            {
                path: "",
                component: LoginPageComponent,
            },
            {
                path: "redirect",
                component: LoginCallbackComponent,
            },
        ],
    },
    {
        path: "",
        // canActivate: [authGuard],
        children: [
            {
                path: "",
                pathMatch: "full",
                redirectTo: "/start"
            },
            {
                path: "start",
                component: HomeComponent,
            },
            {path: "patient-details/:id", component: PatientDetailsComponent},
            {
                path: "patient-details/:id/:tabIndex",
                component: PatientDetailsComponent,
            }, {
                path: 'patient-details/:id/:tabIndex',
                component: PatientDetailsComponent,
            },
            {
                path: "admin",
                loadChildren: () =>
                    import("./pages/admin/admin.module").then(
                        (m) => m.AdminModule
                    ),
            },
            {
                path: "about",
                loadComponent: () =>
                    import("./pages/about/about.component").then(
                        (mod) => mod.AboutComponent
                    ),
            },
            {
                path: "test-sandbox",
                component: TestSandboxComponent,
            },
            {path: "settings", component: SettingsComponent},
            {
                path: "organization",
                component: OrganizationComponent,
            },
            {path: "workflow", component: WorkflowComponent},
            {
                path: "404",
                component: ErrorComponent,
            },
            {
                // This has to be at the bottom of the array, as it will capture any address that reaches it!
                path: "**",
                redirectTo: "404",
            },
        ],
    },
];
