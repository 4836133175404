import { distanceBetweenPoints } from "chart.js/helpers";
import { CHART_COLORS } from "../curve.component";
import { ScriptableContext } from "chart.js";

export default (ctx: ScriptableContext<'line'>, options: any) => {
    const x = options['x'] || 0;
    const y = ctx?.parsed?.y || 0;
    // @ts-ignore
    const systolic = ctx.raw ? Number(ctx.raw['systolic']) || 0 : 0;
    const diastolic = ctx.raw
        ? // @ts-ignore
          Number(ctx.raw['diastolic']) || 0
        : 0;
    const arrowSize = options['arrowSize'] || 5;
    const canvas = document.createElement('canvas');
    const canvasCTX = canvas.getContext('2d');

    const pixelY =
        ctx.chart.scales['y2'].getPixelForValue(systolic);
    const pixelY2 =
        ctx.chart.scales['y2'].getPixelForValue(diastolic);
    const distance = distanceBetweenPoints(
        { x, y: pixelY },
        { x, y: pixelY2 }
    );

    const width = 30;
    const canvasHeight = distance * 2 + arrowSize * 2;
    const translateY = distance / 2;
    const middle = width / 2;

    canvas.width = width;
    canvas.height = canvasHeight;

    if (canvasCTX) {

        canvasCTX.strokeStyle = CHART_COLORS.RR;
        canvasCTX.fillStyle = CHART_COLORS.RR;
        canvasCTX.lineWidth = 2;

        // Zeichne die vertikale Linie
        canvasCTX.translate(0, translateY + arrowSize);
        canvasCTX.beginPath();
        canvasCTX.moveTo(middle, translateY + arrowSize);
        canvasCTX.lineTo(middle, translateY * 3 - arrowSize);
        canvasCTX.stroke();

        // Zeichne den oberen Pfeil
        canvasCTX.translate(0, translateY);
        canvasCTX.beginPath();
        canvasCTX.moveTo(middle, arrowSize);
        canvasCTX.lineTo(middle - arrowSize, 0);
        canvasCTX.moveTo(middle, arrowSize);
        canvasCTX.lineTo(middle + arrowSize, 0);
        canvasCTX.stroke();

        // Zeichne den unteren Pfeil
        canvasCTX.beginPath();
        canvasCTX.moveTo(middle, distance - arrowSize);
        canvasCTX.lineTo(middle - arrowSize, distance);
        canvasCTX.moveTo(middle, distance - arrowSize);
        canvasCTX.lineTo(middle + arrowSize, distance);
        canvasCTX.stroke();
    }

    return canvas;
}
