import { FormioRendererI18n } from '../../components/data-interaction/formio-renderer/formio-renderer.component';
import {
    Case_Overview,
    getNewViewContent,
    Patient_Details,
    VC_Case,
    VC_Consultation,
    VC_CurveGroup,
    VC_Diagnose,
    VC_Diagnostic,
    VC_Discharge,
    VC_Medical_History,
    VC_Pathogen,
    VC_Post_Treatment,
    VC_Task,
    VC_Therapy,
    VC_Visit_Record,
    ViewContent,
} from '../../models/view-content.models/view-content.model';
import {
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    signal,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PaintingToolBridgeComponent } from '../../components/utility/painting-tool-bridge/painting-tool-bridge.component';
import { RecordsFacadeService } from '../../services/facades/records-facade.service';
import { AreaFacadeService } from '../../services/facades/areas-facade.service';
import { FullscreenDialogComponent } from '../../modals/fullscreen-dialog/fullscreen-dialog.component';
import {
    LS_PATIENT_RECORDS,
    LS_PATIENT_RECORDS_AREAS,
    LS_PATIENT_RECORDS_SUBAREAS,
    LS_PATIENT_SELECTED_RECORD,
} from '../../shared/constants';
import { Preferences } from '@capacitor/preferences';
import {
    Area,
    Findings,
    PaintingToolItem,
    Record,
    SubArea,
} from '../../models/patient-records.model';
import { PatientCurveComponent } from '../../components/patient-info/patient-curve-group/patient-curve-group.component';
import { CurveComponent } from '../../components/curve/curve.component';
import { DataRepositoryService } from '../../services/datarepository.service';
import {
    CurveGroup,
    getNewCurveGroup,
    getNewCurveRow,
} from '../../models/curve.model';
import { v4 as uuidv4 } from 'uuid';
import { PatientListItem } from '../../models/patient.model';
import { filter, firstValueFrom, Subscription } from 'rxjs';
import { CommonModule, Location } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatCardModule } from '@angular/material/card';
import { PatientOverviewComponent } from '../../components/patient-info/patient-overview/patient-overview.component';
import { PatientFilesComponent } from '../../components/patient-info/patient-files/patient-files.component';
import { CalAgePipe, DayAgoPipe } from '@lohmann-birkner/luic';
import { MatIconModule } from '@angular/material/icon';
import { WorkflowTabsComponent } from '../../components/tabs/workflow-tabs/workflow-tabs.component';
import { UpcommingPageComponent } from '../base/upcomming-page/upcomming-page.component';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatTabChangeEvent, MatTabsModule } from '@angular/material/tabs';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Cp2ApiService } from '../../services/cp2-api.service';
import { SettingsFacadeService } from '../../services/facades/settings-facade.service';
import dayjs from 'dayjs';
import { AccessFacadeService } from '../../services/facades/access-facade.service';
import { CP2_User } from '../../models/view-content.models/view-content-personal-domain.model';
import * as jose from 'jose';
import { UserToken } from '../../models/auth.model';
import { ViewContentFacadeService } from '../../services/facades/viewcontent-facade.service';
import { ToolboxService } from '../../services/toolbox.service';
import {
    Consultation,
    Diagnose,
    Diagnostic,
    Discharge,
    Medical_History,
    Pathogen,
    Post_Treatment,
    Task,
    Therapy,
} from '../../models/view-content.models/view-content-clinic-domain.model';
import {HttpResponse} from '@capacitor/core';

export interface PatientNameAndDob {
    firstName: string;
    lastName: string;
    dob: string;
    gender: string;
    ward: string;
    room: string;
    bed: string;
}

export interface PatientRecordsTemplate {
    templateLabel: string;
    templateId: string;
    templatePath: string;
}

@Component({
    selector: 'app-patient-details',
    templateUrl: './patient-details.component.html',
    styleUrls: ['./patient-details.component.scss'],
    standalone: true,
    imports: [
        CalAgePipe,
        CommonModule,
        CurveComponent,
        DayAgoPipe,
        MatCardModule,
        MatDialogModule,
        MatIconModule,
        MatTabsModule,
        PatientCurveComponent,
        PatientFilesComponent,
        PatientOverviewComponent,
        TranslateModule,
        UpcommingPageComponent,
        WorkflowTabsComponent,
    ],
    providers: [
        // Provide DialogRef token
        { provide: DialogRef, useValue: {} },
        { provide: DIALOG_DATA, useValue: {} },
    ],
})
export class PatientDetailsComponent implements OnInit, OnDestroy {
    public vcPatientDetails: Patient_Details | undefined;
    public vcCaseOverview: Case_Overview | undefined;
    public vc_CurveGroups: VC_CurveGroup[] = [];

    public currentTab: number | undefined;
    public selectedChip: string | undefined | null;

    public selectedRecord = {} as Record;
    public records: Record[] = [];
    public areas: Area[] = [];
    // TODO: SORGE DAFÜR DAS ES IM STATE MANAGER GESPEICHERT WIRD UND FRAGE PETER NACH WIE ES MIR RECORD AUSSIEHT
    public subAreas: SubArea[] = [];
    public findings: Findings[] = [];
    public isFullscreenForImageEnabled = signal({
        data: {},
        fullScreen: false,
    });
    public isMetaDataViewOpened = signal(false);
    public isPaintingToolOpened = signal({
        data: {},
        isPaintingToolDialogOpened: false,
    });

    //TODO: need to be deleted
    public patient: PatientListItem | undefined;
    // public patientDetails: PatientData | undefined;
    public viewContentI18n: FormioRendererI18n | undefined;
    public patientNameAndDob: PatientNameAndDob = {
        firstName: '',
        lastName: '',
        dob: '',
        gender: '',
        ward: '',
        room: '',
        bed: '',
    };
    public patientWorkflow: PatientListItem[] | undefined;
    public filterValue: string = '';
    private allSubs: Subscription[] = [];

    private caseId: string | undefined;
    private caseDetails: VC_Case | undefined;
    private user: CP2_User | undefined;
    private token: UserToken | undefined;
    private allLocators: string[] = [];

    public constructor(
        private api: Cp2ApiService,
        private accessFacade: AccessFacadeService,
        private areasFacade: AreaFacadeService,
        private dialog: MatDialog,
        private location: Location,
        private cdr: ChangeDetectorRef,
        private recordsFacade: RecordsFacadeService,
        private repo: DataRepositoryService,
        private route: ActivatedRoute,
        private settings: SettingsFacadeService,
        private vcFacade: ViewContentFacadeService,
        private toolbox: ToolboxService
    ) {
        this.settings.loadSettings();

        this.vcFacade.locatorsState$
            .pipe(
                filter((e) => {
                    if (this.caseId)
                        return e.viewContent.related_case_id == this.caseId;
                    return false;
                })
            )
            .subscribe((vc) => {
                this.processIncomingVc(vc.viewContent);
            });
    }

    /**
     * Initializes the component, fetches patient details, and calculates age.
     * @private
     */
    public async ngOnInit(): Promise<void> {
        this.caseId = this.route.snapshot.paramMap.get('id') ?? undefined;

        // Listen for changes in the route parameters
        const tabIndex = Number.parseInt(
            this.route.snapshot.paramMap.get('tabIndex') ?? '0'
        );
        this.changeTab(tabIndex);

        try {
            await this.initializePatientRecords();
        } catch (e) {
            console.error(e);
        }

        // Load this.user
        this.allSubs.push(
            this.accessFacade.userTokens$.subscribe(async (t) => {
                await this.toolbox.executeInLoading(async () => {
                    if (!t?.token) return;

                    const { access_token } = t.token;
                    const decodedToken = jose.decodeJwt(access_token);

                    this.user = {
                        userId: decodedToken['sub'] ?? '',
                        surname: decodedToken['family_name'] as string,
                        name: decodedToken['given_name'] as string,
                    };

                    this.token = t.token;

                    // Parallelisiere refresh() und loadCurveViewContent()
                    await this.refresh();
                    await this.loadCurveViewContent();

                    if (access_token) {
                        try {
                            const findingsResponse =
                                await this.api.getPatientFindings(access_token);
                            this.findings = findingsResponse.data ?? []; // Setze ein leeres Array als Fallback

                            const demoFinding =
                                this.findings[this.findings.length - 1];
                            this.findings.push({
                                ...demoFinding,
                                area: {
                                    ...demoFinding.area,
                                    name: 'Radiologie',
                                },
                                subArea: {
                                    ...demoFinding.subArea,
                                    name: 'MRT',
                                },
                                title: 'DICOM Demo',
                                documentType: 'dicom',
                            });
                        } catch (error) {
                            console.error(
                                'Error fetching patient findings:',
                                error
                            );
                        }
                    }
                });
            })
        );
    }

    /**
     * Cleans up resources on component destruction.
     */
    public ngOnDestroy(): void {
        this.allSubs.forEach((s) => s.unsubscribe());
    }

    //#region Listeners
    public async onCurveGroupsChange($event: VC_CurveGroup[]): Promise<void> {
        if (!this.caseId || !this.caseDetails?.related_patient_id || !this.user)
            throw Error(
                'Error saving curve group: no case_id or patient_id or user available'
            );

        if (this.token) {
            await this.toolbox.executeInLoading(async () => {
                try {
                    await Promise.all(
                        this.vc_CurveGroups.map(async (vcCurveGroup) => {
                            vcCurveGroup.form = undefined;
                            vcCurveGroup.i18n = undefined;
                            vcCurveGroup.owner_departments = [];
                            vcCurveGroup.owners = [];
                            try {
                                await this.api.putViewContent(
                                    vcCurveGroup,
                                    this.token!.access_token
                                );
                            } catch (e) {
                                console.error('PUT method failed', e);
                                await this.api.postViewContent(
                                    vcCurveGroup,
                                    this.token!.access_token
                                );
                            }
                        })
                    );
                } catch (error) {
                    console.error(
                        'An error occurred while processing VC_CurveGroups',
                        error
                    );
                }
            });
        }
        // await this.repo.createOrUpdateViewContent($event, this.user);
        this.refresh();
    }

    public async onOverviewDataChange(ev: any) {
        if (!this.caseId || !this.caseDetails?.related_patient_id || !this.user)
            throw Error(
                'Error saving curve group: no case_id or patient_id or user available'
            );
        const cid = this.caseDetails?.related_case_id;
        const pid = this.caseDetails?.related_patient_id;
        const i18n: FormioRendererI18n = {
            de: {
                Eingabe: 'Eingabe',
                Speichern: 'Speichern',
                Abbrechen: 'Abbrechen',
                'Field Set': 'Field Set',
            },
            en: {
                Eingabe: 'Input',
                Speichern: 'Save',
                Abbrechen: 'Cancel',
                'Field Set': 'Field Set',
            },
        };

        if (this.token) {
            const method: 'UPDATE' | 'ADD' = ev.method;
            switch (ev.type) {
                case 'task':
                    const task: Task = {
                        id: ev.data.find((item: any) => item.key === 'id')?.value ?? uuidv4(),
                        contractor: this.user,
                        details: ev.data.find((item: any) => item.key === 'details')?.value ?? '',
                        done: ev.data.find((item: any) => item.key === 'done')?.value ?? false,
                        editor: this.user,
                        goalDateOn: ev.data.find((item: any) => item.key === 'goalDateOn')?.value ?? '',
                        goal_date: ev.data.find((item: any) => item.key === 'goal_date')?.value ?? '',
                        priority: ev.data.find((item: any) => item.key === 'priority')?.value ?? 'normal',
                        task_name: ev.data.find((item: any) => item.key === 'task_name')?.value ?? ''
                    };

                    let vcTask: ViewContent<Task> = {} as ViewContent<Task>;
                    if (method === 'ADD') {
                        vcTask = getNewViewContent({
                            locator: `case.overview.tasks.${this.caseId}.${uuidv4()}`,
                            related_patient_id: pid,
                            related_case_id: cid,
                            data: task,
                            id: uuidv4(),
                            owners: [],
                            owner_departments: [],
                            main_owner_job_type: 'other',
                            created_at: dayjs().toISOString(),
                            status: 'not_final',
                            i18n
                        });

                        await this.api.postViewContent(vcTask, this.token.access_token);

                    } else if (method === 'UPDATE') {
                        vcTask = getNewViewContent({
                            locator: ev.vc.locator,
                            related_patient_id: ev.vc.related_patient_id,
                            related_case_id: ev.vc.related_case_id,
                            data: task,
                            id: ev.vc.id,
                            owners: ev.vc.owners,
                            owner_departments: ev.vc.owner_departments,
                            main_owner_job_type: ev.vc.main_owner_job_type,
                            created_at: ev.vc.created_at,
                            status: ev.vc.status,
                            i18n: ev.vc.i18n
                        });

                        await this.api.putViewContent(vcTask, this.token.access_token);
                    }

                    console.log(ev.vc);

                    break;

                case 'diagnostic':
                    const diagnostic: Diagnostic = {
                        title:
                            ev.data.find((item: any) => item.key === 'title')
                                ?.value ?? '',
                        details:
                            ev.data.find((item: any) => item.key === 'details')
                                ?.value ?? '',
                        task:
                            ev.data.find((item: any) => item.key === 'task')
                                ?.value ?? false,
                        teams:
                            ev.data.find((item: any) => item.key === 'teams')
                                ?.value ??
                            ({} as {
                                [key: string]: string | boolean;
                            }),
                        registered:
                            ev.data.find(
                                (item: any) => item.key === 'registered'
                            )?.value ?? false,
                        conducted:
                            ev.data.find(
                                (item: any) => item.key === 'conducted'
                            )?.value ?? false,
                        goalDateOn:
                            ev.data.find(
                                (item: any) => item.key === 'goalDateOn'
                            )?.value ?? '',
                        showDayCountForGoalDateAs:
                            ev.data.find(
                                (item: any) =>
                                    item.key === 'showDayCountForGoalDateAs'
                            )?.value ?? 'day0',
                        dayCountDeactivate:
                            ev.data.find(
                                (item: any) => item.key === 'dayCountDeactivate'
                            )?.value ?? '',
                        showJustInCurve:
                            ev.data.find(
                                (item: any) => item.key === 'showJustInCurve'
                            )?.value ?? false,
                    };

                    let vcDiagnostic: ViewContent<Diagnostic> = {} as ViewContent<Diagnostic>;
                    if (method === 'ADD') {
                        vcDiagnostic = getNewViewContent({
                            locator: `case.overview.diagnostic.${this.caseId}.${uuidv4()}`,
                            related_patient_id: pid,
                            related_case_id: cid,
                            data: diagnostic,
                            id: uuidv4(),
                            owners: [],
                            owner_departments: [],
                            main_owner_job_type: 'other',
                            created_at: dayjs().toISOString(),
                            status: 'not_final',
                            i18n
                        });
                        await this.api.postViewContent(vcDiagnostic, this.token.access_token);

                    } else if (method === 'UPDATE') {
                        vcDiagnostic = getNewViewContent({
                            locator: ev.vc.locator,
                            related_patient_id: ev.vc.related_patient_id,
                            related_case_id: ev.vc.related_case_id,
                            data: diagnostic,
                            id: ev.vc.id,
                            owners: ev.vc.owners,
                            owner_departments: ev.vc.owner_departments,
                            main_owner_job_type: ev.vc.main_owner_job_type,
                            created_at: ev.vc.created_at,
                            status: ev.vc.status,
                            i18n: ev.vc.i18n
                        });

                        console.log(ev.vc);

                        await this.api.putViewContent(vcDiagnostic, this.token.access_token);
                    }
                    break;

                case 'diagnoses':
                    const diagnose: Diagnose = {
                        created_at: new Date().toISOString(),
                        diagnose:
                            ev.data.find((item: any) => item.key === 'diagnose')
                                ?.value ?? '',
                    };

                    let vcDiagnose: ViewContent<Diagnose> = {} as ViewContent<Diagnose>;

                    if (method === 'ADD') {
                        vcDiagnose = getNewViewContent({
                            locator: `case.overview.diagnosis.${this.caseId}.${uuidv4()}`,
                            related_patient_id: pid,
                            related_case_id: cid,
                            data: diagnose,
                            id: uuidv4(),
                            owners: [],
                            owner_departments: [],
                            main_owner_job_type: 'other',
                            created_at: dayjs().toISOString(),
                            status: 'not_final',
                            i18n
                        });
                        await this.api.postViewContent(vcDiagnose, this.token.access_token);

                    } else if (method === 'UPDATE') {
                        vcDiagnose = getNewViewContent({
                            locator: ev.vc.locator,
                            related_patient_id: ev.vc.related_patient_id,
                            related_case_id: ev.vc.related_case_id,
                            data: diagnose,
                            id: ev.vc.id,
                            owners: ev.vc.owners,
                            owner_departments: ev.vc.owner_departments,
                            main_owner_job_type: ev.vc.main_owner_job_type,
                            created_at: ev.vc.created_at,
                            status: ev.vc.status,
                            i18n: ev.vc.i18n
                        });
                        await this.api.putViewContent(vcDiagnose, this.token.access_token);
                    }
                    break;

                case 'anamnesis':
                    const anamnesis: Medical_History = {
                        medical_history:
                            ev.data.find(
                                (item: any) => item.key === 'medical_history'
                            )?.value ?? '',
                        created_at: new Date().toISOString(),
                        date: new Date().toISOString(),
                    };
                    let vcAnamnesis: ViewContent<Medical_History> = {} as ViewContent<Medical_History>;

                    if (method === 'ADD') {
                        vcAnamnesis = getNewViewContent({
                            locator: `case.overview.anamnesis.${this.caseId}.${uuidv4()}`,
                            related_patient_id: pid,
                            related_case_id: cid,
                            data: anamnesis,
                            id: uuidv4(),
                            owners: [],
                            owner_departments: [],
                            main_owner_job_type: 'other',
                            created_at: dayjs().toISOString(),
                            status: 'not_final',
                            i18n
                        });
                        await this.api.postViewContent(vcAnamnesis, this.token.access_token);
                    } else if (method === 'UPDATE') {
                        vcAnamnesis = getNewViewContent({
                            locator: ev.vc.locator,
                            related_patient_id: ev.vc.related_patient_id,
                            related_case_id: ev.vc.related_case_id,
                            data: anamnesis,
                            id: ev.vc.id,
                            owners: ev.vc.owners,
                            owner_departments: ev.vc.owner_departments,
                            main_owner_job_type: ev.vc.main_owner_job_type,
                            created_at: ev.vc.created_at,
                            status: ev.vc.status,
                            i18n: ev.vc.i18n
                        });
                        await this.api.putViewContent(vcAnamnesis, this.token.access_token);
                    }

                    break;

                case 'therapy':
                    const therapy: Therapy = {
                        conducted:
                            ev.data.find(
                                (item: any) => item.key === 'conducted'
                            )?.value ?? false,
                        created_at:
                            ev.data.find(
                                (item: any) => item.key === 'created_at'
                            )?.value ?? new Date().toISOString(),
                        dayCountDeactivateOn:
                            ev.data.find(
                                (item: any) =>
                                    item.key === 'dayCountDeactivateOn'
                            )?.value ?? new Date().toISOString(),
                        details:
                            ev.data.find((item: any) => item.key === 'details')
                                ?.value ?? '',
                        editor: this.user,
                        goalDateOn:
                            ev.data.find(
                                (item: any) => item.key === 'goalDateOn'
                            )?.value ?? new Date().toISOString(),
                        registered:
                            ev.data.find(
                                (item: any) => item.key === 'registered'
                            )?.value ?? false,
                        showDayCountForGoalDateAs:
                            ev.data.find(
                                (item: any) =>
                                    item.key === 'showDayCountForGoalDateAs'
                            )?.value ?? 'day0',
                        showOnlyInCurve:
                            ev.data.find(
                                (item: any) => item.key === 'showOnlyInCurve'
                            )?.value ?? false,
                        task:
                            ev.data.find((item: any) => item.key === 'task')
                                ?.value ?? false,
                        title:
                            ev.data.find((item: any) => item.key === 'title')
                                ?.value ?? '',
                    };

                    let vcTherapy: ViewContent<Therapy> = {} as ViewContent<Therapy>;

                    if (method === 'ADD') {
                        vcTherapy = getNewViewContent({
                            locator: `case.overview.therapy.${this.caseId}.${uuidv4()}`,
                            related_patient_id: pid,
                            related_case_id: cid,
                            data: therapy,
                            id: uuidv4(),
                            owners: [],
                            owner_departments: [],
                            main_owner_job_type: 'other',
                            created_at: dayjs().toISOString(),
                            status: 'not_final',
                            i18n
                        });
                        await this.api.postViewContent(vcTherapy, this.token.access_token);

                    } else if (method === 'UPDATE') {
                        vcTherapy = getNewViewContent({
                            locator: ev.vc.locator,
                            related_patient_id: ev.vc.related_patient_id,
                            related_case_id: ev.vc.related_case_id,
                            data: therapy,
                            id: ev.vc.id,
                            owners: ev.vc.owners,
                            owner_departments: ev.vc.owner_departments,
                            main_owner_job_type: ev.vc.main_owner_job_type,
                            created_at: ev.vc.created_at,
                            status: ev.vc.status,
                            i18n: ev.vc.i18n
                        });
                        await this.api.putViewContent(vcTherapy, this.token.access_token);
                    }
                    break;

                case 'consultations':
                    const consultation: Consultation = {
                        title:
                            ev.data.find((item: any) => item.key === 'title')
                                ?.value ?? '',
                        details:
                            ev.data.find((item: any) => item.key === 'details')
                                ?.value ?? '',
                        task:
                            ev.data.find((item: any) => item.key === 'task')
                                ?.value ?? false,
                        editor: this.user,
                        registered:
                            ev.data.find(
                                (item: any) => item.key === 'registered'
                            )?.value ?? false,
                        performed:
                            ev.data.find(
                                (item: any) => item.key === 'performed'
                            )?.value ?? false,
                        goalDateOn:
                            ev.data.find(
                                (item: any) => item.key === 'goalDateOn'
                            )?.value ?? '',
                        goal_date:
                            ev.data.find(
                                (item: any) => item.key === 'goal_date'
                            )?.value ?? '',
                        dayCountForGoalDateShowAs:
                            ev.data.find(
                                (item: any) =>
                                    item.key === 'dayCountForGoalDateShowAs'
                            )?.value ?? '',
                        dayCountDeactivate:
                            ev.data.find(
                                (item: any) => item.key === 'dayCountDeactivate'
                            )?.value ?? '',
                        dayCountDeactivateOn:
                            ev.data.find(
                                (item: any) =>
                                    item.key === 'dayCountDeactivateOn'
                            )?.value ?? '',
                        showOnlyInCurve:
                            ev.data.find(
                                (item: any) => item.key === 'showOnlyInCurve'
                            )?.value ?? false,
                    };

                    let vcConsultation: ViewContent<Consultation> = {} as ViewContent<Consultation>;

                    if (method === 'ADD') {
                        vcConsultation = getNewViewContent({
                            locator: `case.overview.consultation.${this.caseId}.${uuidv4()}`,
                            related_patient_id: pid,
                            related_case_id: cid,
                            data: consultation,
                            id: uuidv4(),
                            owners: [],
                            owner_departments: [],
                            main_owner_job_type: 'other',
                            created_at: dayjs().toISOString(),
                            status: 'not_final',
                            i18n
                        });
                        await this.api.postViewContent(vcConsultation, this.token.access_token);

                    } else if (method === 'UPDATE') {
                        vcConsultation = getNewViewContent({
                            locator: ev.vc.locator,
                            related_patient_id: ev.vc.related_patient_id,
                            related_case_id: ev.vc.related_case_id,
                            data: consultation,
                            id: ev.vc.id,
                            owners: ev.vc.owners,
                            owner_departments: ev.vc.owner_departments,
                            main_owner_job_type: ev.vc.main_owner_job_type,
                            created_at: ev.vc.created_at,
                            status: ev.vc.status,
                            i18n: ev.vc.i18n
                        });
                        await this.api.putViewContent(vcConsultation, this.token.access_token);

                    }

                    break;
                case 'pathogen':
                    const pathogen: Pathogen = {
                        date:
                            ev.data.find((item: any) => item.key === 'date')
                                ?.value ?? '',
                        name:
                            ev.data.find((item: any) => item.key === 'name')
                                ?.value ?? '',
                        type:
                            ev.data.find((item: any) => item.key === 'type')
                                ?.value ?? '',
                    };
                    let vcPathogen: ViewContent<Pathogen> = {} as ViewContent<Pathogen>;
                    if (method === 'ADD') {
                        vcPathogen = getNewViewContent({
                            locator: `case.overview.pathogen.${this.caseId}.${uuidv4()}`,
                            related_patient_id: pid,
                            related_case_id: cid,
                            data: pathogen,
                            id: uuidv4(),
                            owners: [],
                            owner_departments: [],
                            main_owner_job_type: 'other',
                            created_at: dayjs().toISOString(),
                            status: 'not_final',
                            i18n
                        });
                        await this.api.postViewContent(vcPathogen, this.token.access_token);

                    } else if (method === 'UPDATE') {
                        vcPathogen = getNewViewContent({
                            locator: ev.vc.locator,
                            related_patient_id: ev.vc.related_patient_id,
                            related_case_id: ev.vc.related_case_id,
                            data: pathogen,
                            id: ev.vc.id,
                            owners: ev.vc.owners,
                            owner_departments: ev.vc.owner_departments,
                            main_owner_job_type: ev.vc.main_owner_job_type,
                            created_at: ev.vc.created_at,
                            status: ev.vc.status,
                            i18n: ev.vc.i18n
                        });
                        await this.api.putViewContent(vcPathogen, this.token.access_token);

                    }
                    break;

                case 'discharge':
                    const discharge: Discharge = {
                        comment:
                            ev.data.find((item: any) => item.key === 'comment')
                                ?.value ?? '',
                        dismissalExpectedOn:
                            ev.data.find(
                                (item: any) =>
                                    item.key === 'dismissalExpectedOn'
                            )?.value ?? '',
                        setInCheckpad:
                            ev.data.find(
                                (item: any) => item.key === 'setInCheckpad'
                            )?.value ?? false,
                    };

                    let vcDischarge: ViewContent<Discharge> = {} as ViewContent<Discharge>;

                    if (method == 'ADD') {
                        vcDischarge = getNewViewContent({
                            locator: `case.overview.discharge.${this.caseId}.${uuidv4()}`,
                            related_patient_id: pid,
                            related_case_id: cid,
                            data: discharge,
                            id: uuidv4(),
                            owners: [],
                            owner_departments: [],
                            main_owner_job_type: 'other',
                            created_at: dayjs().toISOString(),
                            status: 'not_final',
                            i18n
                        });
                        await this.api.postViewContent(vcDischarge, this.token.access_token);

                    } else if (method === 'UPDATE') {
                        vcDischarge = getNewViewContent({
                            locator: ev.vc.locator,
                            related_patient_id: ev.vc.related_patient_id,
                            related_case_id: ev.vc.related_case_id,
                            data: discharge,
                            id: ev.vc.id,
                            owners: ev.vc.owners,
                            owner_departments: ev.vc.owner_departments,
                            main_owner_job_type: ev.vc.main_owner_job_type,
                            created_at: ev.vc.created_at,
                            status: ev.vc.status,
                            i18n: ev.vc.i18n
                        });
                        await this.api.putViewContent(vcDischarge, this.token.access_token);

                    }
                    break;

                case 'postTreatment':
                    const postTreatment: Post_Treatment = {
                        postTreatment:
                            ev.data.find(
                                (item: any) => item.key === 'postTreatment'
                            )?.value ?? '',
                    };

                    let vcDPostTreatment: ViewContent<Post_Treatment> = {} as ViewContent<Post_Treatment>;

                    if (method === 'ADD') {
                        vcDPostTreatment = getNewViewContent({
                            locator: `case.overview.course.${this.caseId}.${uuidv4()}`,
                            related_patient_id: pid,
                            related_case_id: cid,
                            data: postTreatment,
                            id: uuidv4(),
                            owners: [],
                            owner_departments: [],
                            main_owner_job_type: 'other',
                            created_at: dayjs().toISOString(),
                            status: 'not_final',
                            i18n
                        });
                        await this.api.postViewContent(vcDPostTreatment, this.token.access_token);

                    } else if (method === 'UPDATE') {
                        vcDPostTreatment = getNewViewContent({
                            locator: ev.vc.locator,
                            related_patient_id: ev.vc.related_patient_id,
                            related_case_id: ev.vc.related_case_id,
                            data: postTreatment,
                            id: ev.vc.id,
                            owners: ev.vc.owners,
                            owner_departments: ev.vc.owner_departments,
                            main_owner_job_type: ev.vc.main_owner_job_type,
                            created_at: ev.vc.created_at,
                            status: ev.vc.status,
                            i18n: ev.vc.i18n
                        });
                        await this.api.putViewContent(vcDPostTreatment, this.token.access_token);
                    }
                    break;
            }
        }

        // await this.repo.createOrUpdateViewContent(ev, this.user);
        this.refresh();
    }

    public changeTab($event: MatTabChangeEvent | number): void {
        const newTabIndex =
            $event instanceof MatTabChangeEvent ? $event.index : $event;

        // Update URL to include new tab index
        const newPath = `/patient-details/${this.caseId}/${newTabIndex}`;
        this.location.replaceState(newPath);

        this.currentTab = newTabIndex;
    }

    public async onClickOnFullScreen(ev: { data: any; fullScreen: boolean }) {
        this.isFullscreenForImageEnabled.set(ev);

        const dialogRef = this.dialog.open(FullscreenDialogComponent, {
            data: ev,
            width: 'calc(100% - 2rem)',
            height: 'calc(100% - 2rem)',
        });

        const data = await firstValueFrom(dialogRef.afterClosed());
        this.isFullscreenForImageEnabled.set({ data: {}, fullScreen: false });
    }

    public async onPaintingToolOpenedChanged(ev: {
        data: any;
        isPaintingToolDialogOpened: boolean;
    }) {
        this.isPaintingToolOpened.set(ev);
        let img: PaintingToolItem;
        if (ev.data?.documentType === 'image') {
            img = {
                image: await this.convertToBase64(ev.data?.image),
                json_field: null,
            };
        } else {
            img = {
                blob: ev.data?.pdfBlob,
                json_field: null,
            };
        }
        if (img) {
            // Opens a dialog for adding a new patient file and prevents it from auto-refocusing
            const dialogRef = this.dialog.open(PaintingToolBridgeComponent, {
                restoreFocus: false,
                height: '856px',
                maxHeight: '100vh',
                maxWidth: '100%',
                width: '1464px',
                data: {
                    img,
                    patient: this.patient, //TODO: patient need to be replaced
                    case_id: this.caseId,
                    document_id: ev.data.documentId,
                },
            });
            this.allSubs.push(
                dialogRef.afterClosed().subscribe((result) => {
                    this.isPaintingToolOpened.set({
                        data: {},
                        isPaintingToolDialogOpened: false,
                    });
                })
            );
        }
    }

    public onMetaDataViewOpenedChanged(ev: boolean) {
        this.isMetaDataViewOpened.set(ev);
    }

    //#endregion

    private async initializePatientRecords() {
        // Check and initialize areas
        const storedAreas = JSON.parse(
            <string>(
                (await Preferences.get({ key: LS_PATIENT_RECORDS_AREAS })).value
            )
        );
        if (!storedAreas) {
            const areas = this.getDefaultAreas();
            await Preferences.set({
                key: LS_PATIENT_RECORDS_AREAS,
                value: JSON.stringify(areas),
            });
            // this.areas = areas;
        }

        // Check and initialize subAreas
        const storedSubAreas = JSON.parse(
            <string>(
                (await Preferences.get({ key: LS_PATIENT_RECORDS_SUBAREAS }))
                    .value
            )
        );
        if (!storedSubAreas) {
            const subAreas = this.getDefaultSubAreas();
            await Preferences.set({
                key: LS_PATIENT_RECORDS_SUBAREAS,
                value: JSON.stringify(subAreas),
            });
            // this.subAreas = subAreas;
        }

        // Check and initialize records
        const storedRecords = JSON.parse(
            <string>(await Preferences.get({ key: LS_PATIENT_RECORDS })).value
        );
        if (!storedRecords) {
            const records = this.getDefaultRecords();
            await Preferences.set({
                key: LS_PATIENT_RECORDS,
                value: JSON.stringify(records),
            });
            // this.records = records;
        }

        // Check and initialize selectedRecord
        const storedSelectedRecord = JSON.parse(
            <string>(
                (await Preferences.get({ key: LS_PATIENT_SELECTED_RECORD }))
                    .value
            )
        );
        if (!storedSelectedRecord) {
            const record = this.getDefaultRecords()[0];
            await Preferences.set({
                key: LS_PATIENT_SELECTED_RECORD,
                value: JSON.stringify(record),
            });
            // this.selectedRecord = record;
        }

        this.areasFacade.loadAreas();
        this.areasFacade.loadSubAreas();
        this.recordsFacade.loadSelectedRecord();
        this.recordsFacade.loadRecords();

        // Subscribe to updates from facades
        this.subscribeToFacades();
    }

    private blobToBase64(blob: Blob): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result as string);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    }

    private getDefaultAreas(): Area[] {
        return [
            {
                id: '95c9665d-ad11-4bf6-b25e-4b64c02196e7',
                recordId: 'c5725899-b20b-4aff-b349-8fe9f510d47d',
                version: '1.0',
                name: 'Labor',
                validFrom: '2023-01-01T00:00:00Z',
                validUntil: '2024-01-01T00:00:00Z',
                order: 1,
                subAreas: [
                    {
                        id: 'a2ccfc0a-233e-4758-9da8-d1a7bc636a2c',
                        areaId: '95c9665d-ad11-4bf6-b25e-4b64c02196e7',
                        version: '1.0',
                        name: 'Blutuntersuchung',
                        validFrom: '2023-01-01T00:00:00Z',
                        validUntil: '2024-01-01T00:00:00Z',
                        order: 1,
                    },
                ],
            },
            {
                id: '83482f1f-0c43-4d22-8e81-3ed6c19650c6',
                recordId: 'c5725899-b20b-4aff-b349-8fe9f510d47d',
                version: '1.0',
                name: 'Radiologie',
                validFrom: '2023-02-01T00:00:00Z',
                validUntil: '2024-02-01T00:00:00Z',
                order: 1,
                subAreas: [
                    {
                        id: '8ba4feae-f002-4f31-b06f-72f19dece33d',
                        areaId: '83482f1f-0c43-4d22-8e81-3ed6c19650c6',
                        version: '1.0',
                        name: 'MRT',
                        validFrom: '2023-02-01T00:00:00Z',
                        validUntil: '2024-02-01T00:00:00Z',
                        order: 1,
                    },
                    {
                        id: 'ef481ba4-9234-4fa8-b3bc-dc3ec3d7ba6c',
                        areaId: '83482f1f-0c43-4d22-8e81-3ed6c19650c6',
                        version: '1.0',
                        name: 'CT',
                        validFrom: '2023-02-01T00:00:00Z',
                        validUntil: '2024-02-01T00:00:00Z',
                        order: 1,
                    },
                ],
            },
            {
                id: '4201d743-e2a8-4aa5-8bb2-88a37c9da669',
                recordId: 'af27a2a9-81f4-44a7-b72f-58528949fc8d',
                version: '1.0',
                name: 'Kardiologie',
                validFrom: '2023-02-01T00:00:00Z',
                validUntil: '2024-02-01T00:00:00Z',
                order: 1,
                subAreas: [
                    {
                        id: '7bb37e0f-2ab1-4ddb-9422-e15e813326d3',
                        areaId: '4201d743-e2a8-4aa5-8bb2-88a37c9da669',
                        version: '1.0',
                        name: 'EKG',
                        validFrom: '2023-02-01T00:00:00Z',
                        validUntil: '2024-02-01T00:00:00Z',
                        order: 1,
                    },
                ],
            },
            {
                id: '97bb7da0-4729-40ed-8d6e-96fa330ba4f7',
                recordId: 'af27a2a9-81f4-44a7-b72f-58528949fc8d',
                version: '1.0',
                name: 'Allgemeinmedizin',
                validFrom: '2023-02-01T00:00:00Z',
                validUntil: '2024-02-01T00:00:00Z',
                order: 1,
                subAreas: [
                    {
                        id: 'ca76399e-42dd-401b-8405-9d9d4b5e20db',
                        areaId: '97bb7da0-4729-40ed-8d6e-96fa330ba4f7',
                        version: '1.0',
                        name: 'Vitalparameter',
                        validFrom: '2023-02-01T00:00:00Z',
                        validUntil: '2024-02-01T00:00:00Z',
                        order: 1,
                    },
                    {
                        id: 'fff73543-04d5-4921-aab6-5feae2a596ea',
                        areaId: '97bb7da0-4729-40ed-8d6e-96fa330ba4f7',
                        version: '1.0',
                        name: 'Anamnese',
                        validFrom: '2023-02-01T00:00:00Z',
                        validUntil: '2024-02-01T00:00:00Z',
                        order: 1,
                    },
                ],
            },
            {
                id: 'a19c3890-678f-43a3-813e-b1059536fa5d',
                recordId: 'c5725899-b20b-4aff-b349-8fe9f510d47d',
                version: '1.0',
                name: 'Test',
                validFrom: '2024-07-04T13:58:14.041Z',
                validUntil: '2024-07-04T13:58:14.041Z',
                order: 1,
                subAreas: [
                    {
                        id: '2742634c-fa4d-47ec-bdb6-37dc1034b92d',
                        areaId: 'a19c3890-678f-43a3-813e-b1059536fa5d',
                        version: '1.0',
                        name: 'Test1',
                        validFrom: '2024-07-04T14:01:21.044Z',
                        validUntil: '2024-07-04T14:01:21.044Z',
                        order: 1,
                    },
                ],
            },
        ];
    }

    private getDefaultSubAreas(): SubArea[] {
        return [
            {
                id: 'a2ccfc0a-233e-4758-9da8-d1a7bc636a2c',
                areaId: '95c9665d-ad11-4bf6-b25e-4b64c02196e7',
                version: '1.0',
                name: 'Blutuntersuchung',
                validFrom: '2023-01-01T00:00:00Z',
                validUntil: '2024-01-01T00:00:00Z',
                order: 1,
            },
            {
                id: '8ba4feae-f002-4f31-b06f-72f19dece33d',
                areaId: '83482f1f-0c43-4d22-8e81-3ed6c19650c6',
                version: '1.0',
                name: 'MRT',
                validFrom: '2023-02-01T00:00:00Z',
                validUntil: '2024-02-01T00:00:00Z',
                order: 1,
            },
            {
                id: 'ef481ba4-9234-4fa8-b3bc-dc3ec3d7ba6c',
                areaId: '83482f1f-0c43-4d22-8e81-3ed6c19650c6',
                version: '1.0',
                name: 'CT',
                validFrom: '2023-02-01T00:00:00Z',
                validUntil: '2024-02-01T00:00:00Z',
                order: 1,
            },
            {
                id: '7bb37e0f-2ab1-4ddb-9422-e15e813326d3',
                areaId: '4201d743-e2a8-4aa5-8bb2-88a37c9da669',
                version: '1.0',
                name: 'EKG',
                validFrom: '2023-02-01T00:00:00Z',
                validUntil: '2024-02-01T00:00:00Z',
                order: 1,
            },
            {
                id: 'ca76399e-42dd-401b-8405-9d9d4b5e20db',
                areaId: '97bb7da0-4729-40ed-8d6e-96fa330ba4f7',
                version: '1.0',
                name: 'Vitalparameter',
                validFrom: '2023-02-01T00:00:00Z',
                validUntil: '2024-02-01T00:00:00Z',
                order: 1,
            },
            {
                id: 'fff73543-04d5-4921-aab6-5feae2a596ea',
                areaId: '97bb7da0-4729-40ed-8d6e-96fa330ba4f7',
                version: '1.0',
                name: 'Anamnese',
                validFrom: '2023-02-01T00:00:00Z',
                validUntil: '2024-02-01T00:00:00Z',
                order: 1,
            },
            {
                id: '71fcd825-e2ab-4c7a-b3a1-615330a8ccf1',
                areaId: '1e690973-8328-45ac-991a-336ecad1d57a',
                version: '1.0',
                name: 'Sonstiges',
                validFrom: '2023-02-01T00:00:00Z',
                validUntil: '2024-02-01T00:00:00Z',
                order: 1,
            },
        ];
    }

    private getDefaultRecords(): Record[] {
        return [
            {
                id: 'c5725899-b20b-4aff-b349-8fe9f510d47d',
                clinicId: 'clinic-1234',
                name: 'Ambulant',
                version: '1.0',
                validFrom: new Date().toISOString(),
                validUntil: new Date().toISOString(),
            },
            {
                id: 'af27a2a9-81f4-44a7-b72f-58528949fc8d',
                clinicId: 'clinic-5678',
                name: 'Stationär',
                version: '1.0',
                validFrom: new Date().toISOString(),
                validUntil: new Date().toISOString(),
            },
        ];
    }

    private subscribeToFacades() {
        this.allSubs.push(
            this.areasFacade.areas$.subscribe((areas: Area[]) => {
                if (areas.length > 0) {
                    this.areas = areas;
                }
            })
        );

        this.allSubs.push(
            this.areasFacade.subAreas$.subscribe((subAreas: SubArea[]) => {
                if (subAreas.length > 0) {
                    this.subAreas = subAreas;
                }
            })
        );

        this.allSubs.push(
            this.recordsFacade.selectedRecord$.subscribe(
                (record: Record | null) => {
                    if (record) {
                        this.selectedRecord = record;
                    }
                }
            )
        );

        this.allSubs.push(
            this.recordsFacade.records$.subscribe((records: Record[]) => {
                if (records.length > 0) {
                    this.records = records;
                }
            })
        );
    }

    private async convertToBase64(filePath: string): Promise<any> {
        try {
            const response = await fetch(filePath);
            const blob = await response.blob();
            return await this.blobToBase64(blob);
        } catch (error) {
            console.error('Error:', error);
        }
    }

    private async refresh(): Promise<void> {
        if (!this.caseId)
            throw Error('Error refreshing view: no patientId found');
        if (!this.token)
            throw Error('Error refreshing view: no token available');

        this.allLocators = await this.api.getAllLocatorsForCaseId(
            this.caseId,
            this.token.access_token
        );

        this.caseDetails = await this.api.getVcCaseDetails(
            this.caseId,
            this.token.access_token
        );

        this.vcPatientDetails = (
            await this.api.getVcCaseListItem(
                this.caseId,
                this.token.access_token
            )
        ).patient_details;

        const contentTypes = [
            'anamnesis', 'diagnosis', 'therapy', 'course', 'diagnostic',
            'consultation', 'pathogen', 'tasks', 'discharge', 'drg'
        ];

        const allLocators = await this.api.getAllLocatorsForCaseId(this.caseId, this.token!.access_token);

        const contentData = await Promise.all(
            contentTypes.map(type => {
                const locators = allLocators?.filter(loc => loc?.includes(`case.overview.${type}.${this.caseId}`));

                if (locators && locators.length > 0) {
                    return Promise.all(
                        locators.map(locator =>
                            this.api.getViewContentByLocator(locator, this.token!.access_token, '-1', 'send_history=true')
                                .catch(() => null) // Fehler abfangen und mit null ersetzen
                        )
                    );
                }

                return Promise.resolve([null]);
            })
        );

        const getData = (responseArray: (HttpResponse | null)[]) => {
            return responseArray && responseArray.length > 0 ? responseArray[0]?.data : {};
        };

        const getAllData = (responseArray: (HttpResponse | null)[]) => {
            return responseArray?.map(response => response?.data).filter(data => data) ?? [];
        };

        const [
            anamnesis,
            diagnosis,
            therapy,
            course,
            diagnostic,
            consultation,
            pathogen,
            tasks,
            discharge,
            postTreatment
        ] = contentData.map(item => item || {data: null});

        this.vcCaseOverview = {
            case: this.vcPatientDetails.case,
            medical_history: getData(anamnesis) as VC_Medical_History || {} as VC_Medical_History,
            diagnose: getData(diagnosis) as VC_Diagnose || {} as VC_Diagnose,
            therapy: getAllData(therapy) as VC_Therapy[] || [] as VC_Therapy[],
            visit_record: {} as VC_Visit_Record,
            diagnostic: getAllData(diagnostic) as VC_Diagnostic[] || [] as VC_Diagnostic[],
            consultation: getAllData(consultation) as VC_Consultation[] || [] as VC_Consultation[],
            task: getAllData(tasks) as VC_Task[] || [] as VC_Task[],
            discharge: getAllData(discharge) as VC_Discharge[] || [] as VC_Discharge[],
            pathogen: getAllData(pathogen) as VC_Pathogen[] || [] as VC_Pathogen[],
            post_treatment: getAllData(course) as VC_Post_Treatment[] || [] as VC_Post_Treatment[]
        };

        this.patientNameAndDob = {
            firstName: this.vcPatientDetails.patient.data.name,
            lastName: this.vcPatientDetails.patient.data.surname,
            dob: this.vcPatientDetails.patient.data.birth_date,
            gender: this.vcPatientDetails.patient.data.gender,
            ward: this.vcPatientDetails.current_place.data.room?.service_unit
                .long_text,
            bed: this.vcPatientDetails.current_place.data.name,
            room: this.vcPatientDetails.current_place.data.room?.long_text,
        };

        await this.loadCurveViewContent();
    }

    private async loadCurveViewContent(): Promise<void> {
        if (!this.caseId)
            throw Error(
                'Error loading curve viewContents: this.vcCaseOverview.case unavailable'
            );
        if (!this.token)
            throw Error('Error loading curve viewContents: no token available');

        const curveLocators = this.allLocators.filter((e) =>
            e.startsWith('case.curve.group')
        );

        this.vc_CurveGroups = await Promise.all(
            curveLocators.map((e) =>
                this.api.getVcForLocator<CurveGroup>(
                    e,
                    this.token!.access_token
                )
            )
        );

        // Commented code: Get Curve VCs from SQLite
        // // Load all ViewContents for current case from DB
        // const allVc = await this.repo.getAllEmptyViewContentForCase(
        //     this.caseId
        // );

        // // Complete the Curve groups ViewContents
        // this.vc_CurveGroups = await Promise.all(
        //     allVc
        //         .filter((e) => e.locator.startsWith('case.curve.group'))
        //         .map((e) =>
        //             this.repo.getFullViewContentForLocator<CurveGroup>(
        //                 e.locator
        //             )
        //         )
        // );

        // If there is n o data, create and save it

        if (!this.vc_CurveGroups?.length) {
            this.vc_CurveGroups = this.getEmptyVcCurve();
            for (const vc of this.vc_CurveGroups) {
                if (this.user) await this.repo.createViewContent(vc, this.user);
            }
        } else {
            for (const vc of this.vc_CurveGroups) {
                vc.history = await this.repo.getVcHistory<CurveGroup>(vc.id);
            }
        }
    }

    private getEmptyVcCurve(): VC_CurveGroup[] {
        const cid = this.caseDetails?.related_case_id;
        const pid = this.caseDetails?.related_patient_id;

        if (!cid || !pid) {
            console.error('Error creating VC_CurveGroup');
            return [];
        }

        // Upper part
        const notesRow = getNewCurveRow(uuidv4(), 'Notizen');
        const appointmentsRow = getNewCurveRow(uuidv4(), 'Termine');
        const diagnosticRow = getNewCurveRow(uuidv4(), 'Diagnostik');
        const therapyRow = getNewCurveRow(uuidv4(), 'Therapie');
        const basisGroup = getNewCurveGroup(
            uuidv4(),
            'Basisinformationen',
            'upper',
            [notesRow, appointmentsRow, diagnosticRow, therapyRow]
        );

        // Middle part
        const heartRate = getNewCurveRow(uuidv4(), 'Herzfrequenz');
        const bloodPressure = getNewCurveRow(uuidv4(), 'Blutdruck');
        const temperature = getNewCurveRow(uuidv4(), 'Temperatur');
        const respiratoryRate = getNewCurveRow(uuidv4(), 'Atemfrequenz');
        const vitalSigns = getNewCurveGroup(
            'vital_signs-' + uuidv4(),
            'Vitalwerte',
            'middle',
            [heartRate, bloodPressure, temperature, respiratoryRate]
        );

        // Lower part
        const accessGroup = getNewCurveGroup(
            uuidv4(),
            'Zugänge/Dränage/Bilanz',
            'lower'
        );
        const medicationGroup = getNewCurveGroup(
            uuidv4(),
            'Medikation',
            'lower'
        );

        const i18n: FormioRendererI18n = {
            de: {
                Eingabe: 'Eingabe',
                Speichern: 'Speichern',
                Abbrechen: 'Abbrechen',
                'Field Set': 'Field Set',
            },
            en: {
                Eingabe: 'Input',
                Speichern: 'Save',
                Abbrechen: 'Cancel',
                'Field Set': 'Field Set',
            },
        };

        const basisVc = getNewViewContent({
            locator: 'case.curve.group.basisGroup-' + this.caseId,
            related_patient_id: pid,
            related_case_id: cid,
            data: basisGroup,
            id: uuidv4(),
            owners: [],
            owner_departments: [],
            main_owner_job_type: 'other',
            created_at: dayjs().toISOString(),
            status: 'not_final',
            i18n,
        });

        const vitalSignsVc = getNewViewContent({
            locator: 'case.curve.group.vitalSigns-' + this.caseId,
            related_patient_id: pid,
            related_case_id: cid,
            data: vitalSigns,
            id: uuidv4(),
            owners: [],
            owner_departments: [],
            main_owner_job_type: 'other',
            created_at: dayjs().toISOString(),
            status: 'not_final',
            i18n,
        });

        const medicationVc = getNewViewContent({
            locator: 'case.curve.group.medicationGroup-' + this.caseId,
            related_patient_id: pid,
            related_case_id: cid,
            data: medicationGroup,
            id: uuidv4(),
            owners: [],
            owner_departments: [],
            main_owner_job_type: 'other',
            created_at: dayjs().toISOString(),
            status: 'not_final',
            i18n,
        });

        const accessVc = getNewViewContent({
            locator: 'case.curve.group.accessGroup-' + this.caseId,
            related_patient_id: pid,
            related_case_id: cid,
            data: accessGroup,
            id: uuidv4(),
            owners: [],
            owner_departments: [],
            main_owner_job_type: 'other',
            created_at: dayjs().toISOString(),
            status: 'not_final',
            i18n,
        });

        return [basisVc, vitalSignsVc, accessVc, medicationVc];
    }

    private processIncomingVc<T>(vc: ViewContent<T>): void {
        const l = vc.locator;

        if (l.startsWith('case.curve.group')) {
            const index = this.vc_CurveGroups.findIndex((e) => e.locator == l);

            // @ts-ignore
            this.vc_CurveGroups.splice(index, 1, vc as VC_CurveGroup);
            this.vc_CurveGroups = JSON.parse(
                JSON.stringify(this.vc_CurveGroups)
            );
        } else if (l.startsWith('case.overview.tasks')) {
            if (this.vcCaseOverview) {
                const index = this.vcCaseOverview.task.findIndex(
                    (e) => e.locator == l
                );
                if (index !== -1) {
                    // @ts-ignore
                    this.vcCaseOverview.task.splice(index, 1, vc as VC_Task);
                }
                this.vcCaseOverview.task = JSON.parse(
                    JSON.stringify(this.vcCaseOverview.task)
                );
            }
        } else if (l.startsWith('case.overview.diagnostic')) {
            if (this.vcCaseOverview) {
                const index = this.vcCaseOverview.diagnostic.findIndex(
                    (e) => e.locator == l
                );
                console.log(this.vcCaseOverview.diagnostic);
                if (index !== -1) {
                    // @ts-ignore
                    this.vcCaseOverview.diagnostic.splice(
                        index,
                        1,
                        vc as VC_Diagnostic
                    );
                }
                this.vcCaseOverview.diagnostic = JSON.parse(
                    JSON.stringify(this.vcCaseOverview.diagnostic)
                );
            }
        } else if (l.startsWith('case.overview.anamnesis')) {
            if (this.vcCaseOverview) {
                // @ts-ignore
                this.vcCaseOverview.medical_history = vc as VC_Medical_History; // Anamnesis ist ein einzelnes Objekt
                this.vcCaseOverview.medical_history = JSON.parse(
                    JSON.stringify(this.vcCaseOverview.medical_history)
                );
            }
        } else if (l.startsWith('case.overview.diagnosis')) {
            if (this.vcCaseOverview) {
                // @ts-ignore
                this.vcCaseOverview.diagnose = vc as VC_Diagnose; // Diagnose ist ein einzelnes Objekt
                this.vcCaseOverview.diagnose = JSON.parse(
                    JSON.stringify(this.vcCaseOverview.diagnose)
                );
            }
        } else if (l.startsWith('case.overview.therapy')) {
            if (this.vcCaseOverview) {
                const index = this.vcCaseOverview.therapy.findIndex(
                    (e) => e.locator == l
                );
                console.log(this.vcCaseOverview.therapy);
                if (index !== -1) {
                    // @ts-ignore
                    this.vcCaseOverview.therapy.splice(
                        index,
                        1,
                        vc as VC_Therapy
                    );
                }
                this.vcCaseOverview.therapy = JSON.parse(
                    JSON.stringify(this.vcCaseOverview.therapy)
                );
            }
        } else if (l.startsWith('case.overview.consultation')) {
            if (this.vcCaseOverview) {
                const index = this.vcCaseOverview.consultation.findIndex(
                    (e) => e.locator == l
                );
                console.log(this.vcCaseOverview.consultation);
                if (index !== -1) {
                    // @ts-ignore
                    this.vcCaseOverview.consultation.splice(
                        index,
                        1,
                        vc as VC_Consultation
                    );
                }
                this.vcCaseOverview.consultation = JSON.parse(
                    JSON.stringify(this.vcCaseOverview.consultation)
                );
            }
        } else if (l.startsWith('case.overview.pathogen')) {
            if (this.vcCaseOverview) {
                const index = this.vcCaseOverview.pathogen.findIndex(
                    (e) => e.locator == l
                );
                console.log(this.vcCaseOverview.pathogen);
                if (index !== -1) {
                    // @ts-ignore
                    this.vcCaseOverview.pathogen.splice(
                        index,
                        1,
                        vc as VC_Pathogen
                    );
                }
                this.vcCaseOverview.pathogen = JSON.parse(
                    JSON.stringify(this.vcCaseOverview.pathogen)
                );
            }
        } else if (l.startsWith('case.overview.discharge')) {
            if (this.vcCaseOverview) {
                const index = this.vcCaseOverview.discharge.findIndex(
                    (e) => e.locator == l
                );
                console.log(this.vcCaseOverview.discharge);
                if (index !== -1) {
                    // @ts-ignore
                    this.vcCaseOverview.discharge.splice(
                        index,
                        1,
                        vc as VC_Discharge
                    );
                }
                this.vcCaseOverview.discharge = JSON.parse(
                    JSON.stringify(this.vcCaseOverview.discharge)
                );
            }
        } else if (l.startsWith('case.overview.course')) {
            if (this.vcCaseOverview) {
                const index = this.vcCaseOverview.post_treatment.findIndex(
                    (e) => e.locator == l
                );
                console.log(this.vcCaseOverview.post_treatment);
                if (index !== -1) {
                    // @ts-ignore
                    this.vcCaseOverview.post_treatment.splice(
                        index,
                        1,
                        vc as VC_Post_Treatment
                    );
                }
                this.vcCaseOverview.post_treatment = JSON.parse(
                    JSON.stringify(this.vcCaseOverview.post_treatment)
                );
            }
        }
    }
}
