import { AbstractControl, ValidatorFn } from '@angular/forms';

export function bloodPressureValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const systolic = control.get('bloodPressureSystolic');
    const diastolic = control.get('bloodPressureDiastolic');

    if (systolic?.value === null || diastolic?.value === null) {
        return null;
    }

    return systolic && diastolic && Number(systolic.value) <= Number(diastolic.value) ? { 'invalidBloodPressure': true } : null;
  };
}
