<div class="flex flex-col h-full w-full">
    <!-- #region Patient Details -->
    <div>
        <p *ngIf="vcPatientDetails" class="text-gray-600 font-extralight">
            <span *ngIf="vcPatientDetails?.case?.data?.department?.name">
                {{ vcPatientDetails?.case?.data?.department?.name }} >
            </span>
            {{ vcPatientDetails?.current_place?.data?.room?.service_unit?.short_text ?? "" }} > {{ "COMPONENT.PAGE_PATIENT_LIST.room" | translate }}
            {{ vcPatientDetails?.current_place?.data?.room?.long_text ?? "" }}
            {{ vcPatientDetails?.current_place?.data?.name ?? "" }}
        </p>

        <div *ngIf="vcPatientDetails" class="flex flex-col">
            <div class="flex flex-col">
                <p>
                    <span class="font-bold">
                        {{ vcPatientDetails?.patient?.data?.surname ?? "" }},
                        {{ vcPatientDetails?.patient?.data?.name ?? "" }},
                    </span>
                    <span>
                        {{ "GENERAL." + (vcPatientDetails?.patient?.data?.gender ?? "")| translate }},
                    </span>
                    <span>
                        {{ vcPatientDetails?.patient?.data?.birth_date| date : ("GENERAL.date_format" | translate) }}
                    </span>
                    <span>
                        ({{ vcPatientDetails?.patient?.data?.birth_date| calAge }}),
                    </span>
                    <span>
                        A:{{ vcPatientDetails?.case?.data?.admission_date| date : ("GENERAL.date_format" | translate) }},
                    </span>
                    <span>
                        {{ "COMPONENT.PAGE_PATIENT_LIST.planned_discharge_date"| translate }}:
                    </span>
                    <span>
                        {{ vcPatientDetails?.case?.data?.discharge_date| date : ("GENERAL.date_format" | translate) }}
                    </span>
                    <span>
                        ({{ vcPatientDetails?.case?.data?.discharge_date| dayAgo }})
                    </span>
                </p>
                <p class="font-extralight">
                    {{ vcPatientDetails?.last_diagnosis?.data?.diagnose ?? "" }}
                </p>
            </div>
        </div>
    </div>
    <!-- #endregion -->

    <!-- #region Tab Group -->
    <div class="flex-grow flex flex-col bg-white shadow-md overflow-hidden">
        <mat-tab-group
            (selectedTabChange)="changeTab($event)"
            [selectedIndex]="currentTab"
            animationDuration="0ms"
            class="h-full flex flex-col"
        >
            <!-- #region Overview Tab -->
            <mat-tab>
                <ng-template mat-tab-label>
                    <div class="flex gap-1 items-center">
                        <mat-icon *ngIf="currentTab === 0">done</mat-icon>
                        <span>{{ "COMPONENT.PATIENT_DETAILS.overview" | translate }}</span>
                    </div>
                </ng-template>
                <div class="h-full overflow-auto">
                    <app-patient-overview
                        (changedOverviewData)="onOverviewDataChange($event)"
                        *ngIf="currentTab === 0 && vcPatientDetails"
                        [caseOverview]="vcCaseOverview"
                        [patientDetailsI18n]="vcPatientDetails?.patient?.i18n"
                        [patientNameAndDob]="patientNameAndDob"
                    ></app-patient-overview>
                </div>
            </mat-tab>
            <!-- #endregion -->

            <!-- #region Curve Tab -->
            <mat-tab>
                <ng-template mat-tab-label>
                    <div class="flex gap-1 items-center">
                        <mat-icon *ngIf="currentTab === 1">done</mat-icon>
                        <span>{{ "COMPONENT.PATIENT_DETAILS.curve" | translate }}</span>
                    </div>
                </ng-template>

                <div class="h-full">
                    <app-patient-curve
                        (groupsChange)="onCurveGroupsChange($event)"
                        [patientNameAndDob]="patientNameAndDob"
                        [vcGroups]="vc_CurveGroups"
                    ></app-patient-curve>
                </div>
            </mat-tab>
            <!-- #endregion -->

            <!-- #region Record Tab -->
            <mat-tab>
                <ng-template mat-tab-label>
                    <div class="flex gap-1 items-center">
                        <mat-icon *ngIf="currentTab === 2">done</mat-icon>
                        <span>{{ "COMPONENT.PATIENT_DETAILS.file" | translate }}</span>
                    </div>
                </ng-template>
                <div class="h-full">
                    <patient-files
                        *ngIf="currentTab === 2 && findings.length > 0"
                        (clickOnFullScreen)="onClickOnFullScreen($event)"
                        (isMetaDataViewChanged)="onMetaDataViewOpenedChanged($event)"
                        (isPaintingToolOpenedChanged)="onPaintingToolOpenedChanged($event)"
                        [areas]="areas"
                        [findings]="findings"
                        [isFullscreenForImageEnabled]="isFullscreenForImageEnabled()"
                        [isMetaDataViewOpened]="isMetaDataViewOpened()"
                        [isPaintingToolOpened]="isPaintingToolOpened()"
                        [records]="records"
                        [selectedRecord]="selectedRecord"
                        [subAreas]="subAreas"
                    ></patient-files>
                </div>
            </mat-tab>
            <!-- #endregion -->

            <!-- #region Workflow Tab -->
            <mat-tab>
                <ng-template mat-tab-label>
                    <div class="flex gap-1 items-center">
                        <mat-icon *ngIf="currentTab === 3">done</mat-icon>
                        <span>{{ "COMPONENT.PATIENT_DETAILS.workflow" | translate }}</span>
                    </div>
                </ng-template>
                <div class="h-full">
                    <app-workflow-tabs
                        [filterValue]="filterValue"
                        [workflowDataPatients]="patientWorkflow!"
                    ></app-workflow-tabs>
                </div>
            </mat-tab>
            <!-- #endregion -->

            <!-- #region Communication Tab -->
            <mat-tab>
                <ng-template mat-tab-label>
                    <div class="flex gap-1 items-center">
                        <mat-icon *ngIf="currentTab === 4">done</mat-icon>
                        <span>{{ "COMPONENT.PATIENT_DETAILS.communication"| translate }}</span>
                    </div>
                </ng-template>
                <div class="h-full">
                    <app-upcomming-page></app-upcomming-page>
                </div>
            </mat-tab>
            <!-- #endregion -->
        </mat-tab-group>
    </div>
    <!-- #endregion -->
</div>
