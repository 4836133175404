import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import * as LocatorsSelectors from '../../store/selectors/locator.selector';
import * as LocatorsActions from '../../store/actions/locator.action';
import {AppState} from '../../store/appstate.model';
import {Locator} from "../../models/view-content.model";

@Injectable({
    providedIn: 'root',
})
export class LocatorsFacadeService {
    /**
     * get locators fearture from the store.
     * return @param locatorsState
     */
    public locatorsState$ = this.store.select(LocatorsSelectors.selectLocatorFeature);


    /**
     * get locators from the store.
     * return @param locators
     */
    public locators$ = this.store.select(
        LocatorsSelectors.selectLocators
    );

    public constructor(private store: Store<AppState>) {
    }

    /**
     * Loads the locators from persistent storage
     */
    public loadLocators(): void {
        this.store.dispatch(LocatorsActions.loadLocatorState());
    }

    /**
     * Dispatches an action to set a language into the store.
     */
    public saveLocators(locators: Locator[]): void {
        this.store.dispatch(LocatorsActions.setLocators({locators}));
    }
}
