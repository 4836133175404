import {SidebarButton} from './components/navigation/sidebar/sidebar.component';
import {UserToken} from './models/auth.model';
import {Component, Inject, NgZone, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {NavController, Platform} from '@ionic/angular';
import {AccessFacadeService} from './services/facades/access-facade.service';
import {Subscription} from 'rxjs';
import {AuthService} from './services/auth.service';
import {SettingsFacadeService} from './services/facades/settings-facade.service';
import {Settings} from './models/settings.model';
import {ThemeService} from './services/theme.service';
import {DOCUMENT} from '@angular/common';
import {decodeJwt} from 'jose';
import {App, URLOpenListenerEvent} from '@capacitor/app';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {NgEventBus} from "ng-event-bus";
import {DeviceFacadeService} from "./services/facades/device-facade.service";
import {LocatorsFacadeService} from "./services/facades/locators-facade.service";
import {ServerSentEventHandlerService} from "./services/mercure/server-sent-event-handler.service";
import {ViewContentFacadeService} from "./services/facades/viewcontent-facade.service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    private static readonly TAG = 'AppComponent';

    public accessToken$ = this.accessFacade.userTokens$;
    public accessToken: UserToken | null = null;
    public username: string = '';
    public showSidebar: boolean = false;
    public currentBreakpoint: string = '';
    public selectedColorSchema: string = '';
    public displayNameMap = new Map([
        [Breakpoints.HandsetPortrait, 'handsetPortrait'],
        [Breakpoints.HandsetLandscape, 'handsetLandscape'],
        [Breakpoints.Web, 'web'],
        [Breakpoints.Tablet, 'tablet'],
    ]);

    // Private properties
    private allSubs: Subscription[] = [];

    // Constructor
    public constructor(
        private authService: AuthService,
        private accessFacade: AccessFacadeService,
        private deviceF: DeviceFacadeService,
        private locatorsF: LocatorsFacadeService,
        private viewContentF: ViewContentFacadeService,
        private navController: NavController,
        private platform: Platform,
        private router: Router,
        private settings: SettingsFacadeService,
        private translate: TranslateService,
        private zone: NgZone,
        private themeService: ThemeService,
        private breakpointObserver: BreakpointObserver,
        private renderer: Renderer2,
        @Inject(DOCUMENT) private document: Document,
        private eventBus: NgEventBus,
        private SSEHService: ServerSentEventHandlerService
    ) {
        this.setupDeepLinks();
        this.setupSettings();

        this.allSubs.push(
            this.themeService.theme$.subscribe((theme) => {
                // Ensure the document body is available before modifying classList
                if (this.document?.body) {
                    const body = this.document.body;

                    if (theme === 'light') {
                        this.renderer.addClass(body, 'cp2-theme');
                        this.renderer.removeClass(body, 'cp2-dark-theme');
                    } else if (theme === 'dark') {
                        this.renderer.addClass(body, 'cp2-dark-theme');
                        this.renderer.removeClass(body, 'cp2-theme');
                    }
                } else {
                    console.error('Document body is not available.');
                }
            })
        );


        this.allSubs.push(
            this.breakpointObserver
                .observe([
                    Breakpoints.HandsetPortrait,
                    Breakpoints.HandsetLandscape,
                    Breakpoints.Web,
                    Breakpoints.Tablet,
                ])
                .subscribe((result) => {
                    for (const query of Object.keys(result.breakpoints)) {
                        if (result.breakpoints[query]) {
                            this.currentBreakpoint =
                                this.displayNameMap.get(query) ?? '';
                        }
                    }
                    this.showSidebar = !(this.currentBreakpoint === 'handsetPortrait' || this.currentBreakpoint === 'handsetLandscape');
                })
        );
    }

    public get currentUrl() {
        return this.router.url;
    }

    public async ngOnInit(): Promise<void> {
        this.allSubs.push(
            this.accessToken$.subscribe((t) => {
                if (t.token) {
                    this.username = decodeJwt(t.token.access_token)['name'] as string;
                    this.accessToken = t.token;
                    this.SSEHService.initializeMercureTopicSubscriptions(this.accessToken.access_token);
                }
            })
        );

        this.platform.ready().then(() => {
            this.accessFacade.loadData();
            this.deviceF.loadData();
            this.locatorsF.loadLocators();
            this.viewContentF.loadViewContent();
        });

        setInterval(async () => {
            if (this.accessToken?.refresh_token) {
                const refreshedToken = await this.authService.refreshAuth(this.accessToken.refresh_token);
                this.accessFacade.setAccessToken(refreshedToken);
            }
        }, 5 * 60 * 1000);


        this.allSubs.push(
            this.eventBus.on('sse:periodicPing').subscribe((event) => {
                // console.log(event.data);
            })
        );
    }

    public ngOnDestroy(): void {
        this.allSubs.forEach((s) => s.unsubscribe());
    }

    public onClickShowSidebar() {
        this.showSidebar = !this.showSidebar;
    }

    public async handleNavigation(link: string): Promise<void> {
        await this.router.navigateByUrl(link);
    }

    public async handleSidebarButton(sidebarButton: SidebarButton): Promise<void> {
        switch (sidebarButton.name) {
            case 'logout':
                this.accessFacade.removeUserToken();
                if (window.confirm('Are you sure you want to logout?')) {
                    await this.navController.navigateRoot('/login');
                }
                break;
            case 'settings':
                await this.navController.navigateRoot('/settings');
                break;
            case 'admin':
                await this.navController.navigateRoot('/admin');
                break;
            case 'refresh_access':
                if (this.accessToken?.refresh_token) {
                    const at = await this.authService.refreshAuth(this.accessToken.refresh_token);
                    this.accessFacade.setAccessToken(at);
                }
                break;
            case 'back':
                this.navController.back();
                break;
            default:
                console.log(AppComponent.TAG, 'sidebar button clicked: ', sidebarButton);
        }
    }

    // Private methods
    private setupDeepLinks(): void {
        App.addListener('appUrlOpen', async (event: URLOpenListenerEvent) => {
            await this.zone.run(async () => {
                const slug = event.url.split('app.cpad2.de').pop();
                if (slug && slug.includes('/login/redirect?')) {
                    await this.authService.initializeLoginCallback(window.location.origin + slug);
                }
            });
        });
    }

    private setupSettings() {
        this.settings.loadSettings();
        this.allSubs.push(
            this.settings.settings$.subscribe((s: Settings) => {
                this.translate.use(s.language);
                this.selectedColorSchema = s.colorScheme;
                this.getColorScheme(this.selectedColorSchema);
            })
        );
    }

    private getColorScheme(colorScheme: string) {
        if (colorScheme === 'rostock') {
            this.renderer.removeClass(this.document.body, 'cp2-theme');
            this.renderer.addClass(this.document.body, 'cp2-rostock-theme');
        } else {
            this.renderer.addClass(this.document.body, 'cp2-theme');
            this.renderer.removeClass(this.document.body, 'cp2-rostock-theme');
        }
    }
}

