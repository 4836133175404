import { OrgaPatient } from './../../models/patient.model';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { OrgaPatientData, PatientListItem } from '../../models/patient.model';
import { Cp2ApiService } from '../../services/cp2-api.service';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { WorkflowTabsComponent } from '../../components/tabs/workflow-tabs/workflow-tabs.component';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { OrgaTabsComponent } from '../../components/tabs/orga-tabs/orga-tabs.component';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { FormioRendererI18n } from '../../components/data-interaction/formio-renderer/formio-renderer.component';
import {
    SortSelectComponent,
    SortSelection,
} from '../../components/utility/sort-select/sort-select.component';
import { SortDirection } from '@angular/material/sort';
import {
    Patient_Details,
    VC_Task,
    VC_Visit_Record,
    VcPatientListItem,
} from '../../models/view-content.models/view-content.model';
import { MatTable } from '@angular/material/table';

//sort the patients according to the service unit name
interface ServiceUnitGroup {
    serviceUnitName: string;
    patients: VcPatientListItem[];
}

@Component({
    selector: 'app-organization',
    templateUrl: './organization.component.html',
    styleUrls: ['./organization.component.scss'],
    standalone: true,
    imports: [
        MatCardModule,
        CommonModule,
        MatTabsModule,
        TranslateModule,
        WorkflowTabsComponent,
        FormsModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatButtonModule,
        OrgaTabsComponent,
        MatSelectModule,
        SortSelectComponent,
    ],
})
export class OrganizationComponent implements OnInit {
    public OrgaPatientData: VcPatientListItem[] = [];
    public patients: VcPatientListItem[] = [];
    public orgaPatients: ServiceUnitGroup[] | undefined;
    public searchQuery: string = '';
    public sortKey: string = 'lastName';
    public sortOrder: SortDirection = 'asc';
    public filteredOrgaPatients: VcPatientListItem[] = [];
    public filteredOrgaPatientListItem: ServiceUnitGroup[] = [];
    public viewContentI18n: FormioRendererI18n | undefined;
    @ViewChild(MatTable) table: MatTable<any> | undefined;

    public sortOptions = [
        { value: 'lastName', translateKey: 'COMPONENT.PAGE_WORKFLOW.lastName' },
        {
            value: 'admissionDate',
            translateKey: 'COMPONENT.PAGE_WORKFLOW.admissionDate',
        },
    ];

    selectedTabIndex: number = 0;

    public constructor(private api: Cp2ApiService) {}

    async ngOnInit() {
        // this.OrgaPatientData = this.api
        //     .getOrgaPatients()
        //     .data.orgaData.map((item: any) => {
        //         return {
        //             ...item,
        //             patients: this.sortList(item.patients),
        //         };
        //     });
        // this.filteredOrgaPatientListItem = this.OrgaPatientData;
        // this.filteredOrgaPatients = this.OrgaPatientData.flatMap(
        //     (item) => item.patients
        // );

        await this.refresh();
        this.orgaPatients = this.transformDataToServiceUnitGroups(
            this.patients
        );
        console.log(this.orgaPatients);

        this.viewContentI18n = this.api.getOrgaPatients().i18n;
    }
    private async refresh(): Promise<void> {
        this.patients = await this.api.getVcPatientListItem();
        this.searchSortAndPaginate();
    }
    // (await this.api.getVcPatientListItem()).map((el) => {
    //     return (this.viewContentI18nVisiteRecord = el.visit_record.i18n);
    // });}

    /**transform the data by sorting the service unit name */
    public transformDataToServiceUnitGroups(
        data: VcPatientListItem[]
    ): ServiceUnitGroup[] {
        const serviceUnitMap: { [key: string]: ServiceUnitGroup } = {};

        data.forEach((item) => {
            const serviceUnitName =
                item.patient_details.current_place.data.room.serviceUnit.name;

            if (!serviceUnitMap[serviceUnitName]) {
                serviceUnitMap[serviceUnitName] = {
                    serviceUnitName,
                    patients: [],
                };
            }

            serviceUnitMap[serviceUnitName].patients.push({
                patient_details: item.patient_details,
                visit_record: item.visit_record,
                tasks: item.tasks,
            });
        });

        // Convert the map to an array of ServiceUnitGroups
        return Object.values(serviceUnitMap);
    }

    public onSortChange(direction: SortSelection): void {
        this.sortKey = direction.value;
        this.sortOrder = direction.sortOrder;
        this.searchSortAndPaginate();
    }
    public onSearchFieldInput(): void {
        this.searchSortAndPaginate();
    }
    public updateFilteredPatients() {
        // Flatten the list of patients from all service unit groups and filter them
        const allPatients = this.filteredOrgaPatientListItem.flatMap(
            (group) => group.patients
        );
        const filteredPatients = this.getFilteredPatients(allPatients);

        // Map over the original groups and filter patients within each group based on the search query
        this.filteredOrgaPatientListItem = this.filteredOrgaPatientListItem.map(
            (group) => ({
                ...group,
                patients: this.getFilteredPatients(group.patients),
            })
        );
    }

    //#endregion

    private sortList(patients: VcPatientListItem[]): VcPatientListItem[] {
        return patients?.sort((a, b) => {
            let compare = 0;

            switch (this.sortKey) {
                case 'lastName':
                    compare =
                        a.patient_details.patient.data.surname.localeCompare(
                            b.patient_details.patient.data.surname
                        ) ||
                        a.patient_details.patient.data.name.localeCompare(
                            b.patient_details.patient.data.name
                        );
                    break;
                case 'admissionDate':
                    compare =
                        new Date(
                            a.patient_details.case.data.admission_date
                        ).getTime() -
                        new Date(
                            b.patient_details.case.data.admission_date
                        ).getTime();
                    break;

                default:
                    break;
            }

            return this.sortOrder === 'asc' ? compare : -compare;
        });
    }


    /* area for search */
    getFilteredPatients(
        patients: {
            patient_details: Patient_Details;
            visit_record: VC_Visit_Record;
            tasks: VC_Task[];
        }[]
    ): {
        patient_details: Patient_Details;
        visit_record: VC_Visit_Record;
        tasks: VC_Task[];
    }[] {
        if (!this.searchQuery.trim()) return patients;

        return patients.filter((patient) =>
            this.matchesPatient(patient, this.searchQuery.toLowerCase())
        );
    }

    //#region search area
    private matchesPatient(patient: VcPatientListItem, query: string): boolean {
        // Convert the query to lowercase for case-insensitive comparison
        const lowerCaseQuery = query.toLowerCase();

        // Check simple string properties
        const matchesSimpleFields = [
            patient.patient_details.patient.data.surname,
            patient.patient_details.current_place.data.shortName,
            patient.patient_details.case.data.admission_date,
        ].some((field) => field.toLowerCase().includes(lowerCaseQuery));

        // Check array of strings (diagnosis)
        const matchesDiagnosis =
            patient.patient_details.last_diagnose.data.diagnose
                .toLowerCase()
                .includes(lowerCaseQuery);

        // Check nested Tasks array
        const matchesTasks = patient.tasks.some(
            (task) =>
                task.data.task_name.toLowerCase().includes(lowerCaseQuery) ||
                task.data.details.toLowerCase().includes(lowerCaseQuery) ||
                task.data.priority.toLowerCase().includes(lowerCaseQuery) ||
                task.data.editor.surname
                    .toLowerCase()
                    .includes(lowerCaseQuery) ||
                task.data.editor.name.toLowerCase().includes(lowerCaseQuery) ||
                task.data.goalDateOn.toLowerCase().includes(lowerCaseQuery) ||
                task.data.contractor.surname
                    .toLowerCase()
                    .includes(lowerCaseQuery) ||
                task.data.contractor.name.toLowerCase().includes(lowerCaseQuery)
        );

        // Check nested Visits array
        const matchesVisits = patient.visit_record.history?.some(
            (visitRecord) =>
                visitRecord.data.note.toLowerCase().includes(lowerCaseQuery)
        );

        // Check Discharge information (if applicable)
        const matchesDischarge =
            patient.patient_details.case.data.discharge_date
                ?.toLowerCase()
                .includes(lowerCaseQuery) ||
            patient.patient_details.case.data.planned_discharge_date
                ?.toLowerCase()
                .includes(lowerCaseQuery) ||
            false;

        // Return true if any of the above conditions match
        return (
            matchesSimpleFields ||
            matchesDiagnosis ||
            matchesTasks ||
            matchesVisits ||
            matchesDischarge
        );
    }

    //#endregion

    private searchSortAndPaginate(): void {
        this.filteredOrgaPatients = !this.searchQuery.trim()
            ? this.patients
            : this.patients.filter((patient) =>
                  this.matchesPatient(patient, this.searchQuery.toLowerCase())
              );

        // Rebuild the orgaPatients structure
        this.orgaPatients = this.transformDataToServiceUnitGroups(
            this.filteredOrgaPatients
        );

        // Sort the patients within the currently selected tab
        if (this.selectedTabIndex !== -1 && this.orgaPatients) {
            const selectedServiceUnitGroup =
                this.orgaPatients[this.selectedTabIndex];
            selectedServiceUnitGroup.patients = this.sortList(
                selectedServiceUnitGroup.patients
            );
        }

        console.log(this.orgaPatients);
        this.table?.renderRows(); //trigger the update of the table
    }
}
