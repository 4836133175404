import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {from, of} from 'rxjs';
import {catchError, map, switchMap, withLatestFrom} from 'rxjs/operators';
import * as ViewContentActions from '../actions/viewcontent.action';
import {DataRepositoryService} from '../../services/datarepository.service';
import {AppState} from '../appstate.model';
import {Store} from '@ngrx/store';

/**
 * Effects for handling authorization tokens related operations.
 */
@Injectable()
export class ViewContentEffects {
    /** Effect for loading settings. */
    public loadViewContent$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ViewContentActions.loadViewContentState),
            switchMap(() => {
                // TODO Finish me!
                return from(this.repo.getViewContent()).pipe(
                    map((viewContent) => {
                        return ViewContentActions.loadViewContentSuccess({viewContent});
                    })
                );
            }),
            catchError((error) => {
                return of(ViewContentActions.loadViewContentFail({error}));
            })
        )
    );
    /** Effect for adding or updating settings. */
    saveViewContent$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ViewContentActions.setViewContent),
            withLatestFrom(this.store.select((state) => state.viewContent.viewContent)),
            switchMap(([action, viewContent]) => {
                // TODO Finish me!
                this.repo.setViewContent(viewContent).then();
                return of(
                    ViewContentActions.setViewContentSuccess({viewContent: action.viewContent})
                );
            }),
            catchError((error) => {
                return of(ViewContentActions.setViewContentFail({error}));
            })
        )
    );

    public constructor(
        private actions$: Actions,
        private repo: DataRepositoryService,
        private store: Store<AppState>
    ) {
    }
}
