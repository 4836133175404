import {FormioRendererI18n} from '../../components/data-interaction/formio-renderer/formio-renderer.component';
import {CurveGroup} from '../curve.model';
import {FormioForm} from '../formio.model';
import {
    Consultation,
    Diagnose,
    Diagnostic,
    Discharge,
    LabReport,
    Medical_History as Medical_History,
    Pathogen,
    Post_Treatment,
    Task,
    Therapy,
    Visit_Record
} from './view-content-clinic-domain.model';
import {Place} from './view-content-organization-domain.model';
import {Case, Patient} from './view-content-patient-domain.model';
import {CP2_User} from './view-content-personal-domain.model';
import {v4 as uuidv4} from 'uuid';

export interface Traceable {
    modifiedAt: string;
    modifiedBy: CP2_User;
}

export interface VcHistoryElement<T> extends Traceable {
    data: T;
}

export interface ViewContent<T> {
    id: string;
    locator: string;
    owners: string[];
    owner_departments: string[];
    main_owner_job_type: 'doctor' | 'nurse' | 'admin' | 'other';
    created_at: string;
    status: 'final' | 'not_final';
    related_patient_id: string;
    related_case_id: string;
    data: T;
    form?: FormioForm;
    i18n?: FormioRendererI18n;
    history?: VcHistoryElement<T>[];
}

/** Generates a new dummy view content */
export const getNewViewContentOld = <T>(
    locator: string,
    related_patient_id: string,
    related_case_id: string,
    data: T,
    id = uuidv4(),
    owners: string[] = [],
    owner_departments: string[] = [],
    main_owner_job_type: 'doctor' | 'nurse' | 'admin' | 'other' = 'other',
    created_at: string = new Date().toISOString(),
    status: 'final' | 'not_final' = 'not_final',
    i18n?: FormioRendererI18n
): ViewContent<T> => ({
    id,
    locator,
    owners,
    owner_departments,
    main_owner_job_type,
    created_at,
    status,
    related_patient_id,
    related_case_id,
    data,
    i18n
});

export const getNewViewContent = <T>({
                                         locator,
                                         related_patient_id,
                                         related_case_id,
                                         data,
                                         id = uuidv4(),
                                         owners = [],
                                         owner_departments = [],
                                         main_owner_job_type = 'other',
                                         created_at = new Date().toISOString(),
                                         status = 'not_final',
                                         i18n
                                     }: {
    locator: string;
    related_patient_id: string;
    related_case_id: string;
    data: T;
    id?: string;
    owners?: string[];
    owner_departments?: string[];
    main_owner_job_type?: 'doctor' | 'nurse' | 'admin' | 'other';
    created_at?: string;
    status?: 'final' | 'not_final';
    i18n?: FormioRendererI18n;
}): ViewContent<T> => ({
    id,
    locator,
    owners,
    owner_departments,
    main_owner_job_type,
    created_at,
    status,
    related_patient_id,
    related_case_id,
    data,
    i18n
});


export interface Patient_Details {
    case: ViewContent<Case>;
    patient: ViewContent<Patient>;
    current_place: ViewContent<Place>;
    last_diagnosis?: ViewContent<Diagnose>;
}

export interface VC_Case extends ViewContent<Case> {
}

export interface VC_Medical_History extends ViewContent<Medical_History> {
}

export interface VC_Diagnose extends ViewContent<Diagnose> {
}

export interface VC_Therapy extends ViewContent<Therapy> {
}

export interface VC_Visit_Record extends ViewContent<Visit_Record> {
}

export interface VC_Diagnostic extends ViewContent<Diagnostic> {
}

export interface VC_Consultation extends ViewContent<Consultation> {
}

export interface VC_Task extends ViewContent<Task> {
}

export interface VC_Discharge extends ViewContent<Discharge> {
}

export interface VC_Post_Treatment extends ViewContent<Post_Treatment> {
}

export interface VC_Pathogen extends ViewContent<Pathogen> {
}

export interface Case_Overview {
    case: VC_Case;
    medical_history: VC_Medical_History;
    diagnose: VC_Diagnose;
    therapy: VC_Therapy[];
    visit_record: VC_Visit_Record;
    diagnostic: VC_Diagnostic[];
    consultation: VC_Consultation[];
    task: VC_Task[];
    discharge: VC_Discharge[];
    pathogen: VC_Pathogen[];
    post_treatment: VC_Post_Treatment[];
}

export interface VcPatientListItem {
    patient_details: Patient_Details;
    visit_record: VC_Visit_Record;
    tasks: VC_Task[];
}

/** In few words, a PatientList is an array of PatientListGroups */
export interface PatientList extends Array<PatientListGroup> {
}

export interface PatientListGroup {
    listIdentifier: {
        id: string;
        code: string;
        name: string;
        listType: string;
    };
    patients: VcPatientListItem[];
}

export interface VC_CurveGroup extends ViewContent<CurveGroup> {
}

export interface VC_LabReport extends ViewContent<LabReport> {
}
