import { NetworkService } from './../../../services/mercure/network.service';
import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { AsyncPipe, CommonModule, Location } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import {
    NavigationEnd,
    NavigationError,
    Router,
    RouterModule,
} from '@angular/router';
import { LoginPageComponent } from '../../../pages/base/login-page/login-page.component';
import { TranslateModule } from '@ngx-translate/core';
import { LuicModule } from '@lohmann-birkner/luic';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { Preferences } from '@capacitor/preferences';
import {
    LS_CURRENT_POSITION,
    LS_LAST_SAVED_DATE,
    LS_NAVIGATION_HISTORY,
} from '../../../shared/constants';
import { NetworkStatusComponent } from '../../utility/network-status/network-status.component';
import { NetworkQualityComponent } from '../../network-quality/network-quality.component';
import { firstValueFrom, Subscription } from 'rxjs';

/**
 * Interface representing a menu item on the top.
 */
export interface NavigationButton {
    label: string;
    handyLabel: string;
    link: string;
    activeOnRoutes: string[];
    abbreviation: string;
}

/**
 * AppNavigationComponent is responsible for rendering the application navigation bar.
 *
 * @export
 * @class AppNavigationComponent
 */
@Component({
    selector: 'app-app-navigation',
    templateUrl: './app-navigation.component.html',
    styleUrls: ['./app-navigation.component.scss'],
    standalone: true,
    imports: [
        AsyncPipe,
        CommonModule,
        LoginPageComponent,
        LuicModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatListModule,
        MatSelectModule,
        MatSidenavModule,
        MatToolbarModule,
        NetworkStatusComponent,
        RouterModule,
        TranslateModule,
        NetworkQualityComponent,
    ],
})
export class AppNavigationComponent implements OnInit, OnDestroy {
    /**
     * EventEmitter to emit a navigation event when a menu item is clicked.
     */
    @Output() navigate = new EventEmitter<string>();

    @Input() HandsetPortrait: boolean = false;
    @Input() currentHandyOrientation: string = '';

    /**
     * Buttons for the navigation bar on the top.
     */
    public buttons: NavigationButton[] = [
        {
            label: 'COMPONENT.SIDEBAR.start',
            link: '/start',
            activeOnRoutes: ['/start'],
            handyLabel: 'home',
            abbreviation: 'Home',
        },
        {
            label: 'COMPONENT.SIDEBAR.orga',
            link: '/organization',
            activeOnRoutes: ['/organization', '/patient-details'],
            handyLabel: 'medical_services',
            abbreviation: 'Orga',
        },
        {
            label: 'COMPONENT.SIDEBAR.workflow',
            link: '/workflow',
            activeOnRoutes: ['/workflow'],
            abbreviation: 'Workflow',
            handyLabel: 'account_tree',
        },
    ];
    public selectedButton = this.buttons[0];

    private navigationHistory: string[] = [];
    private currentPosition: number = 0;
    private allSubs: Subscription[] = [];

    public constructor(
        private networkService: NetworkService,
        public router: Router
    ) {}

    public async ngOnInit(): Promise<void> {
        await this.loadNavigationState();

        this.allSubs.push(
            this.router.events.subscribe(async (event) => {
                if (event instanceof NavigationEnd) {
                    if (event.url !== '/login') {
                        await this.manageNavigationHistory(event.url);
                    }
                }
            })
        );
    }

    public ngOnDestroy(): void {
        this.allSubs.forEach(s => s.unsubscribe());
    }

    //#region Listeners
    /**
     * Handles the navigation when a menu item is clicked.
     */
    public onClickOnButton(button: NavigationButton): void {
        this.selectedButton = button;
        this.navigate.emit(button.link);
    }

    public async onClickOnBack(): Promise<boolean> {
        if (this.canNavigateBack()) {
            this.currentPosition--;
            await this.router.navigate(
                [this.navigationHistory[this.currentPosition]],
                { replaceUrl: true }
            );
            return true;
        }
        return false;
    }

    public async onClickOnForward(): Promise<boolean> {
        if (this.canNavigateForward()) {
            this.currentPosition++;
            await this.router.navigate(
                [this.navigationHistory[this.currentPosition]],
                { replaceUrl: true }
            );
            return true;
        }
        return false;
    }

    public async onClickOnNetStatus(): Promise<void> {
        const res = await this.networkService.pingWithPayload();
    }
    //#endregion

    public canNavigateBack(): boolean {
        return this.currentPosition > 0;
    }

    public canNavigateForward(): boolean {
        return this.currentPosition < this.navigationHistory.length - 1;
    }

    public isButtonActive(button: NavigationButton): boolean {
        const url = this.router.url;

        for (const r of button.activeOnRoutes) {
            if (url.startsWith(r)) return true;
        }

        return false;
    }

    private async saveNavigationState(): Promise<void> {
        const currentDate = new Date().toISOString().slice(0, 10); // YYYY-MM-DD format
        await Preferences.set({
            key: LS_NAVIGATION_HISTORY,
            value: JSON.stringify(this.navigationHistory),
        });
        await Preferences.set({
            key: LS_CURRENT_POSITION,
            value: JSON.stringify(this.currentPosition),
        });
        await Preferences.set({ key: LS_LAST_SAVED_DATE, value: currentDate });
    }

    private async loadNavigationState(): Promise<void> {
        const { value: savedDate } = await Preferences.get({
            key: LS_LAST_SAVED_DATE,
        });
        const currentDate = new Date().toISOString().slice(0, 10);

        if (savedDate !== currentDate) {
            // Das gespeicherte Datum ist nicht heute, also setze die Historie zurück
            await this.resetNavigationState();
        } else {
            // Lade die gespeicherten Daten, da das Datum übereinstimmt
            const { value: savedHistory } = await Preferences.get({
                key: LS_NAVIGATION_HISTORY,
            });
            const { value: savedPosition } = await Preferences.get({
                key: LS_CURRENT_POSITION,
            });

            if (savedHistory) {
                this.navigationHistory = JSON.parse(savedHistory);
            }
            if (savedPosition !== null) {
                this.currentPosition = JSON.parse(savedPosition);
            }
        }
    }

    private async resetNavigationState(): Promise<void> {
        this.navigationHistory = [];
        this.currentPosition = 0;
        await Preferences.remove({ key: LS_NAVIGATION_HISTORY });
        await Preferences.remove({ key: LS_CURRENT_POSITION });
        // Optional: Das Datum zurücksetzen
        await Preferences.remove({ key: LS_LAST_SAVED_DATE });
    }

    private async manageNavigationHistory(url: string): Promise<void> {
        if (url === '/login') {
            return;
        }

        if (this.navigationHistory[this.currentPosition] !== url) {
            if (this.currentPosition < this.navigationHistory.length - 1) {
                this.navigationHistory = this.navigationHistory.slice(
                    0,
                    this.currentPosition + 1
                );
            }
            this.navigationHistory.push(url);
            this.currentPosition = this.navigationHistory.length - 1;
            await this.saveNavigationState();
        }
    }
}
