import { Component, OnInit, signal, ViewChild } from '@angular/core';
import { LuicModule, ViewContent } from '@lohmann-birkner/luic';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { CommonModule, DatePipe, registerLocaleData } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { Cp2ApiService } from '../../services/cp2-api.service';
import { TranslateModule } from '@ngx-translate/core';
import { CapacitorHttp, HttpResponse } from '@capacitor/core';
import { UpcommingPageComponent } from '../base/upcomming-page/upcomming-page.component';
import { SettingsFacadeService } from '../../services/facades/settings-facade.service';
import { NetworkStatusComponent } from '../../components/utility/network-status/network-status.component';
import { ServerStatus } from '@lohmann-birkner/luic/lib/models/server.model';
import { v4 as uuidv4 } from 'uuid';
import { interval, Subscription, takeWhile } from 'rxjs';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { Settings } from '../../models/settings.model';
import localeDe from '@angular/common/locales/de';
import { HomePatientBasicInfoComponent } from '../../components/home-page/home-patient-basic-info/home-patient-basic-info.component';
import { HomePatientTasksComponent } from '../../components/home-page/home-patient-tasks/home-patient-tasks.component';
import { MatList, MatListItem } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { HomePatientVisitComponent } from '../../components/home-page/home-patient-visit/home-patient-visit.component';
import { FormioRendererI18n } from '../../components/data-interaction/formio-renderer/formio-renderer.component';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatTable, MatTableModule } from '@angular/material/table';
import {
    MatButtonToggleChange,
    MatButtonToggleModule,
} from '@angular/material/button-toggle';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatExpansionModule } from '@angular/material/expansion';

import {
    animate,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';
import { SortDirection } from '@angular/material/sort';
import {
    SortOption,
    SortSelectComponent,
    SortSelection,
} from '../../components/utility/sort-select/sort-select.component';
import { SortIndicatorComponent } from '../../components/utility/sort-indicator/sort-indicator.component';
import { MatTabsModule } from '@angular/material/tabs';
import { VcPatientListItem } from '../../models/view-content.models/view-content.model';
import { RouterModule } from '@angular/router';
import { addIcons } from "ionicons";

registerLocaleData(localeDe);

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    standalone: true,
    providers: [DatePipe],
    animations: [
        trigger('detailExpand', [
            state(
                'collapsed',
                style({ height: '0px', minHeight: '0', display: 'none' })
            ),
            state('expanded', style({ height: '*', display: 'block' })),
            transition(
                'expanded <=> collapsed',
                animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
            ),
        ]),
        trigger('detailExpand', [
            state(
                'collapsed',
                style({ height: '0px', minHeight: '0', display: 'none' })
            ),
            state('expanded', style({ height: '*', display: 'block' })),
            transition(
                'expanded <=> collapsed',
                animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
            ),
        ]),
    ],
    imports: [
        CommonModule,
        FormsModule,
        HomePatientBasicInfoComponent,
        HomePatientTasksComponent,
        HomePatientVisitComponent,
        LuicModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatDividerModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatList,
        MatList,
        MatListItem,
        MatListItem,
        MatPaginatorModule,
        MatSelectModule,
        MatTableModule,
        MatTabsModule,
        NetworkStatusComponent,
        RouterModule,
        SortIndicatorComponent,
        SortSelectComponent,
        TranslateModule,
        UpcommingPageComponent,
    ],
})
export class HomeComponent implements OnInit {
    public allSubs: Subscription[] = [];
    /**translation data for the incoming data */
    public viewContentI18nVisiteRecord: FormioRendererI18n | undefined;
    public viewContentI18nTask: FormioRendererI18n | undefined;

    public currentToggleButton: string = 'patientList';
    public selectedList: string = 'List1';

    //#region Dashboard variables
    public viewContent$: any | undefined;
    public dashboardInfo: ViewContent | undefined;
    public servers: ServerStatus[] = [];
    public initPings: boolean = false;
    //#endregion
    panelOpenState = false;
    //#region sort variables
    public sortKey: string = 'lastName';
    public sortOrder: SortDirection = 'asc';
    public searchQuery: string = '';
    public sortOptions: SortOption[] = [
        { value: 'lastName', translateKey: 'COMPONENT.PAGE_WORKFLOW.lastName' },
        {
            value: 'admissionDate',
            translateKey: 'COMPONENT.PAGE_WORKFLOW.admissionDate',
        },
        { value: 'ward', translateKey: 'COMPONENT.PAGE_WORKFLOW.ward' },
    ];
    //#endregion

    //#region for data in Handy
    public patients: VcPatientListItem[] = [];
    public filteredPatients: VcPatientListItem[] = [];
    public patientListToShow: VcPatientListItem[] = [];
    public patientListPage = 0;
    public patientListPageSize = 10;
    @ViewChild(MatTable) table: MatTable<any> | undefined;
    public displayedColumns = ['patient', 'followUp', 'tasks'];

    // for mobile version
    public handyDisplayedColumns = ['patients'];
    columnsToDisplayWithExpand = [...this.handyDisplayedColumns, 'expand'];
    expandedElement: any;
    // Create a map to display breakpoint names for demonstration purposes.
    displayNameMap = new Map([
        [Breakpoints.HandsetPortrait, 'handsetPortrait'],
        [Breakpoints.HandsetLandscape, 'handsetLandscape'],
        [Breakpoints.Tablet, 'tablet'],
        [Breakpoints.Web, 'web'],
    ]);
    currentBreakpoint = signal('');
    public constructor(
        private api: Cp2ApiService,
        private breakpointObserver: BreakpointObserver,
        private settingsFacade: SettingsFacadeService
    ) {
        this.settingsFacade.loadSettings();
    }

    public async ngOnInit(): Promise<void> {
        const sub: Subscription = this.settingsFacade.settings$.subscribe(
            async (settings: Settings) => await this.refresh()
        );
        this.allSubs.push(sub);

        try {
            this.dashboardInfo = await this.api.getDashboardInfo();
        } catch (err) {
            console.error('Failed to load dashboard information:', err);
        }

        this.allSubs.push(
            this.breakpointObserver
                .observe([
                    Breakpoints.HandsetPortrait,
                    Breakpoints.HandsetLandscape,
                    Breakpoints.Tablet,
                    Breakpoints.Web,
                ])
                .subscribe((result) => {
                    for (const query of Object.keys(result.breakpoints)) {
                        if (result.breakpoints[query]) {
                            let breakpoint =
                                this.displayNameMap.get(query) ?? '';
                            this.currentBreakpoint.set(breakpoint);
                        }
                    }
                })
        );
    }

    public ngOnDestroy(): void {
        this.allSubs.forEach((sub) => sub.unsubscribe());
    }

    toggleRow(element: any) {
        this.expandedElement =
            this.expandedElement === element ? null : element;
    }

    //#region Listeners
    public async onPatientPagerChange($event: PageEvent): Promise<void> {
        this.patientListPage = $event.pageIndex;
        this.patientListPageSize = $event.pageSize;
        // this.searchSortAndPaginate();
    }

    public async onClickOnToggleButton($event: MatButtonToggleChange) {
        this.currentToggleButton = $event.value;

        if (this.currentToggleButton === 'dashboard' && this.dashboardInfo) {
            const serverStatusData = this.dashboardInfo['serverStatus']?.data;
            if (serverStatusData && serverStatusData.length > 0) {
                serverStatusData.forEach((s: ServerStatus) => {
                    // Initialize chart object if it's not initialized yet
                    if (!s.chart) {
                        s.chart = {
                            chartUUID: 'chart-' + uuidv4(),
                            chartItem: undefined,
                        };
                    }

                    // Ensure chartItem is initialized to undefined if it's not already set
                    if (s.chart.chartItem) {
                        s.chart.chartItem = undefined;
                    }
                });

                // Update the servers property
                this.servers = serverStatusData;

                // Start the ping and update at intervals
                await this.triggerPingAndUpdate('http://');
                const pingIntervalSub = interval(5000)
                    .pipe(takeWhile(() => !!this.dashboardInfo))
                    .subscribe(() => this.triggerPingAndUpdate('http://'));
                this.allSubs.push(pingIntervalSub);
            }
        } else if (this.currentToggleButton === 'patientList') {
            this.allSubs.forEach((sub) => sub.unsubscribe());
            this.allSubs = [];
        }
    }

    //#endregion

    //#region Functions for sort
    private sortList(patients: VcPatientListItem[]): VcPatientListItem[] {
        return patients?.sort((a, b) => {
            let compare = 0;

            switch (this.sortKey) {
                case 'lastName':
                    // Assuming that lastName is part of the patient details
                    compare =
                        a.patient_details.patient.data.surname.localeCompare(
                            b.patient_details.patient.data.surname
                        ) ||
                        a.patient_details.patient.data.name.localeCompare(
                            b.patient_details.patient.data.name
                        );
                    break;
                case 'admissionDate':
                    // Assuming admissionDate is part of the case details
                    compare =
                        new Date(
                            a.patient_details.case.data.admission_date
                        ).getTime() -
                        new Date(
                            b.patient_details.case.data.admission_date
                        ).getTime();
                    break;
                case 'ward':
                    // Assuming ward is part of the patient's current place or department
                    compare =
                        a.patient_details.current_place.data.shortName.localeCompare(
                            b.patient_details.current_place.data.shortName
                        );
                    break;

                default:
                    break;
            }

            return this.sortOrder === 'asc' ? compare : -compare;
        });
    }
    public onSortChange(direction: SortSelection): void {
        this.sortKey = direction.value;
        this.sortOrder = direction.sortOrder;
        this.searchSortAndPaginate();
    }
    //#region search area
    private matchesPatient(patient: VcPatientListItem, query: string): boolean {
        // Convert the query to lowercase for case-insensitive comparison
        const lowerCaseQuery = query.toLowerCase();

        // Check simple string properties
        const matchesSimpleFields = [
            patient.patient_details.patient.data.surname,
            patient.patient_details.current_place.data.shortName,
            patient.patient_details.case.data.admission_date,
        ].some((field) => field.toLowerCase().includes(lowerCaseQuery));

        // Check array of strings (diagnosis)
        const matchesDiagnosis =
            patient.patient_details.last_diagnose.data.diagnose
                .toLowerCase()
                .includes(lowerCaseQuery);

        // Check nested Tasks array
        const matchesTasks = patient.tasks.some(
            (task) =>
                task.data.task_name.toLowerCase().includes(lowerCaseQuery) ||
                task.data.details.toLowerCase().includes(lowerCaseQuery) ||
                task.data.priority.toLowerCase().includes(lowerCaseQuery) ||
                task.data.editor.surname
                    .toLowerCase()
                    .includes(lowerCaseQuery) ||
                task.data.editor.name.toLowerCase().includes(lowerCaseQuery) ||
                task.data.goalDateOn.toLowerCase().includes(lowerCaseQuery) ||
                task.data.contractor.surname
                    .toLowerCase()
                    .includes(lowerCaseQuery) ||
                task.data.contractor.name.toLowerCase().includes(lowerCaseQuery)
        );

        // Check nested Visits array
        const matchesVisits = patient.visit_record.history?.some(
            (visitRecord) =>
                visitRecord.data.note.toLowerCase().includes(lowerCaseQuery)
        );

        // Check Discharge information (if applicable)
        const matchesDischarge =
            patient.patient_details.case.data.discharge_date
                ?.toLowerCase()
                .includes(lowerCaseQuery) ||
            patient.patient_details.case.data.planned_discharge_date
                ?.toLowerCase()
                .includes(lowerCaseQuery) ||
            false;

        // Return true if any of the above conditions match
        return (
            matchesSimpleFields ||
            matchesDiagnosis ||
            matchesTasks ||
            matchesVisits ||
            matchesDischarge
        );
    }

    public onSearchFieldInput(): void {
        this.searchSortAndPaginate();
    }
    //#endregion

    /**
     * Triggers a ping to all servers and updates their chart data.
     * @param protocol The protocol to use for the ping (either "https://" or "http://").
     * @returns A Promise that resolves when all servers are pinged and updated.
     */
    private async triggerPingAndUpdate(
        protocol: 'https://' | 'http://'
    ): Promise<void> {
        const updates = this.servers.map((server) =>
            this.pingServerAndUpdate(server, protocol)
        );
        const updatedServers = await Promise.all(updates);

        if (!this.initPings) {
            this.initPings = true;
        }

        this.servers = updatedServers;

        if (this.dashboardInfo) {
            this.dashboardInfo = {
                ...this.dashboardInfo,
                serverStatus: {
                    ...this.dashboardInfo.serverStatus,
                    data: this.servers,
                },
            };
        }
    }

    /**
     * Returns true if response.status is between 200 and 299, false otherwise
     */
    private isResponseSuccessful(response: HttpResponse): boolean {
        return response.status >= 200 && response.status < 300;
    }

    /**
     * Pings a server and updates its status.
     * @param server The server to ping.
     * @param protocol The protocol to use for the ping (either "https://" or "http://").
     * @returns The updated server status after the ping.
     */
    private async pingServerAndUpdate(
        server: ServerStatus,
        protocol: 'https://' | 'http://'
    ): Promise<ServerStatus> {
        const startTime: number = Date.now();
        let isServerActive: boolean = false;

        try {
            const response = await CapacitorHttp.get({
                url: protocol + server.ip,
            });
            isServerActive = this.isResponseSuccessful(response);
        } catch (e) {
            console.error(e);
        } finally {
            const ms: number = startTime ? Date.now() - startTime : 0;
            server.active = isServerActive;
            server.lastPingMs = ms;
        }

        return server;
    }

    private async refresh(): Promise<void> {
        this.patients = await this.api.getVcPatientListItem();

        // Commented by JG on 11.09.2024 - This does nothing!! Delete!
        // (await this.api.getVcPatientListItem()).map((el) => {
        //     return (this.viewContentI18nVisiteRecord = el.visit_record.i18n);
        // });

        // (await this.api.getVcPatientListItem()).map((el) => {
        //     el.tasks.map((task) => {
        //         return (this.viewContentI18nTask = task.i18n);
        //     });
        // });
        this.searchSortAndPaginate();
    }

    //TODO
    private searchSortAndPaginate(): void {
        this.filteredPatients = !this.searchQuery.trim()
            ? this.patients
            : this.patients.filter((patient) =>
                  this.matchesPatient(patient, this.searchQuery.toLowerCase())
              );

        const sortedPatients = this.sortList(this.filteredPatients);

        // JG: Pagination deactivated on 3.06.2024
        // this.patientListToShow = sortedPatients.slice(
        //     this.patientListPageSize * this.patientListPage,
        //     this.patientListPageSize * (this.patientListPage + 1)
        // );
        this.patientListToShow = sortedPatients;
        this.table?.renderRows(); //trigger the update of the table
    }
}
