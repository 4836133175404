import {PatientListItem} from './../models/patient.model';
import {AuthorizationToken, Device, UserToken} from '../models/auth.model';
import {Injectable} from '@angular/core';
import {DEFAULT_SETTINGS, Settings} from '../models/settings.model';
import {
    LS_ACCESS_TOKEN,
    LS_AUTHZ_TOKENS,
    LS_CODE_VERIFIER,
    LS_DEVICE,
    LS_LOCATORS,
    LS_PATIENT_RECORDS,
    LS_PATIENT_RECORDS_AREAS,
    LS_PATIENT_RECORDS_SUBAREAS,
    LS_PATIENT_SELECTED_RECORD,
    LS_SETTINGS,
    LS_VIEWCONTENT
} from '../shared/constants';
import {Preferences} from '@capacitor/preferences';
import {DbService} from './database/db.service';
import {MercureStatus} from '../models/mercure.model';
import {Area, Record, SubArea} from '../models/patient-records.model';
import {VcHistoryElement, VcPatientListItem, ViewContent} from '../models/view-content.models/view-content.model';
import {capSQLiteChanges} from '@capacitor-community/sqlite';
import {FormioForm} from '../models/formio.model';
import {FormioRendererI18n} from '../components/data-interaction/formio-renderer/formio-renderer.component';
import {Locator} from '../models/view-content.model';
import {CP2_User} from '../models/view-content.models/view-content-personal-domain.model';

//
// TODO: BIG TODO!!! Check the use of Access and Authz with care
//

@Injectable({
    providedIn: 'root'
})
export class DataRepositoryService {
    private static readonly TAG = 'DataRepositoryService';

    public constructor(private db: DbService) {
    }

    //#region Authz
    /**
     * Safely gets all authorization tokens. Will return all saved authz tokens or an empty array in case of error or none
     */
    public async getAllAuthzTokens(): Promise<AuthorizationToken[]> {
        try {
            const v = (await Preferences.get({key: LS_AUTHZ_TOKENS})).value;

            if (v) return JSON.parse(v);
        } catch (e) {
            console.warn(
                DataRepositoryService.TAG,
                'error retrieving authz tokens',
                e
            );
        }

        return [];
    }

    /**
     * Save the given array to the preferences' authorization tokens. Will clean up the expired tokens before save.
     * Will overwrite whatever was in preferences before.
     */
    public async saveAuthzTokens(tokens: AuthorizationToken[]): Promise<void> {
        // Remove tokens that are older than 300 seconds
        const currentTimeInSeconds = Math.floor(Date.now() / 1000);
        tokens = tokens.filter((token) => {
            const expiresIn = token.exp;
            return expiresIn >= 0 && expiresIn <= 300 + currentTimeInSeconds;
        });

        await Preferences.set({
            key: LS_AUTHZ_TOKENS,
            value: JSON.stringify(tokens)
        });
    }

    //#endregion

    //#region Access Token
    public async getAccessToken(): Promise<UserToken | null> {
        try {
            const value = (await Preferences.get({key: LS_ACCESS_TOKEN}))
                .value;

            return value ? JSON.parse(value) : null;
        } catch (e) {
            console.warn(
                `${DataRepositoryService.TAG}, error retrieving access token`,
                e
            );
            return null;
        }
    }

    public async setAccessToken(token: UserToken | null): Promise<void> {
        if (!token) {
            await Preferences.remove({key: LS_ACCESS_TOKEN});
            return;
        }

        await Preferences.set({
            key: LS_ACCESS_TOKEN,
            value: JSON.stringify(token)
        });
    }

    public async removeAccessToken(): Promise<void> {
        return await this.setAccessToken(null);
    }

    public async saveCodeVerifier(codeVerifier: string | null): Promise<void> {
        if (!codeVerifier) {
            await Preferences.remove({key: LS_CODE_VERIFIER});
            return;
        }

        await Preferences.set({
            key: LS_CODE_VERIFIER,
            value: codeVerifier
        });
    }

    public async getCodeVerifier(): Promise<string | null> {
        try {
            return (await Preferences.get({key: LS_CODE_VERIFIER})).value;
        } catch (e) {
            console.warn(
                `${DataRepositoryService.TAG}, error retrieving code verifier`,
                e
            );
            return null;
        }
    }

    public async removeCodeVerifier(): Promise<void> {
        try {
            await Preferences.remove({key: LS_CODE_VERIFIER});
        } catch (e) {
            console.error(
                `${DataRepositoryService.TAG}, error removing code verifier`,
                e
            );
        }
    }

    //#endregion

    //#region settings
    public async saveSettings(settings: Settings): Promise<void> {
        await Preferences.set({
            key: LS_SETTINGS,
            value: JSON.stringify(settings)
        });
    }

    public async getSettings(): Promise<Settings> {
        try {
            const value = (await Preferences.get({key: LS_SETTINGS})).value;
            return value ? JSON.parse(value) : DEFAULT_SETTINGS;
        } catch (e) {
            console.warn(
                `${DataRepositoryService.TAG}, error retrieving settings`,
                e
            );
            return DEFAULT_SETTINGS;
        }
    }

    //#endregion

    //#region locators

    public async getLocators(): Promise<Locator[]> {
        try {
            const v = (await Preferences.get({key: LS_LOCATORS})).value;
            if (v) return JSON.parse(v);
        } catch (e) {
            console.warn(
                DataRepositoryService.TAG,
                'error retrieving locators',
                e
            );
        }

        return [];
    }

    public async saveLocators(locator: Locator[]): Promise<void> {
        await Preferences.set({
            key: LS_LOCATORS,
            value: JSON.stringify(locator)
        });
    }

    //#endregion

    //#region settings
    public async saveMercure(mercure: MercureStatus): Promise<void> {
        /*  await Preferences.set({
         key: LS_SETTINGS,
         value: JSON.stringify(settings),
         });*/
    }

    public async getMercure(): Promise<any> {
        /* try {
         const value = (await Preferences.get({ key: LS_SETTINGS })).value;
         return value ? JSON.parse(value) : DEFAULT_SETTINGS;
         } catch (e) {
         console.warn(
         `${DataRepositoryService.TAG}, error retrieving settings`,
         e
         );
         return DEFAULT_SETTINGS;
         }*/
    }

    //#endregion

    //#region Device
    public async getDevice(): Promise<Device> {
        try {
            const v = (await Preferences.get({key: LS_DEVICE})).value;

            if (v) return JSON.parse(v);
        } catch (e) {
            console.warn(
                DataRepositoryService.TAG,
                'error retrieving device id',
                e
            );
        }

        return {deviceId: ''};
    }

    public async saveDevice(device: Device): Promise<void> {
        await Preferences.set({
            key: LS_DEVICE,
            value: JSON.stringify(device)
        });
    }

    //#endregion

    //#region ViewContent
    async setViewContent(viewContent: ViewContent<any>[]): Promise<any> {
        await Preferences.set({
            key: LS_VIEWCONTENT,
            value: JSON.stringify(viewContent)
        });
    }

    async getViewContent(): Promise<ViewContent<any>[]> {
        try {
            const v = (await Preferences.get({key: LS_VIEWCONTENT})).value;

            if (v) return JSON.parse(v);
        } catch (e) {
            console.warn(
                DataRepositoryService.TAG,
                'error retrieving viewcontent',
                e
            );
        }

        return [];
    }

    // async createViewContent(viewContent: ViewContent): Promise<any> {
    //     return this.dbService.createViewContent(
    //         viewContent.locator,
    //         viewContent.content,
    //         viewContent.scopes ?? ''
    //     );
    // }

    // async getViewContentById(id: number): Promise<ViewContent> {
    //     const result = await this.db.getViewContentById(id.toString());
    //     return result.values; // Convert to ViewContent if necessary
    // }

    // async getAllViewContent(): Promise<ViewContent[]> {
    //     const results = await this.db.getAllViewContent();
    //     return results.values;
    // }

    // async updateViewContent(viewContent: ViewContent): Promise<any> {
    //     if (!viewContent.id || viewContent.id < 1)
    //         throw Error(
    //             'view content must have a positive id property for update'
    //         );

    //     return this.db.updateViewContent(
    //         viewContent.id,
    //         viewContent.locator,
    //         viewContent.content,
    //         viewContent.scopes ?? ''
    //     );
    // }

    async deleteViewContent(id: number): Promise<any> {
        return this.db.deleteViewContent(id);
    }

    //#endregion

    //#region patientRecords

    async getRecords(): Promise<Record[]> {
        const {value} = await Preferences.get({key: LS_PATIENT_RECORDS});
        return value ? JSON.parse(value) : [];
    }

    async setRecords(records: Record[]): Promise<void> {
        await Preferences.set({
            key: LS_PATIENT_RECORDS,
            value: JSON.stringify(records)
        });
    }

    async getSelectedRecord(): Promise<Record | null> {
        const {value} = await Preferences.get({
            key: LS_PATIENT_SELECTED_RECORD
        });
        return value ? JSON.parse(value) : null;
    }

    async setSelectedRecord(record: Record): Promise<void> {
        await Preferences.set({
            key: LS_PATIENT_SELECTED_RECORD,
            value: JSON.stringify(record)
        });
    }

    async removeSelectedRecord(): Promise<void> {
        await Preferences.remove({key: LS_PATIENT_SELECTED_RECORD});
    }

    async getAreas(): Promise<Area[]> {
        const {value} = await Preferences.get({
            key: LS_PATIENT_RECORDS_AREAS
        });
        return value ? JSON.parse(value) : [];
    }

    async getSubAreas(): Promise<SubArea[]> {
        const {value} = await Preferences.get({
            key: LS_PATIENT_RECORDS_SUBAREAS
        });
        return value ? JSON.parse(value) : [];
    }

    async addArea(area: Area): Promise<Area> {
        const areas = await this.getAreas();
        areas.push(area);
        await Preferences.set({
            key: LS_PATIENT_RECORDS_AREAS,
            value: JSON.stringify(areas)
        });
        return area;
    }

    async updateArea(updatedArea: Area): Promise<Area> {
        let areas = await this.getAreas();
        areas = areas.map((area) =>
            area.id === updatedArea.id ? updatedArea : area
        );
        await Preferences.set({
            key: LS_PATIENT_RECORDS_AREAS,
            value: JSON.stringify(areas)
        });
        return updatedArea;
    }

    async removeArea(areaId: string): Promise<void> {
        let areas = await this.getAreas();
        areas = areas.filter((area) => area.id !== areaId);
        await Preferences.set({
            key: LS_PATIENT_RECORDS_AREAS,
            value: JSON.stringify(areas)
        });
    }

    async addSubArea(subArea: SubArea): Promise<SubArea> {
        const subAreas = await this.getSubAreas();
        subAreas.push(subArea);
        await Preferences.set({
            key: LS_PATIENT_RECORDS_SUBAREAS,
            value: JSON.stringify(subAreas)
        });
        return subArea;
    }

    async updateSubArea(updatedSubArea: SubArea): Promise<SubArea> {
        let subAreas = await this.getSubAreas();
        subAreas = subAreas.map((subArea) =>
            subArea.id === updatedSubArea.id ? updatedSubArea : subArea
        );
        await Preferences.set({
            key: LS_PATIENT_RECORDS_SUBAREAS,
            value: JSON.stringify(subAreas)
        });
        return updatedSubArea;
    }

    async removeSubArea(subAreaId: string): Promise<void> {
        let subAreas = await this.getSubAreas();
        subAreas = subAreas.filter((subArea) => subArea.id !== subAreaId);
        await Preferences.set({
            key: LS_PATIENT_RECORDS_SUBAREAS,
            value: JSON.stringify(subAreas)
        });
    }

    //#endregion

    //#region ViewContent V2
    public async createViewContent<T>(
        vc: ViewContent<T>,
        user: CP2_User
    ): Promise<capSQLiteChanges> {
        const dataRes = await this.createVcData(vc.data);
        if (!dataRes?.changes || !dataRes.changes.lastId)
            throw Error('Error saving VC_Data');

        const vcRes = await this.db.createViewContent(
            vc.id,
            vc.locator,
            JSON.stringify(vc.owners),
            JSON.stringify(vc.owner_departments),
            vc.main_owner_job_type,
            vc.created_at,
            vc.status,
            vc.related_patient_id,
            vc.related_case_id,
            dataRes.changes.lastId,
            JSON.stringify(vc.form),
            JSON.stringify(vc.i18n)
        );
        if (!vcRes?.changes || !vcRes.changes.lastId)
            throw Error('Error saving ViewContent');

        // Store the current data in history with current time
        const histRes = await this.db.createViewContentHistory(
            vc.id,
            dataRes.changes.lastId,
            new Date().toISOString(),
            user.userId
        );
        if (!histRes?.changes || !histRes.changes.lastId)
            throw Error('Error saving History');

        return vcRes;
    }

    public async updateViewContent<T>(
        vc: ViewContent<T>,
        user: CP2_User
    ): Promise<any> {
        const dataRes = await this.createVcData(vc.data);
        if (!dataRes?.changes || !dataRes.changes.lastId)
            throw Error('Error saving VC_Data');

        const vcRes = await this.db.updateViewContent(
            vc.id,
            vc.locator,
            JSON.stringify(vc.owners),
            JSON.stringify(vc.owner_departments),
            vc.main_owner_job_type,
            vc.created_at,
            vc.status,
            vc.related_patient_id,
            vc.related_case_id,
            dataRes.changes.lastId,
            JSON.stringify(vc.form),
            JSON.stringify(vc.i18n)
        );
        if (!vcRes?.changes) throw Error('Error saving ViewContent');

        // Create new user in if necessary
        const userRes = await this.createCP2User(user);
        // TODO: Check problems/errors

        //Store the current data in history with current time
        const histRes = await this.db.createViewContentHistory(
            vc.id,
            dataRes.changes.lastId,
            new Date().toISOString(),
            user.userId
        );
        if (!histRes?.changes || !histRes.changes.lastId)
            throw Error('Error saving History');
    }

    public async createOrUpdateViewContent<T>(
        vc: ViewContent<T>,
        user: CP2_User
    ): Promise<any> {
        if (vc.id === '-1') return await this.createViewContent(vc, user);

        const e = await this.getFullViewContentForLocator(vc.locator);
        // const e = await this.getEmptyViewContentForId(vc.id);

        if (e) return await this.updateViewContent(vc, user);

        return await this.createViewContent(vc, user);
    }

    // TODO: @Jose - Hier wird die Patientenliste gespeichert. Genauer gesagt, werden hier aktuell die Daten aus dem Server (Mercure - ViewContent) gemapped zu unseren Models/Interfaces und dann als ViewContent gespeichert.
    public async savePatientListItem(
        patientListItem: any,
        user: CP2_User
    ): Promise<void> {
        const {
            patient_details,
            tasks,
            visit_record
        }: VcPatientListItem | any = patientListItem;

        console.warn('patient_details.case');
        await this.createViewContent(patient_details.case, user);

        console.warn('patient_details.patient');
        await this.createViewContent(patient_details.patient, user);

        console.warn('patient_details.current_place');
        await this.createViewContent(patient_details.current_place, user);

        console.warn('patient_details.last_diagnosis');
        await this.createViewContent(patient_details.last_diagnosis, user);

        console.warn('patient_details.tasks');
        for (const task of tasks) {
            await this.createViewContent(task, user);
        }

        console.warn('patient_details.visit_record');
        await this.createViewContent(visit_record, user);

        // await this.createViewContent(patient_details.case, user);
    }

    // TODO: @Jose - Hier werden die verschiedenen ViewContents aus der SQLiteDB geholt und als "PatientListItem" Objekt returned
    public async getPatientListItems(): Promise<PatientListItem[]> {
        // console.log(await this.getFullViewContentsWithSimilarLocator('case.details'))
        return [];
        // // TODO: @Jose - Der Boolean "true" sagt aus, das der Locator anhand des Operators "LIKE" gesucht wird!
        // const cases: ViewContent<Case>[] =
        //     await this.getFullViewContentForLocator('case.details', true);
        // const patients: ViewContent<Patient>[] =
        //     await this.getFullViewContentForLocator('patient', true);
        // const places: ViewContent<Place>[] =
        //     await this.getFullViewContentForLocator(
        //         'case.overview.place',
        //         true
        //     );
        // const diagnoses: ViewContent<Diagnose>[] =
        //     await this.getFullViewContentForLocator(
        //         'case.overview.diagnosis',
        //         true
        //     );
        // const visits: ViewContent<Visit_Record>[] =
        //     await this.getFullViewContentForLocator('visit-record', true);
        // const tasks: ViewContent<Task>[] =
        //     await this.getFullViewContentForLocator('task', true);

        // // TODO: @Jose - Ich weiß, das ist kein schöner Code! aber ich habe zu dem Zeitpunkt keine bessere alternative gefunden... Tut mir leid.. :(
        // // "Sicherstellen", dass die Arrays alle die gleiche Länge haben.
        // const minLength = Math.min(
        //     cases.length,
        //     patients.length,
        //     places.length,
        //     diagnoses.length,
        //     visits.length
        // );

        // if (minLength === 0) {
        //     throw new Error(
        //         'Keine ausreichenden Daten vorhanden, um PatientListItems zu erstellen.'
        //     );
        // }

        // // TODO: @Jose - Das muss (wahrscheinlich) auch nochmal angepasst werden, wenn ".tasks" mehrere Items im Array hat.
        // // TODO: @Jose - Hier wird das PatientListItem-Objekt zusammengesetzt
        // return Array(minLength)
        //     .fill(null)
        //     .map((_, i) => ({
        //         patient_details: {
        //             case: cases[i],
        //             patient: patients[i],
        //             current_place: places[i],
        //             last_diagnose: diagnoses[i],
        //         },
        //         tasks: [tasks[i]],
        //         visit_record: visits[i],
        //     }));
    }

    public async getFullViewContentForLocator<T>(
        locator: string
    ): Promise<ViewContent<T>> {
        let vc = (await this.db.getViewContentByLocator(locator)).values?.[0];
        if (!vc)
            throw Error(
                'Error fetching full view content for locator=' + locator
            );

        vc = this.parseViewContent(vc);
        const data = await this.getVcData(vc.data_id);
        vc.data = data;

        return vc;
    }

    public async getFullViewContentsWithSimilarLocator<T>(
        locator: string
    ): Promise<ViewContent<T>[]> {
        // Hol alle ViewContent-Objekte für den Locator (nicht nur das erste)
        const vcs = (await this.db.getViewContentByLocator(locator, true))
            .values;

        if (!vcs || vcs.length === 0) {
            throw new Error(
                'Error fetching full view content for locator=' + locator
            );
        }

        // Lade die zusätzlichen Daten für jedes ViewContent-Objekt und speichere es
        const viewContentArray: ViewContent<T>[] = [];
        for (const vc of vcs) {
            const data = await this.getVcData(vc.data_id);
            vc.data = data;
            viewContentArray.push(vc); // Füge das vollständige ViewContent-Objekt dem Array hinzu
        }

        return viewContentArray; // Rückgabe des Arrays mit allen ViewContent-Objekten
    }

    // Hilfsmethoden

    public getEmptyViewContentOld<T>(
        id: string,
        locator: string,
        main_owner_job_type: 'doctor' | 'nurse' | 'admin' | 'other',
        status: 'final' | 'not_final',
        related_patient_id: string,
        related_case_id: string,
        data: T,
        owners: [],
        owner_departments: [],
        created_at?: string,
        form?: FormioForm,
        i18n?: FormioRendererI18n
    ): ViewContent<T> {
        return {
            id,
            locator,
            main_owner_job_type,
            status,
            related_patient_id,
            related_case_id,
            data,
            owners,
            owner_departments,
            created_at: created_at ?? new Date().toISOString(),
            form,
            i18n
        };
    }

    public async getAllEmptyViewContentForCase(
        caseId: string
    ): Promise<ViewContent<null>[]> {
        const res = await this.db.getAllViewContentForCase(caseId);

        return res.values.map((c: any) => this.parseViewContent(c));
    }

    public async getAllEmptyViewContent(): Promise<ViewContent<null>[]> {
        const res = await this.db.getAllViewContent();

        return res.values.map((c: any) => this.parseViewContent(c));
    }

    public async getVcHistory<T>(vcId: string): Promise<VcHistoryElement<T>[]> {
        const res = await this.db.getHistoryForViewContent(vcId);

        // Build an array with the full editors
        const v: any[] = res.values ?? [];
        const editorIds = Array.from(new Set(v.map((item) => item['editor'])));
        const editors: CP2_User[] = [];
        for (const id of editorIds) {
            const ed = await this.getCp2UserById(id);
            if (ed) editors.push(ed);
        }

        if (res.values?.length) {
            const h = res.values.map<VcHistoryElement<T>>((e) => ({
                modifiedAt: e.datetime,
                modifiedBy:
                    editors.find((ed) => ed.userId === e.editor) ??
                    ({} as CP2_User),
                data: JSON.parse(e.data)
            }));
            return h;
        }

        return [];
    }

    //#endregion

    private parseViewContent<T>(vc: any): ViewContent<T> {
        try {
            vc.i18n = vc.i18n ? JSON.parse(vc.i18n) : undefined;
            vc.owner_departments = vc.owner_departments
                ? JSON.parse(vc.owner_departments)
                : undefined;
            vc.owners = vc.owners ? JSON.parse(vc.owners) : undefined;
            vc.form = vc.form ? JSON.parse(vc.form) : undefined;
        } catch (e) {
            console.warn('Error parsing View Content', e);
        }
        return vc;
    }

    //#region VC Data & VC History
    private async getVcData(id: number): Promise<any> {
        const res = await this.db.getVcDataForId(id);
        if (!res.values?.[0]?.data) return null;

        return JSON.parse(res.values[0].data);
    }

    private async createVcData(data: any): Promise<capSQLiteChanges> {
        return await this.db.createVcData(JSON.stringify(data));
    }

    //#endregion

    //#region CP2_User
    private async createCP2User(u: CP2_User): Promise<capSQLiteChanges> {
        return await this.db.createCp2User(
            u.userId,
            u.surname,
            u.name,
            u.validSince ?? '',
            u.validUntil ?? ''
        );
    }

    private async updateCP2User(u: CP2_User): Promise<capSQLiteChanges> {
        return await this.db.updateCp2User(
            u.userId,
            u.surname,
            u.name,
            u.validSince ?? '',
            u.validUntil ?? ''
        );
    }

    private async getCp2UserById(id: string): Promise<CP2_User | null> {
        const res = await this.db.getCp2UserById(id);
        if (!res.values?.[0]) return null;

        return res.values[0];
    }

    //#endregion
}
