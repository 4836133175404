import { v4 as uuidv4 } from 'uuid';

/**
 * Represents a group of curves in the UI.
 */
export interface CurveGroup {
    /**
     * The unique identifier for the curve group.
     */
    id: string;

    /**
     * The label or name of the curve group.
     */
    label: string;

    /**
     * The position of the curve group in the UI. Can be 'upper', 'middle', or 'lower'.
     */
    position: 'upper' | 'middle' | 'lower';

    /**
     * The order in which the curve group should be sorted/displayed in the UI.
     */
    sortOrder: number;

    /**
     * The current status of the curve group, indicating whether it is normal, deleted, hidden, or any other state.
     */
    status: 'normal' | 'deleted' | 'hidden' | string;

    /**
     * The rows of data associated with this curve group.
     */
    rows: CurveRow[];
}

/**
 * Represents a row of data within a curve group.
 */
export interface CurveRow {
    /**
     * The unique identifier for the curve row.
     */
    id: string;

    /**
     * The label or name of the curve row.
     */
    label: string;

    /**
     * The order in which the curve row should be sorted/displayed within its group in the UI.
     */
    sortOrder: number;

    /**
     * The current status of the curve row, indicating whether it is normal, deleted, hidden, or any other state.
     */
    status: 'normal' | 'deleted' | 'hidden' | string;

    /**
     * The data items associated with this curve row.
     */
    data: CurveItemData[];
}

/**
 * Represents a single data item within a curve row.
 */
export interface CurveItemData {
    /**
     * The unique identifier for the data item.
     */
    id: string;

    /**
     * The date associated with the data item.
     */
    date: string;

    /**
     * The value of the data item.
     */
    value: string;
}

export const getNewCurveGroup = (
    id = uuidv4(),
    label = 'Neue Gruppe',
    position: 'upper' | 'middle' | 'lower' = 'upper',
    rows: CurveRow[] = []
): CurveGroup => ({
    id,
    label,
    position,
    status: 'normal',
    sortOrder: 0,
    rows,
});

export const getNewCurveRow = (
    id = uuidv4(),
    label = 'Neue Zeile',
    data: CurveItemData[] = []
): CurveRow => ({
    id,
    label,
    status: 'normal',
    sortOrder: 0,
    data,
});
