import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import * as RecordsSelectors from '../../store/selectors/records.selector';
import * as RecordsActions from '../../store/actions/records.action';
import {AppState} from '../../store/appstate.model';
import {Record} from "../../models/patient-records.model";

@Injectable({
    providedIn: 'root',
})
export class RecordsFacadeService {
    public records$ = this.store.select(RecordsSelectors.selectAllRecords);
    public selectedRecord$ = this.store.select(RecordsSelectors.selectSelectedRecord);
    public recordsError$ = this.store.select(RecordsSelectors.selectRecordsError);
    public loading$ = this.store.select(RecordsSelectors.selectLoading);

    public constructor(private store: Store<AppState>) {
    }

    public loadRecords(): void {
        this.store.dispatch(RecordsActions.loadRecords());
    }

    public loadSelectedRecord(): void {
        this.store.dispatch(RecordsActions.loadSelectedRecord());
    }

    public setSelectedRecord(record: Record): void {
        this.store.dispatch(RecordsActions.setSelectedRecord({record}));
    }

    public removeSelectedRecord(): void {
        this.store.dispatch(RecordsActions.removeSelectedRecord());
    }
}
