<div id="home-wrapper">
    <div
        [ngClass]="{
            'absolute top-0 right-0': currentBreakpoint() === 'handsetLandscape'
        }"
        class="list-dashboard-sort-search"
    >
        <mat-button-toggle-group
            (change)="onClickOnToggleButton($event)"
            *ngIf="!currentBreakpoint()"
            [(value)]="currentToggleButton"
        >
            <mat-button-toggle
                class="list-dashboard"
                name="list"
                value="patientList"
            >
                <mat-icon
                    *ngIf="currentToggleButton === 'patientList'"
                    [ngClass]="{
                        'mat-select-selected':
                            currentToggleButton == 'patientList'
                    }"
                    class="activeIcon"
                    >done
                </mat-icon>
                <mat-select
                    [(ngModel)]="selectedList"
                    [ngClass]="{
                        'mat-select-selected':
                            currentToggleButton == 'patientList'
                    }"
                >
                    <mat-option [value]="'List1'" class="patient-list-dropdown"
                        >Liste-1
                    </mat-option>
                    <mat-option [value]="'List2'" class="patient-list-dropdown"
                        >Liste-2
                    </mat-option>
                    <mat-option [value]="'List3'" class="patient-list-dropdown"
                        >Liste-3
                    </mat-option>
                </mat-select>
            </mat-button-toggle>
            <mat-button-toggle
                [ngClass]="{
                    'mat-select-selected': currentToggleButton == 'dashboard'
                }"
                class="activeIcon"
                value="dashboard"
            >
                <mat-icon
                    *ngIf="currentToggleButton === 'dashboard'"
                    class="activeIcon"
                    >done
                </mat-icon>
                <span>Dashboard</span></mat-button-toggle
            >
        </mat-button-toggle-group>
        <!-- sort and search area -->
        <div
            *ngIf="currentToggleButton === 'patientList'"
            class="patient-list-sort-search"
        >
            <app-sort-select
                (directionChange)="onSortChange($event)"
                [options]="sortOptions"
                class="patient-list-sort"
            />

            <mat-form-field class="patient-list-search">
                <mat-label
                    >{{
                        "COMPONENT.PAGE_PATIENT_LIST.placeholder_search_in_list"
                            | translate
                    }}
                </mat-label>
                <mat-icon matSuffix>search</mat-icon>
                <input
                    (input)="onSearchFieldInput()"
                    [(ngModel)]="searchQuery"
                    matInput
                    type="search"
                />
            </mat-form-field>
        </div>
    </div>

    <!-- Patients lists -->
    <div
        *ngIf="currentToggleButton === 'patientList'"
        [ngClass]="{ 'w-11/12': currentBreakpoint() === 'handsetLandscape' }"
        class="table-container"
    >
        <!-- table for browser und quer handy  -->
        <table
            *ngIf="currentBreakpoint() !== 'handsetPortrait'"
            [dataSource]="patientListToShow"
            class="home-patient-list"
            mat-table
        >
            <!-- Patient column -->
            <ng-container matColumnDef="patient">
                <th *matHeaderCellDef mat-header-cell>
                    {{ "COMPONENT.PAGE_PATIENT_LIST.patients" | translate }}
                </th>

                <td *matCellDef="let patient" mat-cell>
                    <app-home-patient-basic-info
                        [patient]="patient"
                        class="patient-list-basic"
                        [routerLink]="[
                            '/patient-details',
                            patient.patient_details.case.related_case_id
                        ]"
                    ></app-home-patient-basic-info>
                </td>
            </ng-container>

            <!-- Course column -->
            <ng-container matColumnDef="followUp">
                <th *matHeaderCellDef mat-header-cell>
                    {{ "COMPONENT.PAGE_PATIENT_LIST.followUp" | translate }}
                </th>

                <td *matCellDef="let patient" mat-cell>
                    <app-home-patient-visit
                        [currentBreakpoint]="currentBreakpoint()"
                        [patient]="patient"
                        [viewContentI18n]="viewContentI18nVisiteRecord"
                        class="patient-list-visit"
                    ></app-home-patient-visit>
                </td>
            </ng-container>

            <!-- Tasks column -->
            <ng-container matColumnDef="tasks">
                <th *matHeaderCellDef mat-header-cell>
                    {{ "COMPONENT.PAGE_PATIENT_LIST.tasks" | translate }}
                </th>

                <td *matCellDef="let patient" mat-cell>
                    <app-home-patient-tasks
                        [currentBreakpoint]="currentBreakpoint()"
                        [patient]="patient"
                        [viewContentI18n]="viewContentI18nTask"
                        class="patient-list-tasks"
                    ></app-home-patient-tasks>
                </td>
            </ng-container>

            <!-- Header Row -->
            <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>

            <!-- Data Rows -->
            <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>
        </table>

        <!-- <mat-paginator
            style="flex: 0 0 auto"
            class="mat-elevation-z8"
            [length]="filteredPatients.length"
            [pageIndex]="patientListPage"
            [pageSize]="patientListPageSize"
            [pageSizeOptions]="[5, 10, 25, 100]"
            (page)="onPatientPagerChange($event)"
        ></mat-paginator> -->

        <!-- table for the mobile version, vertical handy -->
        <table
            *ngIf="currentBreakpoint() === 'handsetPortrait'"
            [dataSource]="patientListToShow"
            class="mat-elevation-z8 table-handy"
            mat-table
            multiTemplateDataRows
        >
            <ng-container matColumnDef="patients">
                <th *matHeaderCellDef mat-header-cell>
                    {{ "COMPONENT.PAGE_PATIENT_LIST.patients" | translate }}
                </th>
                <td *matCellDef="let patient" mat-cell>
                    <app-home-patient-basic-info
                        [patient]="patient"
                        class="patient-list-basic"
                    ></app-home-patient-basic-info>
                </td>
            </ng-container>
            <ng-container matColumnDef="expand">
                <th *matHeaderCellDef aria-label="row actions" mat-header-cell>
                    &nbsp;
                </th>
                <td *matCellDef="let element" mat-cell>
                    <button
                        (click)="
                            expandedElement =
                                expandedElement === element ? null : element;
                            $event.stopPropagation()
                        "
                        aria-label="expand row"
                        mat-icon-button
                    >
                        <mat-icon *ngIf="expandedElement !== element"
                            >keyboard_arrow_down
                        </mat-icon>
                        <mat-icon *ngIf="expandedElement === element"
                            >keyboard_arrow_up
                        </mat-icon>
                    </button>
                </td>
            </ng-container>

            <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
            <ng-container matColumnDef="expandedDetail">
                <td
                    *matCellDef="let element"
                    [attr.colspan]="columnsToDisplayWithExpand.length"
                    mat-cell
                >
                    <div
                        [@detailExpand]="
                            element == expandedElement
                                ? 'expanded'
                                : 'collapsed'
                        "
                        class="example-element-detail"
                    >
                        <div class="example-element-diagram">
                            <span>
                                {{
                                    "COMPONENT.PAGE_PATIENT_LIST.followUp"
                                        | translate
                                }}
                            </span>
                            <app-home-patient-visit
                                [currentBreakpoint]="currentBreakpoint()"
                                [patient]="element"
                                [viewContentI18n]="viewContentI18nVisiteRecord"
                                class="patient-list-visit"
                            ></app-home-patient-visit>
                            <span>
                                {{
                                    "COMPONENT.PAGE_PATIENT_LIST.tasks"
                                        | translate
                                }}</span
                            >
                            <app-home-patient-tasks
                                [currentBreakpoint]="currentBreakpoint()"
                                [patient]="element"
                                [viewContentI18n]="viewContentI18nTask"
                                class="patient-list-tasks"
                            ></app-home-patient-tasks>
                        </div>
                    </div>
                </td>
            </ng-container>

            <tr
                *matHeaderRowDef="columnsToDisplayWithExpand"
                mat-header-row
            ></tr>
            <tr
                (click)="
                    expandedElement =
                        expandedElement === element ? null : element
                "
                *matRowDef="let element; columns: columnsToDisplayWithExpand"
                [class.example-expanded-row]="expandedElement === element"
                class="example-element-row"
                mat-row
            ></tr>
            <tr
                *matRowDef="let row; columns: ['expandedDetail']"
                class="example-detail-row"
                mat-row
            ></tr>
        </table>
    </div>

    <!-- Dashboard -->
    <div *ngIf="currentToggleButton === 'dashboard'" class="dashboard-tab">
        <app-network-status
            [viewContent]="dashboardInfo"
            class="dashboard-tab home-tab-body"
        ></app-network-status>
    </div>
</div>
