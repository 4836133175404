import { JsonPipe } from '@angular/common';
import {
    Component,
    computed,
    effect,
    input,
    model,
    OnChanges,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Chart, ChartConfiguration, ChartEvent, ChartType } from 'chart.js';
import 'chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm';
import annotationPlugin from 'chartjs-plugin-annotation';
import dayjs from 'dayjs';
import { BaseChartDirective } from 'ng2-charts';
import backgroundColorPlugin from './plugin/backgroundColorPlugin';
import customTitle from './plugin/customTitle';
import {
    VitalDialogData,
    VitalValuesComponent,
} from './vital-values/vital-values.component';
import bloodPointStyle from './plugin/bloodPointStyle';

export const CHART_COLORS = {
    HF: 'rgba(248, 60, 58, 1)',
    RR: 'rgba(63, 153, 76, 1)',
    T: 'rgba(14, 122, 254, 1)',
    AF: 'rgba(255, 149, 0, 1)',
};

export type ChartItemPoint = {
    x: string;
    y: number;
    systolic?: number;
    diastolic?: number;
};
export type ChartData = ChartConfiguration<'line', ChartItemPoint[]>['data'];

export enum VitalType {
    heartRate = 'HF',
    bloodPressure = 'RR',
    temperatur = 'T',
    respiratoryRate = 'AF',
}

// Commented on 2024-09-09 - Please delete if not needed anymore
// export interface VitalDialog {
//     timeStamp: number;
//     temperatur: number | null;
//     heartRate: number | null;
//     respiratoryRate: number | null;
//     index: number | null;
//     bloodPressureSystolic: number | null;
//     bloodPressureDiastolic: number | null;
// }

export const initDataset: ChartData['datasets'] = [
    {
        label: VitalType.heartRate, // Herzfrequenz ROT
        data: [
            // { x: '2024-09-05T08:30:00', y: 90 },
            // { x: '2024-09-05T09:30:00', y: 95 },
            // { x: '2024-09-05T11:30:00', y: 100 },
        ],
        borderColor: CHART_COLORS.HF,
        backgroundColor: CHART_COLORS.HF,
        pointStyle: 'rect',
        pointRadius: 5,
        pointHoverRadius: 10,
        fill: false,
        yAxisID: 'y',
    },
    {
        label: VitalType.bloodPressure, // Blutdruck GRÜN
        data: [],
        borderColor: CHART_COLORS.RR,
        backgroundColor: CHART_COLORS.RR,
        fill: false,
        yAxisID: 'y2',
        showLine: false,
        pointStyle: bloodPointStyle,
    },
    {
        label: VitalType.temperatur, // Körpertemperatur (°C) Blau
        data: [],
        borderColor: CHART_COLORS.T,
        backgroundColor: CHART_COLORS.T,
        pointStyle: 'circle',
        pointRadius: 5,
        pointHoverRadius: 10,
        fill: false,
        yAxisID: 'y3',
    },
    {
        label: VitalType.respiratoryRate, // Atemfrequenz (Atemzüge/Minute)
        data: [],
        borderColor: CHART_COLORS.AF,
        backgroundColor: CHART_COLORS.AF,
        pointStyle: 'cross',
        pointRadius: 10,
        pointRotation: 45,
        pointBorderWidth: 2,
        pointHoverRadius: 15,
        fill: false,
        yAxisID: 'y4',
    },
];
@Component({
    selector: 'app-curve',
    standalone: true,
    imports: [BaseChartDirective, JsonPipe],
    templateUrl: './curve.component.html',
    styleUrls: ['./curve.component.scss'],
})
export class CurveComponent {
    @ViewChild(BaseChartDirective) chart?: BaseChartDirective;

    public plugins: ChartConfiguration['plugins'] = [
        annotationPlugin,
        backgroundColorPlugin,
        customTitle,
    ];

    public datesArray = input.required<string[]>();

    public datasets = model<ChartData['datasets']>(initDataset);

    public lineChartData = computed<ChartData | undefined>(() => ({
        labels: this.datesArray(),
        datasets: this.datasets(),
    }));

    public lineChartOptions = computed<
        ChartConfiguration['options'] | undefined
    >(() => ({
        maintainAspectRatio: false,
        scales: {
            x: {
                type: 'time',
                time: {
                    minUnit: 'hour',
                    displayFormats: {
                        second: 'HH:mm:ss', // Display time for minor ticks
                        minute: 'HH:mm', // Display time for minor ticks
                        hour: 'HH:mm', // Display time for minor ticks
                    },
                },
                min: this.minMaxDate().min,
                max: this.minMaxDate().max,
                position: 'top',
                display: true,
                grid: {
                    offset: false,
                    color: function (context) {
                        const dayWithMinutes = dayjs(context.tick.value).format(
                            'HH:mm'
                        );

                        if (dayWithMinutes === '00:00') {
                            return 'rgba(0, 0, 0, 0.4)';
                        }

                        return 'rgba(0, 0, 0, 0.1)';
                    },
                },
                ticks: {
                    autoSkip: false,
                    major: {
                        enabled: false,
                    },
                    // maxRotation: 0,
                    // minRotation: 0,
                    // labelOffset: 1,
                    callback: (tickValue) => {
                        const hourWithMinutes =
                            dayjs(tickValue).format('HH:mm');

                        if (
                            hourWithMinutes === '08:00' ||
                            hourWithMinutes === '16:00'
                        ) {
                            return hourWithMinutes;
                        }

                        if (
                            hourWithMinutes === '00:00' ||
                            hourWithMinutes === '04:00' ||
                            hourWithMinutes === '12:00' ||
                            hourWithMinutes === '20:00' ||
                            hourWithMinutes === '24:00'
                        ) {
                            return ' ';
                        }

                        return null;
                    },
                    font: (context) => {
                        const value = context.tick?.value;
                        if (
                            value &&
                            (dayjs(value).format('HH:mm') === '08:00' ||
                                dayjs(value).format('HH:mm') === '16:00')
                        ) {
                            return {
                                weight: 'bold',
                            };
                        }

                        return {};
                    },
                },
            },
            y4: {
                type: 'linear',
                offset: true,
                position: 'left',
                stack: 'af',
                title: {
                    display: true,
                    text: '',
                },
                grid: {
                    display: true,
                    drawBorder: true, // Randlinie (Achse) zeichnen
                    offset: true,
                    color: '#ccc',
                },
                min: 5,
                max: 35,
                ticks: {
                    stepSize: 5,
                },
                border: {
                    // color: CHART_COLORS.AF,
                },
            },
            y3: {
                type: 'linear',
                offset: true,
                position: 'left',
                stack: 't',
                title: {
                    display: true,
                    text: '',
                },
                grid: {
                    display: true,
                    offset: false,
                    color: '#ccc',
                },
                min: 35,
                max: 41,
                ticks: {
                    stepSize: 1,
                },
                stackWeight: 1,
                border: {
                    // color: CHART_COLORS.T,
                },
            },
            y2: {
                type: 'linear',
                offset: true,
                position: 'left',
                stack: 'rr',
                title: {
                    display: true,
                    text: '',
                },
                grid: {
                    display: true,
                    offset: false,
                    color: '#ccc',
                },
                min: 0,
                max: 300,
                ticks: {
                    stepSize: 50,
                },
                stackWeight: 1,
                border: {
                    // color: CHART_COLORS.RR,
                },
            },
            y: {
                type: 'linear',
                offset: true,
                position: 'left',
                stack: 'hf',
                title: {
                    display: true,
                    text: '',
                },
                grid: {
                    display: true,
                    offset: false,
                    color: '#ccc',
                },
                min: 40,
                max: 160,
                ticks: {
                    stepSize: 20,
                },
                stackWeight: 1,
                border: {
                    // color: CHART_COLORS.HF,
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                usePointStyle: true,
            },
            customTitle: {
                x: {
                    display: true,
                    text: 'Month',
                    offsetX: 5,
                    offsetY: 5,
                    font: '12px Comic Sans MS',
                },
                y4: {
                    display: true,
                    color: CHART_COLORS.AF,
                    text: VitalType.respiratoryRate,
                },
                y3: {
                    display: true,
                    text: VitalType.temperatur,
                    color: CHART_COLORS.T,
                },
                y2: {
                    display: true,
                    text: VitalType.bloodPressure,
                    color: CHART_COLORS.RR,
                },
                y: {
                    display: true,
                    text: VitalType.heartRate,
                    color: CHART_COLORS.HF,
                },
            },
        },
    }));

    private minMaxDate = computed(() => {
        const dates = this.datesArray();

        const day1 = dayjs(dates[0]).startOf('day');
        const day2 = dayjs(dates[dates.length - 1]).endOf('day');

        return {
            min: day1.toISOString(),
            max: day2.toISOString(),
        };
    });

    public lineChartType: ChartType = 'line';

    public constructor(private readonly dialog: MatDialog) {}

    // events
    public chartClicked(event: any): void {
        const chartEvent = event as {
            event?: ChartEvent & { chart: Chart };
            active?: object[];
        };

        if (chartEvent.event?.type === 'click' && chartEvent.event?.native) {
            const chart = chartEvent.event.chart;

            const timeStamp =
                chart.scales['x'].getValueForPixel(chartEvent.event.x || 0) ||
                0;

            let temperatur = null;
            let heartRate = null;
            let respiratoryRate = null;
            let index = null;

            chartEvent.active?.forEach((element: any) => {
                const datasetIndex = element.datasetIndex;
                index = element.index;
                const dataSet = chart.data.datasets[datasetIndex];

                if (dataSet.label === VitalType.heartRate) {
                    // @ts-ignore
                    heartRate = dataSet.data[index]['y'];
                }
                if (dataSet.label === VitalType.respiratoryRate) {
                    // @ts-ignore
                    respiratoryRate = dataSet.data[index]['y'];
                }
                if (dataSet.label === VitalType.temperatur) {
                    // @ts-ignore
                    temperatur = dataSet.data[index]['y'];
                }
            });

            const dialogRef = this.dialog.open(VitalValuesComponent, {
                width: '500px',
                data: {
                    index,
                    timeStamp,
                    temperature: temperatur,
                    heartRate,
                    respiratoryRate,
                },
            });

            const diagSub = dialogRef
                .afterClosed()
                .subscribe((result: VitalDialogData) => {
                    if (result) {
                        this.datasets.update((datasets) => {
                            const ds = datasets.map((dataset) => {
                                if (
                                    dataset.label === VitalType.heartRate &&
                                    result.heartRate
                                ) {
                                    if (result.index === null) {
                                        dataset.data.push({
                                            x: dayjs(
                                                result.timeStamp
                                            ).toISOString(),
                                            y: result.heartRate,
                                        });
                                    } else {
                                        dataset.data[result.index] = {
                                            x: dayjs(
                                                result.timeStamp
                                            ).toISOString(),
                                            y: result.heartRate,
                                        };
                                    }
                                }
                                if (
                                    dataset.label === VitalType.bloodPressure &&
                                    result.bloodPressureDiastolic &&
                                    result.bloodPressureSystolic
                                ) {
                                    if (result.index === null) {
                                        dataset.data.push({
                                            x: dayjs(
                                                result.timeStamp
                                            ).toISOString(),
                                            y: result.bloodPressureSystolic,
                                            diastolic:
                                                result.bloodPressureDiastolic,
                                            systolic:
                                                result.bloodPressureSystolic,
                                        });
                                    } else {
                                        dataset.data[result.index] = {
                                            x: dayjs(
                                                result.timeStamp
                                            ).toISOString(),
                                            y: result.bloodPressureSystolic,
                                            diastolic:
                                                result.bloodPressureDiastolic,
                                            systolic:
                                                result.bloodPressureSystolic,
                                        };
                                    }
                                }
                                if (
                                    dataset.label === VitalType.temperatur &&
                                    result.temperatur
                                ) {
                                    if (result.index === null) {
                                        dataset.data.push({
                                            x: dayjs(
                                                result.timeStamp
                                            ).toISOString(),
                                            y: result.temperatur,
                                        });
                                    } else {
                                        dataset.data[result.index] = {
                                            x: dayjs(
                                                result.timeStamp
                                            ).toISOString(),
                                            y: result.temperatur,
                                        };
                                    }
                                }
                                if (
                                    dataset.label ===
                                        VitalType.respiratoryRate &&
                                    result.respiratoryRate
                                ) {
                                    if (result.index === null) {
                                        dataset.data.push({
                                            x: dayjs(
                                                result.timeStamp
                                            ).toISOString(),
                                            y: result.respiratoryRate,
                                        });
                                    } else {
                                        dataset.data[result.index] = {
                                            x: dayjs(
                                                result.timeStamp
                                            ).toISOString(),
                                            y: result.respiratoryRate,
                                        };
                                    }
                                }
                                return dataset;
                            });

                            // Sort the dataset by measure timepoint, so they are correctly shown in the chart
                            ds.forEach((e) =>
                                e.data.sort((d1, d2) =>
                                    d1.x.localeCompare(d2.x)
                                )
                            );
                            return ds;
                        });
                    }

                    diagSub.unsubscribe();
                });
        }
    }

    public downloadChartAsImage(): void {
        if (this.chart) {
            const base64Image = this.chart.toBase64Image();
            if (base64Image) {
                const link = document.createElement('a');
                link.href = base64Image;
                link.download = 'chart.png';
                link.click();
            }
        }
    }
}
