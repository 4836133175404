import {createAction, props} from '@ngrx/store';
import { ViewContent } from '../../models/view-content.models/view-content.model';

export const loadViewContentState = createAction('[ViewContent] Load ViewContent');

export const setViewContent = createAction(
    '[ViewContent] Set ViewContent',
    props<{ viewContent: ViewContent<any>[] }>()
);

export const setViewContentSuccess = createAction(
    '[ViewContent] Set ViewContent Successful',
    props<{ viewContent: ViewContent<any>[] }>()
);

export const setViewContentFail = createAction(
    '[ViewContent] Set ViewContent Fail',
    props<{ error: any }>()
);

export const loadViewContentSuccess = createAction(
    '[ViewContent] Load ViewContent Successful',
    props<{ viewContent: ViewContent<any>[] }>()
);

export const loadViewContentFail = createAction(
    '[ViewContent] Load ViewContent Fail',
    props<{ error: any }>()
);
