<div
    class="lbFlex lbCol patient-basic-info"
    *ngIf="patient"
>
    <!-- Patient Name and Details -->
    <div matLine>
        <span class="patient-basic-info-name table-name"
            >{{ patient.patient_details.patient.data.surname }},
            {{ patient.patient_details.patient.data.name }},</span
        >

        <span class="table-text">
            {{
                "GENERAL." + patient.patient_details.patient?.data?.gender
                    | translate
            }},
            {{
                patient.patient_details.patient?.data?.birth_date
                    | date : ("GENERAL.date_format" | translate)
            }}
            ({{ patient.patient_details.patient?.data?.birth_date | calAge }})
        </span>
    </div>

    <!-- Station,Room, Bed, Admission date and discharge Date -->
    <div>
        <span class="table-text">
            <span class="patient-basic-info-station-room">
                {{
                    patient.patient_details.current_place?.data?.room
                        ?.serviceUnit?.name
                }},
                {{ "COMPONENT.PAGE_PATIENT_LIST.room" | translate }}
                {{ patient.patient_details.current_place?.data?.room?.name
                }}{{
                    patient.patient_details.current_place?.data?.shortName
                }}</span
            >
            A:
            {{
                patient.patient_details.case?.data?.admission_date
                    | date : ("GENERAL.date_format" | translate)
            }}&nbsp;{{
                patient.patient_details.case?.data?.admission_date
            }}&nbsp;({{
                patient.patient_details.case?.data?.admission_date | dayAgo
            }}),
            {{
                "COMPONENT.PAGE_PATIENT_LIST.planned_discharge_date"
                    | translate
            }}:
            {{
                patient.patient_details.case?.data?.planned_discharge_date
                    | date : ("GENERAL.date_format" | translate)
            }}
            ({{
                patient.patient_details.case?.data?.planned_discharge_date
                    | dayAgo
            }})</span
        >
    </div>

    <!-- Patient Diagnosis -->
    @if (!disableDiagnosis) {
    <div class="table-subtext">
        <ng-container>
            {{ patient.patient_details.last_diagnose?.data?.diagnose }}
        </ng-container>
    </div>
    }
</div>
