export class MigrationUpgradeStatements {
    public upgradeMigrations = [
        {
            toVersion: 1,
            statements: [
                `CREATE TABLE IF NOT EXISTS view_content_data(
                    id INTEGER PRIMARY KEY AUTOINCREMENT,
                    data TEXT
                );`,

                `CREATE TABLE IF NOT EXISTS view_content_history(
                    id INTEGER PRIMARY KEY AUTOINCREMENT,
                    view_content_id TEXT,
                    view_content_data_id INTEGER,
                    datetime TEXT,
                    editor TEXT,
                    FOREIGN KEY (view_content_id) REFERENCES view_content(id),
                    FOREIGN KEY (view_content_data_id) REFERENCES view_content_data(id)
                );`,

                `CREATE TABLE IF NOT EXISTS view_content(
                    id TEXT PRIMARY KEY,
                    locator TEXT UNIQUE ON CONFLICT FAIL,
                    owners TEXT,
                    owner_departments TEXT,
                    main_owner_job_type TEXT,
                    created_at TEXT,
                    status TEXT,
                    related_patient_id TEXT,
                    related_case_id TEXT,
                    data_id INTEGER,
                    form TEXT,
                    i18n TEXT,
                    FOREIGN KEY (data_id) REFERENCES view_content_data(id)
                );
                `,
            ],
        },
        {
            toVersion: 2,
            statements: [
                `CREATE TABLE IF NOT EXISTS cp2_user(
                    userId TEXT PRIMARY KEY,
                    surname TEXT,
                    name TEXT,
                    validSince TEXT,
                    validUntil TEXT
                );`
            ],
        },
        {
            toVersion: 3,
            statements: [
                `DELETE FROM view_content_data;`,
                `DELETE FROM view_content_history;`,
                `DELETE FROM view_content;`,
                `DELETE FROM cp2_user;`
            ]
        }
    ];
}
