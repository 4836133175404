import {createReducer, on} from '@ngrx/store';
import * as LocatorsActions from '../actions/locator.action';
import {Locator} from "../../models/view-content.model";

export interface LocatorsState {
    locators: Locator[];
    loading: boolean;
    error: any;
}

export const initialState: LocatorsState = {
    locators: [],
    loading: false,
    error: null,
};

export const locatorsReducer = createReducer(
    initialState,
    on(LocatorsActions.setLocators, (state, {locators}) => ({
        ...state,
        locators,
        loading: true,
    })),
    on(LocatorsActions.setLocatorsSuccess, (state, {locators}) => ({
        ...state,
        locators,
        loading: false,
        error: null,
    })),
    on(LocatorsActions.setLocatorsFail, (state, {error}) => ({
        ...state,
        loading: false,
        error,
    })),
    on(LocatorsActions.loadLocatorsSuccess, (state, {locators}) => ({
        ...state,
        locators,  // Typen sollten nun übereinstimmen
        loading: false,
        error: null,
    })),
    on(LocatorsActions.loadLocatorsFail, (state, {error}) => ({
        ...state,
        loading: false,
        error,
    }))
);
