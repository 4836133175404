<div class="overflow-hidden max-h-full h-full w-full">
    <div class="overflow-y-auto">
        <table>
            <!-- Header row (dates) -->
            <tr>
                <td
                    style="
                        position: sticky;
                        left: 0;
                        top: 0;
                        z-index: 40;
                        background: white;
                    "
                >
                    <mat-form-field>
                        <mat-select [(ngModel)]="filterOption">
                            <mat-option value="all">Alle</mat-option>
                            <mat-option value="important">Wichtige</mat-option>
                        </mat-select>
                    </mat-form-field>
                </td>

                <td *ngFor="let d of datesArray" class="lab-header-date-cell">
                    {{ d | date : "dd.MM.YY" }}
                    <br />
                    {{ d | date : "HH:mm" }}
                </td>
            </tr>

            <!-- Other rows -->
            <ng-container *ngFor="let group of tableData.groups">
                <!-- Fist column -->
                <tr class="lab-table-group-header-row">
                    <td class="lab-table-first-column">{{ group.label }}</td>
                    <td [attr.colspan]="datesArray.length">&nbsp;</td>
                </tr>

                <ng-container *ngFor="let row of group.rows">
                    <tr *ngIf="filterOption === 'all' || row.isImportant">
                        <td class="lab-table-first-column">
                            {{ row.label }}
                            <span class="lab-table-first-column-limits">
                                {{ row.limits }} {{ row.unit }}
                            </span>
                        </td>
                        <td
                            *ngFor="let cell of row.cells"
                            class="lab-table-normal-cell"
                            [ngClass]="getClassesForCell(cell)"
                        >
                            {{ cell.value }}
                        </td>
                    </tr></ng-container
                >
            </ng-container>
        </table>
    </div>
</div>
