<div class="visite-list-wrap h-full">
    <mat-dialog-content>
        <div class="visite-patient-info" cdkFocusRegionstart>
            <div>
                <div class="h5">
                    {{ "COMPONENT.PAGE_ORGA.table_header_visit" | translate }}
                </div>
                <div class="visite-patient-info-text p">
                    <span>
                        <strong>
                            {{ data.patient_info.lastName }},
                            {{ data.patient_info.firstName }}</strong
                        >,</span
                    ><span>
                        {{ "GENERAL." + data.patient_info.gender | translate }},
                        {{
                            data.patient_info.dob
                                | date : ("GENERAL.date_format" | translate)
                        }}
                        ({{ data.patient_info.dob | calAge }}),
                        {{ data.patient_info.ward }},
                        {{ "COMPONENT.PAGE_PATIENT_LIST.room" | translate
                        }}{{ data.patient_info.room.split(" ")[1]
                        }}{{ data.patient_info.bed.split(" ")[1] }}</span
                    >
                </div>
            </div>
            <div class="visite-patient-info-buttons flex">
                <button mat-mini-fab (click)="toggleHistory()">
                    <mat-icon>track_changes</mat-icon>
                </button>
                <button mat-mini-fab (click)="onClose()">
                    <mat-icon>cancel</mat-icon>
                </button>
            </div>
        </div>

        <!-- the entry -->
        <mat-card-content>
            <mat-list
                *ngIf="
                    vcVisitRecord!.history && vcVisitRecord!.history.length > 0
                "
                class="visite-list"
            >
                <mat-list-item>
                    <div class="visite-list-date-editor">
                        <div>
                            <div class="visite-list-item table-text">
                                <!-- {{ "COMPONENT.PAGE_PATIENT_LIST.date_time" | translate }}: -->

                                {{
                                    vcVisitRecord!.history[0].modifiedAt
                                        | date
                                            : ("GENERAL.date_time_format"
                                                  | translate)
                                }}

                                <!-- {{ "COMPONENT.PAGE_PATIENT_LIST.editor" | translate }}: -->
                                {{ vcVisitRecord!.history[0].modifiedBy.name }}
                                {{
                                    vcVisitRecord!.history[0].modifiedBy.surname
                                }}
                            </div>
                            <!-- {{ "COMPONENT.PAGE_PATIENT_LIST.visit_note" | translate }}: -->
                            <div class="table-text">
                                {{ vcVisitRecord!.history[0].data.note }}
                            </div>
                        </div>

                        <mat-icon
                            class="hover:cursor-pointer"
                            matListItemIcon
                            (click)="
                                onClickOnElement(
                                    'form_visit.json ',
                                    data.patient_info,
                                    vcVisitRecord!.history
                                )
                            "
                            >edit</mat-icon
                        >
                    </div>
                </mat-list-item>
                <ng-container *ngIf="showHistory">
                    <div
                        matBadgeOverlap="false"
                        class="w-fit h6"
                        [matBadge]="vcVisitRecord?.history?.length"
                    >
                        {{
                            "COMPONENT.DIALOG_PATIENT_OVERVIEW.changing_history"
                                | translate
                        }}
                    </div>

                    <ng-container
                        *ngFor="
                            let oneHistory of vcVisitRecord!.history;
                            let i = index;
                            let last = last
                        "
                    >
                        <app-changing-history
                            [form_file_name]="'form_visit.json'"
                            [index]="i"
                            [last]="last"
                            *ngIf="showHistory"
                            [i18n]="viewContentI18n"
                            [data]="vcVisitRecord!.history.slice(i, i + 2)"
                        ></app-changing-history>
                    </ng-container>

                    <!-- <mat-list-item
                        *ngFor="
                            let history of group.value.history;
                            let i = index;
                            let last = last
                        "
                    >
                        <div class="visite-list-history">
                            <div
                                class="table-text"
                                [ngClass]="{
                                    'visite-list-history-item': !last
                                }"
                            >
                                 {{
                                    last
                                        ? ("COMPONENT.DIALOG_PATIENT_OVERVIEW.created"
                                          | translate)
                                        : ("COMPONENT.DIALOG_PATIENT_OVERVIEW.edited"
                                          | translate)
                                }}
                                {{
                                    "COMPONENT.DIALOG_PATIENT_OVERVIEW.values"
                                        | translate
                                }}
                                {{
                                    "COMPONENT.DIALOG_PATIENT_OVERVIEW.from"
                                        | translate
                                }}
                                {{
                                    history.modifiedAt
                                        | date
                                            : ("GENERAL.date_time_format"
                                                  | translate)
                                }},

                                {{ history.modifiedBy }}
                                <div>{{ history.note }}</div>
                            </div>
                            <button
                                mat-mini-fab
                                (click)="openDialog()"
                                *ngIf="i !== 0"
                                class="restore-history-button"
                            >
                                <mat-icon class="mat-18">
                                    settings_backup_restore
                                </mat-icon>
                            </button>
                        </div>
                    </mat-list-item> -->
                </ng-container>
            </mat-list>
        </mat-card-content>
    </mat-dialog-content>
</div>
