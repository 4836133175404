import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogContent, MatDialogRef} from "@angular/material/dialog";
import {CalAgePipe, CanvasJson, PaintingToolComponent} from "@lohmann-birkner/luic";
import {TranslateModule} from "@ngx-translate/core";
import {NgIf} from "@angular/common";
import {MatIcon} from "@angular/material/icon";
import {MatIconButton} from "@angular/material/button";
import {MatTooltip} from "@angular/material/tooltip";
import {PatientListItem} from "../../../models/patient.model";
import {PaintingToolItem} from "../../../models/patient-records.model";

/**
 * Component for handling painting tool functionalities within a dialog.
 */
@Component({
    selector: "app-painting-tool-bridge",
    templateUrl: "./painting-tool-bridge.component.html",
    styleUrls: ["./painting-tool-bridge.component.scss"],
    imports: [
        PaintingToolComponent,
        TranslateModule,
        NgIf,
        MatIcon,
        MatIconButton,
        MatTooltip,
        CalAgePipe,
        MatDialogContent
    ],
    standalone: true
})
export class PaintingToolBridgeComponent<T> {
    /** Age to display */
    public showAge: number | undefined;
    protected readonly Object = Object;
    /** Age calculated from date of birth */
    private age: string | undefined;

    constructor(
        /** Dialog data containing image and patient information */
        @Inject(MAT_DIALOG_DATA) public data: { img: PaintingToolItem, patient: PatientListItem },
        /** Reference to the dialog */
        public dialogRef: MatDialogRef<T>
    ) {
        this.calculateAge();
    }

    /**
     * Handles the painting event.
     * @param $event - The CanvasJson event data.
     */
    public painting($event: CanvasJson): void {
        console.log("PAINTING", {$event});
    }

    /**
     * Closes the dialog viewer.
     */
    public closeViewer(): void {
        this.dialogRef.close({test: "STRING"});
    }

    /**
     * Calculates the age of the patient based on their date of birth.
     * @private
     */
    private calculateAge(): void {
        this.age = this.data.patient?.dob;
        if (this.age) {
            const convertAge = new Date(this.age);
            const timeDiff = Math.abs(Date.now() - convertAge.getTime());
            this.showAge = Math.floor(timeDiff / (1000 * 3600 * 24) / 365);
        }
    }
}
