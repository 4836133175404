import {
    Component,
    Input,
    OnDestroy,
    OnInit,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { CommonModule, DatePipe } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { CalAgePipe } from '@lohmann-birkner/luic';
import { PatientListItem } from '../../../models/patient.model';
import { FormModalComponent } from '../../../modals/form-modal/form-modal.component';
import { firstValueFrom, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { SettingsFacadeService } from '../../../services/facades/settings-facade.service';
import { Settings } from '../../../models/settings.model';
import { HomePatientBasicInfoComponent } from '../../home-page/home-patient-basic-info/home-patient-basic-info.component';

export interface OrgaComments {
    id: string;
    table_header_basic_info: PatientListItem;
    firstName: string;
    lastName: string;
    fullname: string;
    dob: string;
    bed: string;
    room: string;
    receiver: string;
    description: string;
    ward: string;
    gender: string;
}

@Component({
    selector: 'app-orga-tabs-comments',
    templateUrl: './orga-tabs-comments.component.html',
    styleUrls: ['./orga-tabs-comments.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        MatTableModule,
        TranslateModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        CalAgePipe,
        MatButtonModule,
        MatButtonToggleModule,
        MatSortModule,
        HomePatientBasicInfoComponent,
    ],
})
export class OrgaTabsCommentsComponent implements OnInit, OnDestroy {
    @ViewChild(MatSort) sort: MatSort | null = null;

    @Input() orgaPatient: PatientListItem[] = [];
    @Input() filterValue: string = '';
    @Input() isWorkflowRoute: boolean = false;

    currentLang: string = '';
    dataSource = new MatTableDataSource<OrgaComments>([]);
    totalRecords: number = 0;
    displayedColumns: string[] = [
        'table_header_basic_info',

        'receiver',
        'description',
    ];

    private allSubs: Subscription[] = [];

    constructor(
        private router: Router,
        public dialog: MatDialog,
        private translate: TranslateService,
        private datePipe: DatePipe,
        private settings: SettingsFacadeService
    ) {}

    ngOnInit() {
        this.dataSource = this.transformDataForMedicalOrders();
        this.totalRecords = this.dataSource.data.length;
    }

    ngAfterViewInit(): void {
        this.dataSource.sort = this.sort;

        this.dataSource.sortingDataAccessor = (item: any, property: string) => {
            switch (property) {
                case 'dob': {
                    let newDate = new Date(item.dob);
                    return newDate;
                }
                default: {
                    return item[property];
                }
            }
        };
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['orgaPatient'] || changes['filterValue']) {
            this.applyFilter();
        }
    }

    public ngOnDestroy(): void {
        this.allSubs.forEach((s) => s.unsubscribe());
    }

    onNameClick(id: string) {
        this.router.navigateByUrl(`patient-details/${id}/3/comments`).then();
    }

    public transformDataForMedicalOrders() {
        let comments: OrgaComments[] = [];

        this.orgaPatient.forEach((patient) => {
            if (patient.comments) {
                this.allSubs.push(
                    this.settings.settings$.subscribe((settings: Settings) => {
                        this.currentLang = settings.language;
                    })
                );

                let formatedDob: string | null;
                const translatedGender = this.translate.instant(
                    'GENERAL.' + patient.gender
                );

                if (this.currentLang === 'en') {
                    formatedDob = this.datePipe.transform(
                        patient.dob,
                        'MM/dd/yyyy'
                    );
                } else {
                    formatedDob = this.datePipe.transform(
                        patient.dob,
                        'dd.MM.yyyy'
                    );
                }
                patient.comments.forEach((comment) => {
                    comments.push({
                        id: patient.id,
                        table_header_basic_info: patient,
                        firstName: patient.firstName,
                        lastName: patient.lastName,
                        fullname: `${patient.lastName}, ${patient.firstName}`,
                        dob: patient.dob,
                        ward: patient.ward,
                        gender: patient.gender,
                        receiver: comment.receiver,
                        description: comment.description,
                        bed: patient.bed,
                        room: patient.room,
                    });
                });
            }
        });
        this.dataSource.data = comments;
        return this.dataSource;
    }

    public async onClickOnElement(
        formFileName: string,
        data: any
    ): Promise<void> {
        const dialogRef = this.dialog.open(FormModalComponent, {
            data: {
                form_file_name: formFileName,
                form_data: { data },
            },
        });
        const res = await firstValueFrom(dialogRef.afterClosed());
    }

    applyFilter(): void {
        const filterValue = this.filterValue.toLowerCase();
        this.dataSource.filter = filterValue;
    }
}
