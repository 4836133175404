import {createSelector} from '@ngrx/store';
import {AppState} from '../appstate.model';

export const selectViewContentFeature = (state: AppState) => state.viewContent;

export const selectViewContent = createSelector(
    selectViewContentFeature,
    (viewContentState) => viewContentState.viewContent
);

