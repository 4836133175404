import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatListModule} from '@angular/material/list';
import {CommonModule} from '@angular/common';
import dayjs from 'dayjs';
import {MatExpansionModule} from '@angular/material/expansion';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {MatIconModule} from '@angular/material/icon';
import {
    DialogPatientOverviewComponent
} from '../../../modals/dialog-patient-overview/dialog-patient-overview.component';
import {MatDialog} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {FormModalComponent} from '../../../modals/form-modal/form-modal.component';
import {firstValueFrom} from 'rxjs';
import {TranslateModule} from '@ngx-translate/core';
import {DynamicListComponent} from '../../data-interaction/dynamic-list/dynamic-list.component';
import {PatientNameAndDob} from '../../../pages/patient-details/patient-details.component';
import {FormioRendererI18n} from '../../data-interaction/formio-renderer/formio-renderer.component';
import {Case_Overview} from '../../../models/view-content.models/view-content.model';

dayjs.extend(customParseFormat);

export interface DialogData {
    dateAndTime: string;
    catalog: string;
    content: string;
}

@Component({
    selector: 'app-patient-overview',
    templateUrl: './patient-overview.component.html',
    styleUrls: ['./patient-overview.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        DialogPatientOverviewComponent,
        MatButtonModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatListModule,
        TranslateModule,
        DynamicListComponent
    ]
})
export class PatientOverviewComponent implements OnInit {
    @Input() patientDetailsI18n: FormioRendererI18n | undefined;
    @Input() patientNameAndDob: PatientNameAndDob = {
        firstName: '',
        lastName: '',
        dob: '',
        gender: '',
        room: '',
        ward: '',
        bed: ''
    };
    //incoming data, contains related patient id and coresponding data, such as medical history,therapy
    @Input() public caseOverview: Case_Overview | undefined;

    @Output() public changedOverviewData: EventEmitter<any> = new EventEmitter();

    public differenceInDays: number | undefined | string;
    protected readonly Object = Object;

    public constructor(public dialog: MatDialog) {
    }

    //#region Listeners

    public ngOnInit() {

        if (this.caseOverview?.visit_record) {
            // this.caseOverview.visit_record.data = this.processVisitRecords(
            //     this.caseOverview.visit_record.data
            // );
        }

        if (this.caseOverview?.consultation) {
            this.caseOverview.consultation = this.showGoalDateOn(
                this.caseOverview.consultation
            );
        }

        if (this.caseOverview?.therapy) {
            this.caseOverview.therapy = this.showGoalDateOn(
                this.caseOverview.therapy
            );
        }

        if (this.caseOverview?.task) {
            this.caseOverview.task = this.showGoalDateOn(
                this.caseOverview.task
            );
        }

        if (this.caseOverview?.diagnostic) {
            this.caseOverview.diagnostic = this.showGoalDateOn(
                this.caseOverview.diagnostic
            );
        }
    }

    //open a new formular
    public async addNewVc(
        formFileName: string,
        type: string,
        patientInfo?: PatientNameAndDob
    ): Promise<void> {
        const dialogRef = this.dialog.open(FormModalComponent, {
            data: {
                form_file_name: formFileName,
                patient_info: patientInfo
            },
            panelClass: 'patient-overview-dialog-container'
        });
        const res = await firstValueFrom(dialogRef.afterClosed());

        this.changedOverviewData.emit({type: type, method: 'ADD', data: res.data});
    }

    //#endregion

    /**for the data with only on entry, like anamnese,diagnose,
     * following the data property contains all the data, and in theory, the referenceIds are always the same.
     * for the multiple data like therapy, diagnostic, they can have more than one entries, and each entry have one id
     * the following property data only contains one entry, history contains all the data, the whole array.
     *
     */
    public async updateVc(
        data: any,
        formFileName: string,
        type: string,
        vc?: any,
        patientInfo?: PatientNameAndDob,
        viewContentI18n?: FormioRendererI18n,
        history?: any[]
    ): Promise<void> {
        const dialogRef = this.dialog.open(FormModalComponent, {
            data: {
                form_data: {data},//formular data
                form_file_name: formFileName,
                patient_info: patientInfo,
                viewContentI18n,
                history
            },
            maxHeight: 'calc(100vh - 16px)',
            panelClass: 'patient-overview-dialog-container'
        });
        console.warn(vc);

        const res = await firstValueFrom(dialogRef.afterClosed());
        this.changedOverviewData.emit({type: type, method: 'UPDATE', data: res.data, vc: vc});
    }

    public getFormattedDate(date: string | Date): string {
        if (!date) return '';

        // Convert the ISO date string to a formatted string
        return dayjs(date).format('YYYY-MM-DDTHH:mm');
    }

    /** to show exactly goal date in the form  */
    /** to check if there is a goal date already in the data, if true, then add one more
     * attribute goal_date, to show the radio('today'|'yesterday'|'tomorrow'|'on') in the form */
    private showGoalDateOn(data: any[]): any[] {
        return data.map((el) => {
            let updatedEl = {...el};

            if (el.data.goalDateOn) {
                updatedEl.data.goal_date = 'on';
            }

            if (el.data.dayCountDeactivateOn) {
                updatedEl.data.dayCountDeactivate = 'on';
            }

            return updatedEl;
        });
    }
}
