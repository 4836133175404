<div *ngIf="!online" color="danger">
    <mat-icon
        aria-hidden="false"
        aria-label="Example settings SVG icon"
        svgIcon="wifi_off"
    ></mat-icon>
    <!-- <span class="material-icons-outlined">wifi_off</span> -->
</div>
<div *ngIf="online" class="net-quality-online-container flex flex-col items-center">
    <!-- <div
        [ngStyle]="{
            height: pingPercent + '%',
            background: getColorForPercent(pingPercent)
        }"
    >
        {{ pingPercent | number : "0.0-0" }}%
    </div> -->

    <mat-icon
        [ngStyle]="{ color: getColorForPercent(speedPercent) }"
        class="material-symbols-outlined text-center"
    >
        network_check
    </mat-icon>
    <div class="text-xs text-center">{{ speedPercent | number : "0.0-0" }}%</div>

    <!-- <div
        [ngStyle]="{
            height: speedPercent + '%',
            background: getColorForPercent(speedPercent)
        }"
    >
        {{ speedPercent | number : "0.0-0" }}%
    </div> -->
</div>
