// Base URLs
/**
 * The base URL for the client application.
 * Use "https://app.cpad2.de/" for PRODUCTION environment.
 */
// export const CLIENT_BASE_URL = "https://app.cpad2.de/";

/**
 * The base URL for the client application.
 * Use "http://localhost:8100/#/" for DEVELOPMENT environment.
 */
// export const CLIENT_BASE_URL = "http://localhost:8100/#/";

/**
 * The unique identifier for the client, used in the OAuth 2.0 authentication process.
 */
export const CLIENT_ID = "cp2_django_backend";

// SMART on FHIR Settings
/**
 * URL to the SMART on FHIR configuration that provides necessary endpoints for FHIR services.
 */
// export const SMART_SETTINGS_URL = "http://192.168.3.133:8443/.well-known/smart-configuration";

/**
 * Base URL for the API endpoints. Pointing to the FHIR server in the local network.
 */
// export const API_BASE_URL = "https://dev-api.cpad2.de/";

// Database Configuration
/**
 * The name of the main application database.
 * Warning: Changing this name can lead to loss of access to the previous database.
 */
export const APP_DB_NAME = "cp2_db";

// Local Storage Keys
/**
 * Local storage key for storing OAuth 2.0 authorization tokens.
 */
export const LS_AUTHZ_TOKENS = "LS_AUTHZ_TOKENS";

/**
 * Local storage key for storing the OAuth 2.0 access token.
 */
export const LS_ACCESS_TOKEN = "LS_ACCESS_TOKEN";
export const LS_DEVICE_ID = "LS_DEVICE_ID";

/**
 * Local storage key for storing the PKCE code verifier.
 * The code verifier is a high-entropy cryptographic random string.
 */
export const LS_CODE_VERIFIER = "LS_CODE_VERIFIER";

/**
 * Local storage key for storing device-related information.
 */
export const LS_DEVICE = "LS_DEVICE";
export const LS_LOCATORS = "LS_LOCATORS";
export const LS_VIEWCONTENT = "LS_VIEWCONTENT";

/**
 * Local storage key for storing user-specific settings.
 */
export const LS_SETTINGS = "LS_SETTINGS";
/**
 * Local storage key for storing user-specific settings.
 */
export const LS_PATIENT_RECORDS_AREAS = "LS_PATIENT_RECORDS_AREAS";
export const LS_PATIENT_RECORDS_SUBAREAS = "LS_PATIENT_RECORDS_SUBAREAS";
export const LS_PATIENT_SELECTED_RECORD = "LS_PATIENT_SELECTED_RECORD";
export const LS_PATIENT_RECORDS = "LS_PATIENT_RECORDS";

/**
 * Local storage keys for storing dashboard position.
 */
export const LS_DASHBOARD = "LS_DASHBOARD";
export const LS_CURRENT_POSITION = "LS_CURRENT_POSITION";
export const LS_NAVIGATION_HISTORY = "LS_NAVIGATION_HISTORY";

export const LS_LAST_SAVED_DATE = "LS_LAST_SAVED_DATE";

/**
 * Local storage keys for storing mercure data.
 */
export const LS_MERCURE_LAST_SSE_ID = "LS_MERCURE_LAST_SSE_ID";
