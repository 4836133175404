import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {from, of} from 'rxjs';
import {catchError, map, switchMap, withLatestFrom} from 'rxjs/operators';
import * as LocatorsActions from '../actions/locator.action';
import {DataRepositoryService} from '../../services/datarepository.service';
import {AppState} from '../appstate.model';
import {Store} from '@ngrx/store';

/**
 * Effects for handling authorization tokens related operations.
 */
@Injectable()
export class LocatorsEffects {
    /** Effect for loading settings. */
    public loadLocators$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LocatorsActions.loadLocatorState),
            switchMap(() => {
                // TODO Finish me!
                return from(this.repo.getLocators()).pipe(
                    map((locators) => {
                        return LocatorsActions.loadLocatorsSuccess({locators});
                    })
                );
            }),
            catchError((error) => {
                return of(LocatorsActions.loadLocatorsFail({error}));
            })
        )
    );
    /** Effect for adding or updating settings. */
    saveLocators$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LocatorsActions.setLocators),
            withLatestFrom(this.store.select((state) => state.locators.locators)),
            switchMap(([action, locators]) => {
                // TODO Finish me!
                this.repo.saveLocators(locators).then();
                return of(
                    LocatorsActions.setLocatorsSuccess({locators: action.locators})
                );
            }),
            catchError((error) => {
                return of(LocatorsActions.setLocatorsFail({error}));
            })
        )
    );

    public constructor(
        private actions$: Actions,
        private repo: DataRepositoryService,
        private store: Store<AppState>
    ) {
    }
}
